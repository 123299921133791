import Swal from "sweetalert2";
import PageTitle from "../../layouts/PageTitle";
import { addNewExtintionFunc } from "../../../store/custom-actions/courses-actions/excutions-actions/AddCourseExtintionsSlicer";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { useContext, useEffect } from "react";
import ContextApi from "../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function NewExecutionPage() {
    const {t} = useTranslation()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();
      const { courseId } = useParams();
      const {loading} = useSelector(state => state.addNewExtintionSlicer);
      const dispatch = useDispatch();
      const contextApi = useContext(ContextApi);
      const extintionsApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/course-executions`;
      const handleSubmitExtintions = (data) => {
          if(data.start_date_student > data.end_date_student) {
              Swal.fire({
                  title: t("Oops!"),
                  text: t("start publish date for students must be less than end date or more than today"),
                  icon: "warning"
              });
          }else if (data.start_date_publish > data.end_date_publish){
              Swal.fire({
                  title: t("Oops!"),
                  text: t("start publish date for anyone must be less than end date or more than today"),
                  icon: "warning"
              });
          }else {
              const notEmptyData = Object.fromEntries(
                  Object.entries(data).filter(([key, value]) => value !== "")
              );
              dispatch(addNewExtintionFunc({extintionsApi : extintionsApi , extintionData : notEmptyData}))
              .then(result => {
                  if(result.payload.success === true) {
                      window.location.reload();
                      reset();
                  }
              })
          }
      }
      useEffect(() => {
          HandleErrorsListFunc(errors)
      },[errors])
  
    return (
        <>
        
            <PageTitle motherMenu={"Executions"} activeMenu={"New Execution"} />
            <div className="card p-3">
                <div className="basic-form">
                    <form 
                    className="course-extintions-form" 
                    id="course-extintions-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitExtintions(data)
                    })
                    }
                    >
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("Title")}
                            </label>
                            {errors.title?.message && (
                                <p className="text-red-600">{errors.title?.message}</p>
                            )}
                            <input
                                {...register("title", {
                                required: "title Should not be empty!",
                                })}
                                type="text"
                                className="form-control"
                        />
                        </div>
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("Price")}
                            </label>
                            {errors.price?.message && (
                                <p className="text-red-600">{errors.price?.message}</p>
                            )}
                            <input
                                {...register("price", {
                                required: "price Should not be empty!",
                                })}
                                type="number"
                                className="form-control"
                        />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12">
                            <label className="relative flex items-center">
                                {t("Course Visibilty")}
                            </label>
                            {errors.is_visible?.message && (
                                <p className="text-red-600">{errors.is_visible?.message}</p>
                            )} 
                            <select
                                className="form-control"
                                {...register("is_visible", {
                                required: "visibilty Should not be empty!",
                                })}
                            >
                                <option value={1}>{t("Visible")}</option>
                                <option value={0}>{t("Not Visible")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("Discount")} ({t("Optional")})
                            </label>
                            {errors.discount?.message && (
                                <p className="text-red-600">{errors.discount?.message}</p>
                            )}
                            <input
                                {...register("discount")}
                                type="number"
                                className="form-control"
                                defaultValue={0}
                        />
                        </div>
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("Order")}
                            </label>
                            {errors.order?.message && (
                                <p className="text-red-600">{errors.order?.message}</p>
                            )}
                            <input
                                {...register("order", {
                                required: t("order Should not be empty!"),
                                })}
                                type="number"
                                className="form-control"
                        />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("Start Visible for Students Date")}
                            </label>
                            {errors.start_date_student?.message && (
                                <p className="text-red-600">{errors.start_date_student?.message}</p>
                            )}
                            <input
                                {...register("start_date_student", {
                                required: t("date Should not be empty!"),
                                })}
                                type="date"
                                className="form-control"
                        />
                        </div>
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("End Visible for Students Date")}
                            </label>
                            {errors.end_date_student?.message && (
                                <p className="text-red-600">{errors.end_date_student?.message}</p>
                            )}
                            <input
                                {...register("end_date_student", {
                                required: t("end_date Should not be empty!"),
                                })}
                                type="date"
                                className="form-control"
                        />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("Start Visible for anyone")}
                            </label>
                            {errors.start_date_publish?.message && (
                                <p className="text-red-600">{errors.start_date_publish?.message}</p>
                            )}
                            <input
                                {...register("start_date_publish" , {
                                    required: t("end date Should not be empty!"),
                                })}
                                type="date"
                                className="form-control"
                        />
                        </div>
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("End Visible for anyone")}
                            </label>
                            {errors.end_date_publish?.message && (
                                <p className="text-red-600">{errors.end_date_publish?.message}</p>
                            )}
                            <input
                                {...register("end_date_publish" , {
                                    required: t("end date Should not be empty!"),
                                })}
                                type="date"
                                className="form-control"
                        />
                        </div>
                    </div>
                    <div className="relative flex items-center my-2">
                    <Button onClick={()=>{
                        window.history.back();
                    }}>{t("Back")}</Button>
                    <Button type="submit" form="course-extintions-form" className={`btn btn-primary flex items-center justify-center ${loading ? "pointer-events-none" : ""} mx-1`}>
                        {!loading ? (
                        t("Submit")
                        ) : (
                        <div className="relative flex justify-center items-center">
                            {t("loading")}..
                            <div className="loading-spin-data w-[15px] h-[15px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1"></div>
                        </div>
                        )}
                    </Button>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}