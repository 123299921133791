// import { useDispatch, useSelector } from "react-redux"
// import { Spinner} from "react-bootstrap"
// import { useContext, useEffect, useState } from "react";
// import ContextApi from "../../../store/ContextApi";
// import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
// import PageTitle from "../../layouts/PageTitle";
// import { getQuizzesFunc } from "../../../store/custom-actions/quizzes-actions/GetQuizzesSlicer";
// import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
// import { deleteQuizeFunc } from "../../../store/custom-actions/quizzes-actions/DeleteQuizeSlicer";
// import swal from "sweetalert";
// import QuizeQuestionsTable from "./QuizeQuestionsTable";
// import { Link } from "react-router-dom";
// import NewQuizeForm from "../../global-compnents/new-quize-form/NewQuizeForm";
// import checkUserRole from "../../global-functions/checkUserRole";


// function QuizzesPage() {
//     const {loading , categoriesData} = useSelector(
//         state => state.getAllCategoriesSlicer
//     )
//     const {deleteCategoriesLoading} = useSelector(
//         state => state.deleteCategorySlicer
//         )
//     const {deleteQuizeLoading} = useSelector(
//         state => state.deleteQuizeSlicer
//     );
//     const {getQuizzesLoading , quizzesData} = useSelector(
//         state => state.getQuizzesSlicer
//     )
//     const contextApi = useContext(ContextApi);
//     const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
//     const quizzes_API_URL = `${contextApi.dashboard_URL_API}/quizzes`;
//     const [categoriesList , setCategoriesList] = useState([]);  
//     const [quizzesSearchValue , setQuizzesSearchValue] = useState();
//     const [quizzesList , setQuizzesList] = useState([]);
//     const [showQuizeQuestions , setShowQuizeQuestions] = useState();
//     const [quizeQuestions , setQuizeQuestions] = useState([]);
//     const [category , setCategory] = useState();
//     const [quizeTitle , setQuizeTitle] = useState();
//     const [showQuizeForm , setShowQuizeForm] = useState();
//     const dispatch = useDispatch();
//     useEffect(() => {
//         const getCategoriesData = async () => {
//           try {
//             dispatch(fetchAllCategoriesFunc(categories_API_URL))
//           } catch (error) {
//             console.log(error);
//           }
//         };
//         getCategoriesData();
//       }, []);
//       useEffect(() => {
//         categoriesData && setCategoriesList(categoriesData);
//       }, [categoriesData]);

//       useEffect(() => {
//         let quizzesTimeoutSearch;
//         const quizzesApi = `${contextApi.dashboard_URL_API}/quizzes?category_id=${category}`;
//         const searchApi = `${quizzesApi}${quizzesSearchValue ? `&search=${quizzesSearchValue}` : ""}`
//         const quizzesSearchFunc = () => {
//           if(quizzesSearchValue !== "") {
//             clearTimeout(quizzesTimeoutSearch)
//             quizzesTimeoutSearch = setTimeout(() => dispatch(getQuizzesFunc(searchApi)),2000)
//           }else {
//             dispatch(getQuizzesFunc(quizzesApi));
//           }
//         }
//         quizzesSearchFunc()
//         return () => clearTimeout(quizzesTimeoutSearch);
//       },[quizzesSearchValue , category])
//       useEffect(() => {
//         quizzesData && setQuizzesList(quizzesData)
//       },[quizzesData])

//       const deletequizeFunction = async (quizId) => {
//         swal({
//             title: "Confirm Quize Deletion",
//             text: `Once deleted, will not be quize in platform!`,
//             icon: "warning",
//             buttons: true,
//             dangerMode: true,
//             }).then((willDelete) => {
//             if (willDelete) {
//                 dispatch(deleteQuizeFunc(`${quizzes_API_URL}/${quizId}`))
//                 .then(result => {
//                     if(result.payload.success === true) {
//                         dispatch(getQuizzesFunc(quizzes_API_URL));
//                     }
//                 })
//             } else {
//                 swal("Your quize is safe!");
//             }
//             });
//     }
//     useEffect(() => {
//         setCategory(0)
//     },[])

//     return (
//         <>
//         <PageTitle motherMenu={"Home"} activeMenu={"Quizzes"} customClasses={"mb-0"}/>
//         <div className="card-header border-0 flex-wrap pb-0 my-2">
//             <h3>Quizzes List</h3>
//             <div className="flex">
//                 <div className="input-group search-area w-auto">
//                 <span className="input-group-text">
//                     <Link to={"#"}>
//                     <svg
//                         width="24"
//                         height="24"
//                         viewBox="0 0 32 32"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <path
//                         d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
//                         fill="var(--primary)"
//                         ></path>
//                     </svg>
//                     </Link>
//                 </span>
//                 <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Search here..."
//                     onChange={(e) => {
//                     setQuizzesSearchValue(e.target.value);
//                     }}
//                 />
//                 </div>
//                 {
//                 loading
//                 ?
//                 <p className="ml-1">Loading Categories..</p>
//                 :
//                 <div className="filter-icon relative  ml-2">
//                     <select className="form-control" id="filter-courses-btn"
//                         onChange={(e)=>{
//                         setCategory(e.target.value)
//                         }}
//                         defaultValue={0}
//                     >
//                         <option disabled value={0}>مادة الامتحان..</option>
//                         {
//                             categoriesData
//                             &&
//                             categoriesList
//                             &&
//                             categoriesList.length >= 1
//                             &&
//                             categoriesList.map((cat , index) => {
//                                 return <option key={index} value={cat.id}>{cat.name}</option>
//                             })
                            
//                         }
//                     </select>
//                 </div>
//                 }
//                 <button
//                     className="add-student-btn px-3 relative text-white py-2 rounded-md ml-3 bg-[#4d5fe3] flex justify-center items-center transition-all duration-500 border overflow-hidden"
//                     onClick={() => {
//                         setShowQuizeForm(true);
//                     }}
//                     >
//                     <i className="fa-solid fa-plus transition-all duration-700 absolute left-0 opacity-0 delay-[.2s]"></i>
//                     <span className="relative mx-[2px] transition-all duration-500 delay-[.1s]">
//                         New
//                     </span>
//                     <span className="relative mx-[2px] transition-all duration-500 delay-[]"></span>
//                     <span className="relative mx-[2px] transition-all duration-500 delay-[0s]">
//                         Quize
//                     </span>
//                 </button>
//             </div>
//         </div>
//         <div className="categories-section relative ">
//             {
//                 loading 
//                 ?
//                 (
//                     <>
//                     <div className="row d-flex justify-content-center gap-2">
//                       <Spinner animation="grow" variant="warning" />
//                       <Spinner animation="grow" variant="warning" />
//                       <Spinner animation="grow" variant="warning" />
//                       <Spinner animation="grow" variant="warning" />
//                     </div>
//                   </>
//                 )
//                 :
//                 (
//                     category !== 0
//                     ?
//                     <>
//                         <div className="card-body py-0">
//                         <div className="table-responsive">
//                             <div
//                             id="student_wrapper"
//                             className="dataTables_wrapper no-footer"
//                             >
//                             <table
//                                 className="table display mb-4 dataTablesCard order-table card-table text-black application "
//                                 id="application-tbl1_next"
//                             >
//                                 <thead>
//                                 <tr>
//                                     <th className="text-[#4d5fe3]">ID</th>
//                                     <th className="text-[#4d5fe3]">Quize</th>
//                                     <th className="text-[#4d5fe3]">Questions Count</th>
//                                     <th className="text-[#4d5fe3]">Lessons Count</th>
//                                     <th className="text-[#4d5fe3]">Creation</th>
//                                     <th className="text-[#4d5fe3]">Last Update</th>
//                                     <th className="text-[#4d5fe3] text-end">
//                                         Decision
//                                     </th>
//                                 </tr>
//                                 </thead>
//                                 {
//                                 getQuizzesLoading 
//                                 ?
//                                 (
                                    
//                                     <>
//                                         <div className="row d-flex justify-content-center gap-2 my-4 pl-4">
//                                             <Spinner animation="grow" variant="warning" />
//                                             <Spinner animation="grow" variant="warning" />
//                                             <Spinner animation="grow" variant="warning" />
//                                             <Spinner animation="grow" variant="warning" />
//                                         </div>
//                                     </>
//                                 )
//                                 :
//                                 (
//                                     quizzesData
//                                     &&
//                                     quizzesList
//                                     &&
//                                     quizzesList.length >= 1
//                                     ?
//                                     <tbody>
//                                         {quizzesData &&
//                                         quizzesList.length > 0 &&
//                                         quizzesList.map((quize, index) => {
//                                             const creationDate = new Date(quize.created_at)
//                                             const creationYear = creationDate.getFullYear();
//                                             const creationMonth = creationDate.getMonth()+1;
//                                             const creationDay = creationDate.getDate();
//                                             const latestUpdateDate = new Date(quize.updated_at)
//                                             const latestUpdateYear = latestUpdateDate.getFullYear();
//                                             const latestUpdateMonth = latestUpdateDate.getMonth()+1;
//                                             const latestUpdateDay = creationDate.getDate();
//                                             return (
//                                             <tr key={index}>
//                                                 <td className="font-bold text-[#4d5fe3]">
//                                                 #ID:{quize.id}
//                                                 </td>
//                                                 <td>
//                                                 <div className="d-flex align-items-center">
//                                                     <i className="fa-solid fa-clipboard-question text-[20px] text-slate-700"></i>
//                                                     <h4 className="mb-0 fs-16 font-w500 ml-2">
//                                                     {quize.title}
//                                                     </h4>
//                                                 </div>
//                                                 </td>
//                                                 <td>{quize.questions_count}</td>
//                                                 <td>{quize.lessons_count}</td>
//                                                 <td>
//                                                     {`${creationYear} / ${creationMonth} / ${creationDay}`}
//                                                 </td>
//                                                 <td className="">
//                                                     {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
//                                                 </td>
//                                                 <td className="">
//                                                     {
//                                                         checkUserRole
//                                                         (
//                                                             "manager"
//                                                             ,
//                                                             <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
//                                                                 onClick={() => {
//                                                                     deletequizeFunction(quize.id)
//                                                                 }}
//                                                             >
//                                                                 <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2"
//                                                                 >
//                                                                     <i className="fa-solid fa-trash text-white"></i>
//                                                                     <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
//                                                                     >Delete</span>
//                                                                 </div>
//                                                             </button>
//                                                         )
//                                                     }
//                                                     <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
//                                                         onClick={() => {
//                                                             setQuizeQuestions(quize.questions)
//                                                             setShowQuizeQuestions(true)
//                                                             setQuizeTitle(quize.title)
//                                                         }}
//                                                     >
//                                                         <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2"
//                                                         >
//                                                             <i className="fa-solid fa-eye text-white"></i>
//                                                         </div>
//                                                     </button>
//                                                 </td>
//                                             </tr>
//                                             );
//                                         })}
//                                     </tbody>
//                                     :
//                                     (
//                                     category && !getQuizzesLoading
//                                     ?
//                                     <p className="relative p-1 text-[17px]">loading..</p>
//                                     :
//                                     <p className="p-2 text-[20px]">لا يوجد امتحانات!</p>
//                                     )
//                                 )
//                                 }
//                             </table>
//                             </div>
//                         </div>
//                         </div>
//                     </>
//                     :
//                     <p className="relative text-center mt-2 text-[20px]">قم بالبحث او اختيار ماده الامتحان!</p>

//                 )
//             }
//         </div>
//         <OverLayLoading loading={deleteCategoriesLoading} loadingText={"Deleting Category..."}/>
//         <OverLayLoading 
//             loading={deleteQuizeLoading} 
//             loadingText={"Deletnig Quize..."}
//         />
//         <QuizeQuestionsTable
//             show = {showQuizeQuestions}
//             onHide = {() => setShowQuizeQuestions(false)}
//             quize_questions = {quizeQuestions}
//             quize_title = {quizeTitle}
//         />
//         {
//             showQuizeForm
//             &&
//             <NewQuizeForm
//             show={showQuizeForm}
//             onHide={()=>setShowQuizeForm(false)}
//             />
//         }
//         </>
//     )
// }


// export default QuizzesPage
import { useDispatch, useSelector } from "react-redux"
import { Spinner} from "react-bootstrap"
import { useContext, useEffect, useRef, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { Link, useParams } from "react-router-dom";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { getAllQuizzesFunc } from "../../../store/custom-actions/quizzes-actions/GetAllQuizzesSlicer";
import noAttemptsImg from "../../../media/images/planet.png";
import studentImg from "../../../media/images/graduated.png";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { deleteAttemptFunc } from "../../../store/custom-actions/attempts-actions/DeleteAttemptSlicer";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { Bounce, toast } from "react-toastify";
export default function QuizzesPage() {    
    const {t} = useTranslation()
    const {lessonId} = useParams();
    const dispatch = useDispatch();
    const {loading:getQuizzesLoading , data:quizzesData} = useSelector(state => state.getAllQuizzesSlicer);
    const {loading:deleteAttemptLoading} = useSelector(state => state.deleteAttemptSlicer);
    const contextApi = useContext(ContextApi)
    const quizzessApi = `${contextApi.dashboard_URL_API}/lessons/${lessonId}/quizzes`;
    const allQuizzessApi = `${contextApi.dashboard_URL_API}/quizzes`;
    const [quizzesList , setQuizzesList] = useState([]);
    const [filtredQuizzes , setFiltredQuizzes] = useState([]);
    const [lessonName , setLessonName] = useState("Lesson");
    const [vistedStatus , setVistedStatus] = useState("");
    const getCourseAttempts = () => {
        dispatch(getAllQuizzesFunc(quizzessApi))
        .then(result => console.log(result))
    }
    useEffect(() => {
        getCourseAttempts();
    },[])
    useEffect(() => {
        if(quizzesData && quizzesData.length >= 1) {
            setQuizzesList(quizzesData);
            quizzesData[0]?.lesson?.name && setLessonName(quizzesData[0].lesson.name) 
        }
        
    },[quizzesData])
    
    useEffect(() => {
        const getData = () => {
            dispatch(getAllQuizzesFunc(quizzessApi))
        }
        getData()
    },[])

      useEffect(() => {
        quizzesData && setQuizzesList(quizzesData);
        quizzesData && setFiltredQuizzes(quizzesData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
        ));
        console.log(quizzesData)
      },[quizzesData])

      const sort = 20;
      const activePag = useRef(0);

  
      const onClick = (i) => {
          activePag.current = i;
          setFiltredQuizzes(quizzesList.slice(
              activePag.current * sort,
              (activePag.current + 1) * sort
          ));
      };
          let paggination = Array(Math.ceil(quizzesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
  
      useEffect(() => {
          if (vistedStatus !== "") {
              const searchResult = quizzesData &&
                  quizzesData.length >= 1 && quizzesData.filter((quize) => (quize.is_visited == vistedStatus))
              setFiltredQuizzes(searchResult);
          } else {
              setFiltredQuizzes(quizzesData.slice(
                  activePag.current * sort,
                  (activePag.current + 1) * sort
              ));
          }
      }, [vistedStatus, quizzesData]);

    //   const deleteQuestionFunction = async (questionId) => {
    //     swal({
    //         title: "Confirm Question Deletion",
    //         text: `Once deleted, will not be quize in platform!`,
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //         }).then((willDelete) => {
    //         if (willDelete) {
    //             dispatch(deleteQuestionFunc(`${delete_questions_API_URL}/${questionId}`))
    //             .then(result => {
    //                 if(result.payload.success === true) {
    //                     dispatch(getAllQuizzesFunc(quizzessApi));
    //                 }
    //             })
    //         }
    //         });
    // }
    // useEffect(() => {
    //     setCategory(0)
    // },[])

    const deleteAttempt = async (id) => {
        const deleteFunc = () => {
          try {
            dispatch(deleteAttemptFunc(`${allQuizzessApi}/${id}`))
            .then((result)=>{
              if(result.payload.success === true){
                dispatch(getAllQuizzesFunc(quizzessApi));
                toast.error(
                    result?.payload?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
              }
            });
          }catch (error) {
            console.log(error);
          }
        }
        swal({
          title: t("Confirm Item Deletion"),
          text: t(`Once deleted, this Attempt will Increase Student Time For Try Quize!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteFunc()
          }
        })
      };

    return (
        <>
        <PageTitle motherMenu={"Chapter"} activeMenu={lessonName} customClasses={"mb-0"}/>
        <div className="categories-section relative ">
            {
                getQuizzesLoading 
                ?
                (
                    <>
                    <div className="row d-flex justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                  </>
                )
                :
                (
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header flex justify-between items-center">
                                <h4 className="card-title">{t("Quizzes")}</h4>
                                <div className="relative add-and-search flex items-center">
                                    {/* <div className="input-group search-area w-auto mr-2">
                                        <span className="input-group-text">
                                            <Link to={"#"}>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                        fill="var(--primary)"
                                                    ></path>
                                                </svg>
                                            </Link>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here..."
                                            value={searchValue}
                                            onChange={(e) => setVistedStatus(e.target.value)}
                                        />
                                    </div> */}
                                    <div className="filter-icon relative  mx-2">
                                        <select className="form-control" id="filter-courses-btn"
                                            onChange={(e)=>{
                                                setVistedStatus(e.target.value)
                                            }}
                                            defaultValue={""}
                                        >
                                            <option value={""}>{t("All Types")}</option>
                                            <option value={1}>{t("Visted")}</option>
                                            <option value={0}>{t("Not Visted")}</option>
                                        </select>
                                    </div>
                                    {/* {checkPermissions(
                                        "create questions",
                                        <Link
                                            to={`/new-question/${lessonId}`}
                                            className="btn btn-primary btn-sm"
                                            // onClick={() => setShowNew}
                                        >
                                            Create
                                            <i className="fa-solid fa-plus ml-1"></i>
                                        </Link>
                                    )} */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="w-100 table-responsive">
                                    <div id="example_wrapper" className="dataTables_wrapper">
                                        <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr>
                                                <th className="width80">
                                                    <strong className="">#{t("ID")}</strong>
                                                </th>
                                                <th>
                                                    <strong className="">{t("Student")}</strong>
                                                </th>
                                                <th>
                                                    <strong className="">{t("Lesson")}</strong>
                                                </th>
                                                <th>
                                                    <strong className="">{t("Creation Date")}</strong>
                                                </th>
                                                <th>
                                                    <strong className="">{t("Status")}</strong>
                                                </th>
                                                <th>
                                                    <strong className="">{t("Score")}</strong>
                                                </th>
                                                <th>
                                                    <strong className="">{t("Actions")}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filtredQuizzes.map((attemp,index)=>{
                                                const date = new Date(attemp.created_at)
                                                const year = date.getFullYear();
                                                const month = date.getMonth()+1;
                                                const day = date.getDate();
                                                return (
                                                <tr key={index}>
                                                    <td>
                                                    <   strong className="">ID:{attemp.id}</strong>
                                                    </td>
                                                    <td className="flex items-center">
                                                        <Link className="relative flex items-center" to={`student-profile/${attemp.student_id}`}>
                                                            <img 
                                                            src={attemp.student && attemp.student.image_url !== null ? attemp.student.image_url : studentImg} 
                                                            alt="student img"
                                                            className="relative w-[40px] mr-1"
                                                            />
                                                            {attemp.student.name}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <h5 className="relative flex items-center m-0">
                                                            {
                                                            attemp.lesson 
                                                            && attemp.lesson.type 
                                                            && attemp.lesson.type
                                                            // && checkLessonType(attemp.lesson.type)
                                                            }
                                                            {attemp.lesson && attemp.lesson.name && attemp.lesson.name}
                                                        </h5>
                                                    </td>
                                                    <td>{year} / {month} / {day}</td>
                                                    <td>
                                                        {
                                                            attemp.is_visited === 1
                                                            ?
                                                            // <img src={correctIcon} alt="attempt status img" className="w-[17px]"/>
                                                            <i className="fa-solid fa-circle-check text-green-500"></i>
                                                            :
                                                            <i className="fa-solid fa-hourglass-start text-orange-500"></i>
                                                        }
                                                    </td>
                                                    <td>{`${(attemp.score === 0 || attemp.score >= 1) ? `${attemp.score}%` : "No Score"}`}</td>
                                                    <td className="">
                                                        {
                                                            // attemp.is_visited === 1
                                                            // ?
                                                            // <p className="m-0">Corrected</p>
                                                            // :
                                                            <Link
                                                                to={`/attemp-page/${attemp.id}`}
                                                                className="btn btn-danger shadow btn-xs sharp mx-2"
                                                            >
                                                                <i className="fa-regular fa-eye"></i>
                                                            </Link>
                                                        }
                                                            <Link
                                                                to="#"
                                                                className="btn btn-danger shadow btn-xs sharp"
                                                                onClick={() => {
                                                                    deleteAttempt(attemp?.id);
                                                                }}
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </Link>
                                                    </td>
                                                </tr>
                                                )
                                            })}
                                            {
                                                vistedStatus !== "" && filtredQuizzes.length < 1
                                                &&
                                                <tr>
                                                    <td colSpan={4}>{t("No Data Match Your Search!")}</td>
                                                </tr> 
                                            }
                                        </tbody>
                                    </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                            <div className="dataTables_info">
                                                {t("Showing")} {activePag.current * sort + 1} to{" "}
                                                {quizzesList.length > (activePag.current + 1) * sort
                                                    ? (activePag.current + 1) * sort
                                                    : quizzesList.length}{" "}
                                                {t("of")} {quizzesList.length} {t("entries")}
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers mb-0"
                                                id="example5_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={() =>
                                                        activePag.current > 0 && onClick(activePag.current - 1)
                                                    }
                                                >
                                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                </Link>
                                                <span>
                                                    {paggination.map((number, i) => (
                                                        <Link
                                                            key={i}
                                                            to="#"
                                                            className={`paginate_button  ${activePag.current === i ? "current" : ""
                                                                } `}
                                                            onClick={() => onClick(i)}
                                                        >
                                                            {number}
                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={() =>
                                                        activePag.current + 1 < paggination.length &&
                                                        onClick(activePag.current + 1)
                                                    }
                                                >
                                                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
        <OverLayLoading loading={deleteAttemptLoading} loadingText={"loading..."} />
        </>
    )
}
