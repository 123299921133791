import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ContextApi from '../../../../store/ContextApi';
import { getSubscriptionsFunc } from '../../../../store/custom-actions/subscription-actions/GetSubscriptionsSlicer';

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { updateSubscripePaymentsStatusFunc } from '../../../../store/custom-actions/subscription-actions/UpdateSubscripePaymentsStatus';

export default function UpdateSubscripeStatus({show , onHide , oldStatus , id , courseId}) {
    const {t} = useTranslation();
    const [status , setStatus] = useState();
    const {loading:updateSubscripeLoading} = useSelector(state => state.updateSubscripePaymentsStatus)
    const contextApi = useContext(ContextApi)
    const subscriptionsApi = `${contextApi.dashboard_URL_API}/subscriptions?course_id=${courseId}`;
    const globalSubscriptionsApi = `${contextApi.dashboard_URL_API}/subscriptions`;
    useEffect(() => {
        oldStatus && setStatus(oldStatus)
    },[oldStatus])
    useEffect(() => console.log(status) ,[status])
    const dispatch = useDispatch();
    const updateSubscripe = (e) => {
        e.preventDefault();
        const updateFunc = () => {
          dispatch(updateSubscripePaymentsStatusFunc({api : `${globalSubscriptionsApi}/${id}` , data : {payment_status : status , "_method" : "PUT"}})).then((result) => {
            if(result.payload.success === true) {
              onHide();
              courseId
              ?
              dispatch(getSubscriptionsFunc(`${globalSubscriptionsApi}?course_id=${courseId}`))
              :
              dispatch(getSubscriptionsFunc(globalSubscriptionsApi))
            }
          })
        }
          swal({
          title: t("Confirm Update"),
          text: t(`Please Confirm Update Subscription!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            updateFunc()
          }
        });
      }
      const handleShowForm = () => {
        if(oldStatus) {
          switch(oldStatus) {
            case "rejected":
              return (
                <>
                <div className='form-group col-sm-12 col-md-4'>
                  <input type='radio' name='status' className='mx-2 cursor-pointer'
                    onChange={(e) => setStatus(e.target.value)}
                    value={"unconfirmed"}
                    id='unconfirmed'
                    />
                  <label className='cursor-pointer translate-y-[-3px]' htmlFor='unconfirmed'>Un Confirm</label>
                </div>
                <div className='form-group col-sm-12 col-md-4'>
                    <input type='radio' name='status' className='mx-2 cursor-pointer'
                    onChange={(e) => setStatus(e.target.value)}
                    value={"confirmed"}
                    id='confirmed'
                    />
                    <label className='cursor-pointer translate-y-[-3px]' htmlFor='confirmed'>Confirem</label>
                </div>
                </>
              );
            case "confirmed" :
              return (
                <>
                  <div className='form-group col-sm-12 col-md-4'>
                    <input type='radio' name='status' className='mx-2 cursor-pointer'
                      onChange={(e) => setStatus(e.target.value)}
                      value={"unconfirmed"}
                      id='unconfirmed'
                      />
                    <label className='cursor-pointer translate-y-[-3px]' htmlFor='unconfirmed'>Un Confirm</label>
                  </div>
                  <div className='form-group col-sm-12 col-md-4'>
                      <input type='radio' name='status' className='mx-2 cursor-pointer'
                      onChange={(e) => setStatus(e.target.value)}
                      value={"rejected"}
                      id='rejected'
                      />
                      <label className='cursor-pointer translate-y-[-3px]' htmlFor='rejected'>Reject</label>
                  </div>
              </>
              );
            case "unconfirmed":
              return (
                <>
                  <div className='form-group col-sm-12 col-md-4'>
                      <input type='radio' name='status' className='mx-2 cursor-pointer'
                      onChange={(e) => setStatus(e.target.value)}
                      value={"confirmed"}
                      id='confirmed'
                      />
                      <label className='cursor-pointer translate-y-[-3px]' htmlFor='confirmed'>Confirem</label>
                  </div>
                  <div className='form-group col-sm-12 col-md-4'>
                      <input type='radio' name='status' className='mx-2 cursor-pointer'
                      onChange={(e) => setStatus(e.target.value)}
                      value={"rejected"}
                      id='rejected'
                      />
                      <label className='cursor-pointer translate-y-[-3px]' htmlFor='rejected'>Reject</label>
                  </div>
                </>
              );
            default :
              console.log(oldStatus)
          }
        }
      }
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form 
        onSubmit={updateSubscripe}
        id='update-payment-status'
        >
          <div className='row'>
            {handleShowForm()}
          </div>
        </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type='submit' form='update-payment-status' className={`${updateSubscripeLoading || oldStatus === status ? "pointer-events-none opacity-70" : ""}`}>
            {
                updateSubscripeLoading
                ?
                t("loading...")
                :
                "Save Changes"
            }
            
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
