import { useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { getSingleChapterFunc } from "../../../store/custom-actions/chapters/GetSingleChapterSlicer";
import ContextApi from "../../../store/ContextApi";
import { Spinner } from "react-bootstrap";
import ChapterCard from "./ChapterCard";
import ChapterLessonsTable from "./ChapterLessons/LessonsTable";
import { useTranslation } from "react-i18next";

export default function SingleChapterPage() {
    const {t} = useTranslation();
    const {chapterId} = useParams();
    const contextApi = useContext(ContextApi);
    const dispatch = useDispatch();
    const {loading:getChapterLoading , data:chapterData} = useSelector(
        state => state.getSingleChapterSlicer
    )
    const [chapter , setChapter] = useState({});
    const [chapterChildren , setChapterChildren] = useState([]);
    const [chapterLessns , setChapterLessns] = useState([]);
    useEffect(() => {
        const chapterApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}`;
        const getChapterData = () => {
            dispatch(getSingleChapterFunc(chapterApi))
        }
        getChapterData();
    },[chapterId])
    useEffect(() => {
        if(chapterData) {
            setChapter(chapterData)
            console.log(chapterData)
            setChapterChildren(chapterData.all_children)
            setChapterLessns(chapterData.lessons)
        }
    },[chapterData])
    return (
        <>
            <PageTitle motherMenu={"Course"} activeMenu={"Single Chapter"} />
            {/* {
                !getChapterLoading
                &&
                <div className="relative flex justify-end mb-1">
                    <Link
                        to={`/new-chapter/${chapterId}`}
                        className="btn btn-primary btn-sm"
                    >
                        Create
                        <i className="fa-solid fa-plus ml-1"></i>
                    </Link>
                </div>
            } */}
            {
                getChapterLoading
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                </div>
                :
                (
                    chapterData
                    ?
                    <>
                        <div className="courses-section h-fit relative row">
                            {
                                chapterChildren && chapterChildren.length >= 1
                                ?
                                (
                                    chapterChildren.map((child , index) => {
                                        const {id , lessons , all_children , name , order} = child;
                                        // const {name:courseName} = course;
                                        return (
                                            <ChapterCard
                                            id={id}
                                            chapterName={name}
                                            chapterOrder={order}
                                            courseName={"courseName"}
                                            lessonsCount={lessons.length}
                                            childernCount={all_children.length}
                                            refetchData = {() => dispatch(getSingleChapterFunc(`${contextApi.dashboard_URL_API}/chapters/${chapterId}`))}
                                            />
                                        )
                                    })
                                )
                                :
                                <h3 className="text-center my-3 w-full">{t("No Chapters For This Item!")}</h3>
                            }
                        </div>
                        <ChapterLessonsTable
                            lessonsData={chapterData.lessons}
                            chapterId={chapterId}
                            refetchSingleChapter = {() => dispatch(getSingleChapterFunc(`${contextApi.dashboard_URL_API}/chapters/${chapterId}`))}
                        />
                    </>
                    :
                    <h3 className="text-center my-3 w-full">{t("No Data!")}</h3>
                )
            }
        </>
    )
}