import "./stuff-page.css";
import { Spinner } from "react-bootstrap";
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { fetchUsersFunc } from "../../../../store/custom-actions/users-actions/GetUsersSlicer";
import { deleteUserFunc } from "../../../../store/custom-actions/users-actions/DeleteUserSlicer";
import ContextApi from "../../../../store/ContextApi";
import checkPermissions from "../../../global-functions/checkPermissionsFunc";
import PageTitle from "../../../layouts/PageTitle";
import RowOfStuff from "./RowOfStuff";
import { useTranslation } from "react-i18next";


export default function StuffPage() {
    const {t} = useTranslation();
    const {loading:getUsersLoading , data:users} = useSelector(state => state.fetchUsersSlicer)
    const {loading:deleteUserLoading} = useSelector(state => state.deleteUserSlicer)
    const dispatch = useDispatch();
    const [modalShow , setModalShow] = useState(false)
    const contextApi = useContext(ContextApi);
    const users_API = `${contextApi.dashboard_URL_API}/users`
    const roles_API = `${contextApi.dashboard_URL_API}/roles`
    // const users_API = `${users_API}`;
    // const users_API = `${users_API}?role=teacher`;
    const [usersList , setUsersList] = useState([]);
    const [rolesList , setRolesList] = useState([]);
    const [stuffList , setStuffList] = useState([])
    const {loading:getRolesLoading , data:rolesData} = useSelector(
      state => state.getRolesSlicer
  )

    const deleteUserFunction = async (userId) => {
      const deleteFunc = () => {
        dispatch(deleteUserFunc(`${users_API}/${userId}`))
        .then((result) => {
          if(result.payload.success === true) {
            dispatch(fetchUsersFunc(users_API));
          }
        })
      }
      swal({
        title: "Confirm User Deletion",
        text: `Once deleted, will not be user in platform!`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          deleteFunc()
        } else {
          swal("User is safe!");
        }
      });
    }

    // useEffect(() => {
    //   const usersApi = `${users_API}?}${filterWord ? `publish=${filterWord}&` : ""}${searchVal ? `search=${searchVal}` : ""}`;
    //   let searchTimeout;
    //   let fetchNewStudentsData = async ()=>{
    //     if(searchVal !== "") {
    //       clearTimeout(searchTimeout);
    //       searchTimeout = setTimeout(()=>{
    //         dispatch(fetchUsersFunc(usersApi))
    //       },2000)
    //     }else {
    //       dispatch(fetchUsersFunc(usersApi))
    //     }
    //   }
    //   fetchNewStudentsData()
    //   return () => clearTimeout(searchTimeout)
    // },[filterWord , searchVal , filterRole])

    useEffect(()=>{
      const notMangerUsers = users && users.filter(user => user.roles[0].name.toLowerCase() !== "manager");
      users && notMangerUsers && setUsersList(notMangerUsers)
    },[users]);

  //   const getRoles = async () => {
  //     try {
  //         dispatch(getRolesFunc(roles_API))
  //     }
  //     catch (error){
  //         alert(error)
  //     }
  // }
  // useEffect(() => {
  //     getRoles()
  // },[])
  useEffect(() => {
      // rolesData && setRolesList(rolesData)
      const uniqRoles = rolesData && rolesData.filter(role => role.name.toLowerCase() !== "manager" && role.name.toLowerCase() !== "teacher");
      setRolesList(uniqRoles)
  },[rolesData])
  useEffect(() => {
    users && setStuffList(users);
    users && (jobData.current = users.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      ));
  }, [users]);
  const sort = 20;
  let paggination = Array(Math.ceil(stuffList.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    stuffList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    )
  );
  const onClick = (i) => {
    activePag.current = i;
    jobData.current = stuffList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
  };
  const [searchValue , setSearchValue] = useState("")
  const search = () => {
    if(searchValue !== "") {
      const searchResult = users &&
      users.length >= 1 && users.filter((user) =>  
        user.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        // ||
        // cat.sku.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );
      jobData.current = searchResult
    }else {
      dispatch(fetchUsersFunc(users_API))
    }
  }
  useEffect(() => {
    searchValue === "" && dispatch(fetchUsersFunc(users_API));
  },[searchValue])

return (
    <>
    <PageTitle motherMenu={"Chapter"} activeMenu={"Stuff"} />
    {
        !getUsersLoading && usersList.length < 1
        &&
        checkPermissions(
          "create lessons",
          <div className="relative mt-2 flex justify-end items-center">
            <Link 
            to={`/new-user/stuff`} 
            className="btn btn-primary btn-sm"
            >
                {t("Create")}
                <i className="fa-solid fa-plus ml-1"></i>
            </Link>
          </div>
        )
    }
    {
        getUsersLoading
        ?
        (
            <>
                <div className="row d-flex justify-content-center gap-2">
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
                </div>
            </>
        )
        :
        (
            users
            ?
            (
                usersList && usersList.length >= 1
                &&
                <div className="col-12">
                <div className="card">
                  <div className="card-header flex justify-between items-center">
                    <h4 className="card-title">{t("Stuff List")}</h4>
                    <div className="relative add-and-search flex items-center">
                      <div className="input-group search-area w-auto mr-2">
                          <span className="input-group-text"
                            onClick={() => search()}
                          >
                              <Link to={"#"}>
                              <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path
                                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                  fill="var(--primary)"
                                  ></path>
                              </svg>
                              </Link>
                          </span>
                          <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                              onChange={(e) => {
                              setSearchValue(e.target.value);
                              }}
                          />
                      </div>
                      {
                        checkPermissions(
                          "create users",
                          <Link 
                          to={`/new-user/stuff`} 
                          className="btn btn-primary btn-sm"
                          >
                            {t("Create")}
                            <i className="fa-solid fa-plus ml-1"></i>
                          </Link>
                        )
                      }
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="w-100 table-responsive stuff-table">
                      <div id="example_wrapper" className="dataTables_wrapper">
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr role="row">
                              {/* <th>#ID</th> */}
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Phone")}</th>
                              <th>{t("Status")}</th>
                              <th>{t("Role")}</th>
                              {
                                checkPermissions(
                                  ["update users" , "delete users"],
                                  <th>{t("Actions")}</th>
                                )
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              jobData.current.map((user , index) => {
                              if(user.roles[0].name !== "manager" && user.roles[0].name !== "teacher") {
                                return (
                                  <RowOfStuff key={index} user={user} />
                                )
                              }
                              }) 
                            }
                          </tbody>
                        </table>
          
                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                          <div className="dataTables_info">
                            {t("Showing")} {activePag.current * sort + 1} to{" "}
                            {usersList.length > (activePag.current + 1) * sort
                              ? (activePag.current + 1) * sort
                              : usersList.length}{" "}
                            {t("of")} {usersList.length} {t("entries")}
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers mb-0"
                            id="example5_paginate"
                          >
                            <Link
                              className="paginate_button previous disabled"
                            //   to="/table-datatable-basic"
                              to="#"
                              onClick={() =>
                                activePag.current > 0 && onClick(activePag.current - 1)
                              }
                            >
                              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                            </Link>
                            <span>
                              {paggination.map((number, i) => (
                                <Link
                                  key={i}
                                //   to="/table-datatable-basic"
                                  to="#"
                                  className={`paginate_button  ${
                                    activePag.current === i ? "current" : ""
                                  } `}
                                  onClick={() => onClick(i)}
                                >
                                  {number}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                            //   to="/table-datatable-basic"
                              to="#"
                              onClick={() =>
                                activePag.current + 1 < paggination.length &&
                                onClick(activePag.current + 1)
                              }
                            >
                              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            )
            :
            <h3 className="text-center">{t("No Data!")}</h3>
        )
    }
    {/* <OverLayLoading loading={deleteCategoriesLoading} loadingText={"Deleting Lesson..."}/> */}
    </>
)
}
