import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoicesFunc } from "../../../store/custom-actions/invoices-actions/GetInvoicesSlicer";
import ContextApi from "../../../store/ContextApi";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import StudentApexLineChart from "../../components/Instructor/Instructor/StudentApexLineChart";
import { deleteInvoicesFunc } from "../../../store/custom-actions/invoices-actions/DeleteInvoicesSlicer";
import swal from "sweetalert";
import noSubscripeIcon from "../../../media/images/page.png";
import studentIcon from "../../../media/images/graduated.png";
import AddInvoiceForm from "../students-page/AddInvoiceForm";
import subscriptionImg from "../../../media/images/calendar.png"
import { updateInvoicePaymentConfermationFunc } from "../../../store/custom-actions/invoices-actions/UpdateInvoicePaymentConfermation";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { useTranslation } from "react-i18next";
function InvoicesPage() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [invoicesList , setInvoicesList] = useState([]);
    const [showSubscriptionForm , setShowSubscriptionForm] = useState();
    const contextApi = useContext(ContextApi);
    const invoicesApi = `${contextApi.dashboard_URL_API}/invoices`
    const {loading:getInvoicesLoading , data:invoicesData} = useSelector(state => state.getInvoicesSlicer)
    const {loading:addInvoicesLoading} = useSelector(state => state.addInvoicesSlicer)
    const {loading:deleteInvoiceLoading} = useSelector(state => state.deleteInvoicesSlicer)
    const {loading:updatePaymentLoading} = useSelector(state => state.updateInvoicePaymentConfermationSlicer)
    const [subscriptionCourseSearchValue , setSubscriptionCourseSearchValue] = useState();
    const [subscriptionStudentSearchValue , setSubscriptionStudentSearchValue] = useState("");
    const [subscriptionsStudentsList , setSubscriptionsStudentsList] = useState([]);
    useEffect(()=>{
      invoicesData && setInvoicesList(invoicesData);
      if(invoicesData) {
        // courses && setCoursesList(courses);
        const allInvoicesStudents = invoicesData && invoicesData.flatMap(invoice => invoice.student);
        // for(let i=0 ; i < invoicesList.length ; i++) {
        //   setAllExecutions([...allExecutions , invoicesList.course_executions])
        // }
        // invoicesData && invoicesData.map((invoice) => {
        //     setAllExecutions([...allExecutions , invoice.course_executions])
        // })
        const allInvoicesExecutions = invoicesData && invoicesData.flatMap(invoice => invoice.course_execution && invoice.course_executions);
        // const coursesToString =  new Set(allInvoicesCourses.map((course) => JSON.stringify(course)));
        // const uniqeInvoicesCoursesArray = Array.from(coursesToString).map(course => JSON.parse(course));
        
        const studentsToString = allInvoicesStudents && allInvoicesStudents.length >= 1 && new Set(allInvoicesStudents.map((student) => JSON.stringify(student)));
        const uniqeSubscriptionStudentsArray = Array.from(studentsToString).map(student => JSON.parse(student));
  
        allInvoicesStudents.length > 0 
        && 
        setSubscriptionsStudentsList(uniqeSubscriptionStudentsArray);
      }

      // allInvoicesCourses.length > 0 
      // && 
      // setCoursesList(uniqeInvoicesCoursesArray);
    },[invoicesData])
    const deleteSupscription = async (invoiceId) => {
      const deleteFunction = () => {
        dispatch(deleteInvoicesFunc(`${invoicesApi}/${invoiceId}`))
        .then(result => {
            if(result.payload.success === true){
              dispatch(getInvoicesFunc(invoicesApi));
            }
        });
      }
        swal({
          title: t("Confirm Deletion Subscripe"),
          text: t(`If Delete it Student Will not avilable in this!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteFunction()
          }
        });
    }
    useEffect(() => {
      const getInvoices = async () => {
          dispatch(getInvoicesFunc(`${invoicesApi}?${subscriptionCourseSearchValue ? `course_id=${subscriptionCourseSearchValue}` : ""}&${subscriptionStudentSearchValue ? `student_id=${subscriptionStudentSearchValue}` : ""}`))
      }
      getInvoices();
  },[subscriptionCourseSearchValue , subscriptionStudentSearchValue])


  const checkPaymentStatus = (paymentStatus) => {
    if(paymentStatus) {
      switch (paymentStatus) {
        case "rejected" :
          return   (
          <span className={`relative w-fit p-1 text-white rounded-md bg-red-500`}>
            <>
              <i className="fa-solid fa-circle-xmark mr-1"></i>
              {paymentStatus}
            </>
          </span> 
          )
        case "unconfirmed" :
          return   (
          <span className={`relative w-fit p-1 text-white rounded-md bg-orange-500`}>
            <>
              <i className="fa-solid fa-hourglass-half mr-1"></i>
              {t("Pending")}
            </>
          </span> 
          )
        case "confirmed" :
          return   (
          <span className={`relative w-fit p-1 text-white rounded-md bg-green-500`}>
            <>
              <i className="fa-solid fa-circle-check mr-1"></i>
              {paymentStatus}
            </>
          </span> 
          )
          default :
          return   (
            <span className={`relative w-fit p-1 text-white rounded-md bg-orange-500`}>
              <>
                <i className="fa-solid fa-circle-xmark mr-1"></i>
                {paymentStatus}
              </>
            </span> 
            )
      }
    }
  }
  const updatePaymentStatus = async (invoiceId , invoiceDescion , warningtext) => {
    const updateFunc = () => {
      dispatch(updateInvoicePaymentConfermationFunc({api : `${invoicesApi}/${invoiceId}` , data : {status_payment : invoiceDescion}}))
      .then(result => {
        if(result.payload.success === true) {
          dispatch(getInvoicesFunc(invoicesApi))
        }
      })
    }
    swal({
      title: "Cofirmation Payments",
      text: warningtext,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        updateFunc()
      }
    });
  }

  const checkPaymentbtns = (status , invoiceId) => {
    if(status) {
      switch (status) {
        case "rejected" :
          return   (
          <>
            <button
              className="ml-1"
              onClick={() => {
                updatePaymentStatus(invoiceId , "confirmed" , 'Are you sure about Confirm this invoice!');
              }}
            >
                <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 "
                >
                    <i className="fa-solid fa-check-double text-white"></i>
                </div>
            </button>
            <button
                className="ml-1"
                onClick={() => {
                  updatePaymentStatus(invoiceId , "unconfirmed" , t('Are you sure about Mark this invoice as pending!'));
                }}
              >
                  <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 "
                  >
                      <i className="fa-regular fa-clock text-white"></i>
                  </div>
            </button>
          </>
          )
        case "unconfirmed" :
          return   (
          <>
            <button
              className="ml-1"
              onClick={() => {
                updatePaymentStatus(invoiceId , "confirmed" , t('Are you sure about Confirm this invoice!'));
              }}
            >
                <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 "
                >
                    <i className="fa-solid fa-check-double text-white"></i>
                </div>
            </button>
            <button
              className="ml-1"
              onClick={() => {
                updatePaymentStatus(invoiceId , "rejected" , t('Are you sure about reject this invoice!'));
              }}
            >
                <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 "
                >
                    <i className="fa-solid fa-circle-xmark text-white"></i>
                </div>
            </button>
          </> 
          )
        case "confirmed" :
          return   (
            <>
                <button
                  className="ml-1"
                  onClick={() => {
                    updatePaymentStatus(invoiceId , "unconfirmed" , t('Are you sure about Mark this invoice as pending!'));
                  }}
                >
                    <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 "
                    >
                        <i className="fa-regular fa-clock text-white"></i>
                    </div>
                </button>
                <button
                className="ml-1"
                onClick={() => {
                  updatePaymentStatus(invoiceId , "rejected" , t('Are you sure about reject this invoice!'));
                }}
              >
                  <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 "
                  >
                      <i className="fa-solid fa-circle-xmark text-white"></i>
                  </div>
              </button>
            </>
          )
          default :
          return   (
            <span className={`relative w-fit p-1 text-white rounded-md bg-orange-500`}>
              no status
            </span> 
            )
      }
    }
  }


    return (
        <>
        <div className="row">
            <>
              <div className="col-xl-12 flex justify-between mb-3">
                <div className="rounded-md bg-primary col-sm-12 col-md-5">
                  <div className="card-body">
                    <div className="student-box d-flex justify-content-between align-items-center flex-wrap">
                      <div className="flex">
                        <span className="student-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.1665 19.1666C17.8331 19.1666 20.8331 16.1666 20.8331 12.4999C20.8331 8.83325 17.8331 5.83325 14.1665 5.83325C10.4998 5.83325 7.49981 8.83325 7.49981 12.4999C7.49981 16.1666 10.4998 19.1666 14.1665 19.1666ZM21.6665 17.6666C24.4998 19.9999 28.6665 19.4999 30.9998 16.6666C33.3331 13.8333 32.8331 9.66659 29.9998 7.33325C28.8331 6.33325 27.3331 5.83325 25.8331 5.83325C24.3331 5.83325 22.8331 6.33325 21.6665 7.33325C24.4998 9.66659 24.9998 13.6666 22.6665 16.6666C22.4998 16.9999 21.9998 17.3333 21.6665 17.6666ZM38.1665 32.3333C37.1665 26.1666 31.9998 21.6666 25.8331 21.6666C24.3331 21.6666 22.9998 21.8333 21.6665 22.3333C25.9998 23.8333 29.1665 27.6666 29.8331 32.1666C29.9998 32.9999 29.3331 33.9999 28.3331 33.9999H28.1665H36.4998C37.4998 33.9999 38.1665 33.3333 38.1665 32.3333C38.1665 32.4999 38.1665 32.3333 38.1665 32.3333ZM14.1665 21.6666C7.99981 21.6666 2.66647 26.1666 1.83314 32.3333C1.66647 33.1666 2.33314 34.1666 3.33314 34.1666H3.49981H24.8331C25.8331 34.1666 26.4998 33.4999 26.4998 32.4999V32.3333C25.6665 26.1666 20.3331 21.6666 14.1665 21.6666Z"
                              fill="var(--secondary)"
                            />
                          </svg>
                        </span>
                        <div className="ml-2">
                          <h4 className="text-white">{invoicesList && invoicesList.length}</h4>
                          <span className="pb-3 d-block">Total Invoices</span>
                        </div>
                      </div>
                      <StudentApexLineChart />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="card students-list">
                  <div className="card-header border-0 flex-wrap pb-0">
                    <h4 className="relative flex items-center">
                      <img src={subscriptionImg} alt="img" className="w-[20px] mr-1" />
                      Invoices List
                    </h4>
                    <div className="flex">
                        <div className="relative flex items-center mr-2">
                          {
                            invoicesData && subscriptionsStudentsList.length > 0
                            &&
                            <select
                                className="relative p-2 rounded-md shadow-sm shadow-slate-300 mr-3"
                                onChange={(e)=>setSubscriptionStudentSearchValue(e.target.value)}
                            >
                                <option value={""}>All Students</option>
                                {
                                    invoicesData && subscriptionsStudentsList.length > 0
                                    &&
                                    subscriptionsStudentsList.map(student => {
                                        return <option key={student.id} value={student.id}>{student.name}</option>
                                    })
                                }
                            </select>
                          }
                        </div>
                        {
                          invoicesData
                          &&
                          checkPermissions
                          (
                            "invoices_create"
                            ,
                            <button
                              className="add-student-btn px-3 relative text-white py-2 rounded-md ml-3 bg-[#4d5fe3] flex justify-center items-center transition-all duration-500 border overflow-hidden"
                              onClick={() => {
                                  setShowSubscriptionForm(true)
                              }}
                            >
                              <i className="fa-solid fa-plus transition-all duration-700 absolute left-0 opacity-0 delay-[.2s]"></i>
                              <span className="relative mx-[2px] transition-all duration-500 delay-[.1s]">
                                New
                              </span>
                              <span className="relative mx-[2px] transition-all duration-500 delay-[]"></span>
                              <span className="relative mx-[2px] transition-all duration-500 delay-[0s]">
                                Invoice
                              </span>
                            </button>
                          )
                        }
                    </div>
                  </div>
                  <div className="card-body py-0">
                    <div className="table-responsive h-[800px]">
                      <div
                        id="student_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          className="table display mb-4 dataTablesCard order-table card-table text-black application "
                          id="application-tbl1_next"
                        >
                          <thead>
                            <tr>
                              <th className="text-[#4d5fe3]">ID</th>
                              <th className="text-[#4d5fe3]">Student</th>
                              <th className="text-[#4d5fe3]">Payment</th>
                              <th className="text-[#4d5fe3]">Payment Status</th>
                              <th className="text-[#4d5fe3]">Total Price</th>
                              <th className="text-[#4d5fe3]">Creation</th>
                              <th className="text-[#4d5fe3]">Last Update</th>
                              <th className="text-[#4d5fe3] text-end">
                                Decision
                              </th>
                            </tr>
                          </thead>
                          {
                            getInvoicesLoading 
                            ?
                            <>
                            <div className="row d-flex justify-content-center gap-2 my-4 pl-4">
                              <Spinner animation="grow" variant="warning" />
                              <Spinner animation="grow" variant="warning" />
                              <Spinner animation="grow" variant="warning" />
                              <Spinner animation="grow" variant="warning" />
                            </div>
                            </>
                            :
                            <tbody>
                              {invoicesData
                                ?
                                (
                                invoicesList.length > 0 ?
                                invoicesList.map((invoice, index) => {
                                  // const {title , date_student_status} = bulck.course_execution
                                  const creationDate = new Date(invoice.created_at)
                                  const creationYear = creationDate.getFullYear();
                                  const creationMonth = creationDate.getMonth()+1;
                                  const creationDay = creationDate.getDate();
                                  const latestUpdateDate = new Date(invoice.updated_at)
                                  const latestUpdateYear = latestUpdateDate.getFullYear();
                                  const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                  const latestUpdateDay = creationDate.getDate();
                                  return (
                                    <tr key={index}>
                                      <td className="font-bold text-[#4d5fe3]">
                                        ID:{invoice.id}
                                      </td>
                                      <td>
                                          <img src={invoice.student.image_url !== null ? invoice.student.image_url : studentIcon} alt="st img" className="relative rounded-[50%] mr-1" />
                                          <h4 className="mb-0 fs-16 font-w500 ml-2">
                                            <Link to={`student-profile/${invoice.student_id}`}>{invoice.student.name}</Link>
                                          </h4>
                                      </td>
                                      <td>
                                        {invoice.type_payment}
                                      </td>
                                      <td>
                                          {
                                            checkPaymentStatus(invoice.status_payment)
                                          }
                                      </td>
                                      <td className="">
                                        <p className="m-0">
                                            {invoice.total_price}&pound;
                                        </p>
                                      </td>
                                      <td>
                                        {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                      </td>
                                      <td className="">
                                        {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                      </td>
                                      <td>
                                        {checkPaymentbtns(invoice.status_payment , invoice.id)}
                                        {
                                          checkPermissions
                                          (
                                            "invoices_delete"
                                            ,
                                            <button
                                              className="ml-1"
                                              onClick={() => {
                                                deleteSupscription(invoice.id)
                                              }}
                                            >
                                                <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 ml-1"
                                                >
                                                    <i className="fa-solid fa-trash text-white"></i>
                                                </div>
                                            </button>
                                          )
                                        }
                                        <Link
                                          to= {`single-invoice/${invoice.id}`}
                                          className="ml-1 w-fit inline-flex"
                                        >
                                            <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2 ml-1"
                                            >
                                                <i className="fa-solid fa-eye text-white"></i>
                                            </div>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })
                                :
                                <td colSpan={3} className="flex flex-col justify-center items-center">
                                        <img src={noSubscripeIcon} alt="no subscription" className="w-[50px] border-none"/>
                                        <p className="m-0 border-none">No Invoices Yet !</p> 
                                </td>
                                )
                                :
                                <td colSpan={3} className="flex flex-col justify-center items-center">
                                  <img src={noSubscripeIcon} alt="no subscription" className="w-[50px] border-none"/>
                                  <p className="m-0 border-none">Feild Load Invoices !</p> 
                                </td>
                                }
                            </tbody>
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
        </div>
        <OverLayLoading 
            loading={deleteInvoiceLoading} 
            loadingText={"Deleting Invoice..."}
        />
        <OverLayLoading
            loading={addInvoicesLoading} 
            loadingText={"Addition Subscription..."}
        />
        <OverLayLoading
            loading={updatePaymentLoading} 
            loadingText={"Update Confermation..."}
        />
        {
        showSubscriptionForm
        &&
        <AddInvoiceForm
          show = {showSubscriptionForm}
          onHide = {()=>setShowSubscriptionForm(false)}
          refetchFunction = {()=> dispatch(getInvoicesFunc(invoicesApi))}
          studentId = {true}
        />
      }
      </>
    )
}

export default InvoicesPage;