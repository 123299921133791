import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import ContextApi from "../../../store/ContextApi";
import { useContext } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { fetchSingleStudentFunc } from "../../../store/custom-actions/students-actions/GetSingleStudentSlicer";
import swal from "sweetalert";
import { deleteStudentFunc } from "../../../store/custom-actions/students-actions/DeleteStudentSlicer";
import { fetchAllStudentsFromApi } from "../../../store/custom-actions/students-actions/GetAllStudentsSlicer";
import { useForm } from "react-hook-form";
import { editStudentFunc } from "../../../store/custom-actions/students-actions/EditStudentSlicer";
import studentImg from "../../../media/images/graduated.png";
import ChangeUserPasswordForm from "../custom-drop-down/ChangePasswordForm";
import AddInvoiceForm from "./AddInvoiceForm";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import checkUserRole from "../../global-functions/checkUserRole";
import SubscriptionsTable from "./SubscriptionsTable";
import NewSubscripeForm from "../../custom-forms/subscriptions/NewSubscripeForm";
import { useTranslation } from "react-i18next";


function StudentProfilePage() {
  const {t} = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const contextApi = useContext(ContextApi);
  const student_course_URL_APi = `${contextApi.dashboard_URL_API}/invoices?student_id=${params.studentId}`;
  const student_URL_APi = `${contextApi.dashboard_URL_API}/students/${params.studentId}`;
  const { loading, student } = useSelector(
    (state) => state.getSingleStudentSlicer
  );
  const users_API = `${contextApi.dashboard_URL_API}/users`
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { data:invoices } = useSelector((state) => state.getInvoicesSlicer);
  const { students } = useSelector((state) => state.getAllStudents);
  const students_URL_APi = `${contextApi.dashboard_URL_API}/students`;
  const [studentData, setStudentData] = useState();
  const [studentCourses, setStudentCoursesa] = useState([]);
  const [allstudents, setAllstudents] = useState([]);
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [emailAndPassUsed, setEmailAndPassUsed] = useState();
  const navigate = useNavigate();
  const validName = /[a-zA-Z]{3,}/;
  const validEmail = /^[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
  const validPhone = /[0-9]{11,}/;
  const studentEmail = watch("email");
  const [sendMessage, setSendMessage] = useState();
  const [showChangePassForm, setShowChangePassForm] = useState();
  const [showSubscriptionForm, setShowSubscriptionForm] = useState();
  const {editStudentloading} = useSelector(state => state.editStudentSlicer)
  useEffect(() => {
    const getStudentData = async () => {
      dispatch(fetchSingleStudentFunc(student_URL_APi));
    };
    getStudentData();
  }, [
    dispatch,
    student_URL_APi,
    student_course_URL_APi,
    students_URL_APi,
  ]);

  useEffect(() => {
    student && setStudentData(student);
    student && setStudentCoursesa(student.courses);
    students && setAllstudents(students);
  }, [student, invoices, students]);

  const deleteFunction = async () => {
    dispatch(deleteStudentFunc(student_URL_APi))
    .then(result => {
      if(result.payload.success === true) {
        dispatch(fetchAllStudentsFromApi(students_URL_APi));
        navigate("/students");
      }
    })
  };

  useEffect(() => {
    const alreadyData =
      allstudents &&
      allstudents.find((student) => student.email === studentEmail);
    alreadyData ? setEmailAndPassUsed(true) : setEmailAndPassUsed(false);
  }, [allstudents, studentEmail]);


  useEffect(() => {
    if (student) {
      setValue("name", student.name);
      // setValue("email", student.email);
      setValue("phone", student.phone);
      // setValue("attendance_type", student.attendance_type);
    }
  }, [setValue, student]);

  return (
    <>
      <PageTitle activeMenu="Profile" motherMenu="App" />
      {studentData && (
        <>
          <div className="row">
            <div className="col-sm-12 student-img">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content">
                    <div className=" h-[200px] relative rounded overflow-hidden">
                      <img
                        src={"https://images.unsplash.com/photo-1513542789411-b6a5d4f31634?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                        alt="cover"
                        className="absolute w-full h-[200px] top-0 left-0"
                      />
                    </div>
                  </div>
                  <div className="profile-info">
                    <div className="relative main-img-div mt-0 translate-y-[-60px] bg-slate-400 flex justify-center items-center rounded-[50%]">
                      <img
                        src={studentData.image_url !== null ? studentData.image_url : studentImg}
                        className=" w-[100px] h-[90px] rounded-[50%]"
                        alt="profile img"
                      />
                    </div>
                    <div className="profile-details">
                      <div className="profile-name px-3 pt-2">
                        <h4 className="text-primary mb-0">
                          {studentData.name}
                        </h4>
                        <p>{"student"}</p>
                      </div>
                      <div className="profile-email px-2 pt-2">
                        <h4 className="text-muted mb-0">{studentData.email}</h4>
                        <p>{t("Email")}</p>
                      </div>
                      {
                        (
                          checkPermissions(
                            "students_update",
                            <Dropdown className="dropdown ms-auto">
                              <Dropdown.Toggle
                                variant="primary"
                                className="btn btn-primary light sharp i-false"
                                data-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <circle
                                      fill="#000000"
                                      cx="5"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                    <circle
                                      fill="#000000"
                                      cx="12"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                    <circle
                                      fill="#000000"
                                      cx="19"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                  </g>
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                {
                                  checkUserRole
                                  (
                                    ["manager"]
                                    ,
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        setShowChangePassForm(true)
                                      }}
                                    >
                                      <i className="fa-solid fa-key text-primary me-2"></i>
                                      {t("Change Password")}
                                    </Dropdown.Item>
                                  )
                                }
                                {
                                  checkPermissions
                                  (
                                    "students_delete"
                                    ,
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={() => {
                                        swal({
                                          title: t("Confirm Student Deletion"),
                                          text: t(`Once deleted, ${studentData.name} will not be student in platform!`),
                                          icon: "warning",
                                          buttons: true,
                                          dangerMode: true,
                                        }).then((willDelete) => {
                                          if (willDelete) {
                                            deleteFunction(student_URL_APi);
                                            swal(t("Poof! Your student has been deleted!"), {
                                              icon: "success",
                                            });
                                          } else {
                                            swal(t("Your student is safe!"));
                                          }
                                        });
                                      }}
                                    >
                                      <i className="fa fa-ban text-primary me-2" />
                                      {t("Delete")}
                                    </Dropdown.Item>
                                  )
                                }
                              </Dropdown.Menu>
                            </Dropdown>

                          ) 
                          || 
                          checkPermissions(
                            "students_delete",
                            <Dropdown className="dropdown ms-auto">
                              <Dropdown.Toggle
                                variant="primary"
                                className="btn btn-primary light sharp i-false"
                                data-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <circle
                                      fill="#000000"
                                      cx="5"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                    <circle
                                      fill="#000000"
                                      cx="12"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                    <circle
                                      fill="#000000"
                                      cx="19"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                  </g>
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                {
                                  checkUserRole
                                  (
                                    ["manager"]
                                    ,
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        setShowChangePassForm(true)
                                      }}
                                    >
                                      <i className="fa-solid fa-key text-primary me-2"></i>
                                      {t("Change Password")}
                                    </Dropdown.Item>
                                  )
                                }
                                {
                                  checkPermissions
                                  (
                                    "students_delete"
                                    ,
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={() => {
                                        swal({
                                          title: t("Confirm Student Deletion"),
                                          text: t(`Once deleted, will not be student in platform!`),
                                          icon: "warning",
                                          buttons: true,
                                          dangerMode: true,
                                        }).then((willDelete) => {
                                          if (willDelete) {
                                            deleteFunction(student_URL_APi);
                                            swal(t("Poof! Your student has been deleted!"), {
                                              icon: "success",
                                            });
                                          } else {
                                            swal(t("Your student is safe!"));
                                          }
                                        });
                                      }}
                                    >
                                      <i className="fa fa-ban text-primary me-2" />
                                      {t("Delete")}
                                    </Dropdown.Item>
                                  )
                                }
                              </Dropdown.Menu>
                            </Dropdown>

                          )
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-st-section flex flex-col md:flex-row justify-between">
            {/* <div className="col-sm-12 col-lg-12 col-xl-5 md:flex md:justify-between lg:block">
              <div className="col-sm-12 col-lg-5 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-statistics">
                      <div className="text-center">
                        <div className="row">
                          <div className="col">
                            <h3 className="m-b-0 text-sm">
                              {studentData && studentData.publish}
                            </h3>{" "}
                            <span>Active Status</span>
                          </div>
                        </div>
                        <div className="mt-4">
                          <Button
                            as="a"
                            href={`mailto:${studentData && studentData.email}`}
                            className="btn btn-primary mb-1 ms-1"
                            // onClick={() => setSendMessage(true)}
                          >
                            Send Message
                          </Button>
                        </div>
                      </div>
                      <Modal className="modal fade" show={sendMessage}>
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">
                              Send Message to {studentData.name}
                            </h5>
                            <Button
                              variant=""
                              type="button"
                              className="btn-close"
                              data-dismiss="modal"
                              onClick={() => setSendMessage(false)}
                            ></Button>
                          </div>
                          <div className="modal-body">
                            <form
                              className="comment-form"
                              onSubmit={(e) => {
                                e.preventDefault();
                                setSendMessage(false);
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="author"
                                      className="text-black font-w600"
                                    >
                                      {" "}
                                      Name <span className="required">
                                        *
                                      </span>{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="Author"
                                      name="Author"
                                      placeholder="Author"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="email"
                                      className="text-black font-w600"
                                    >
                                      {" "}
                                      Email <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="Email"
                                      placeholder="Email"
                                      name="Email"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="comment"
                                      className="text-black font-w600"
                                    >
                                      Comment
                                    </label>
                                    <textarea
                                      rows={8}
                                      className="form-control"
                                      name="comment"
                                      placeholder="Comment"
                                      defaultValue={""}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="form-group mb-3">
                                    <input
                                      type="submit"
                                      value="Post Comment"
                                      className="submit btn btn-primary"
                                      name="submit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <ul className="nav nav-tabs">
                        <li
                          className="nav-item"
                          onClick={() => setActiveToggle("aboutMe")}
                        >
                          <Link
                            to="#about-me"
                            data-toggle="tab"
                            className={`nav-link ${
                              activeToggle === "aboutMe" ? "active show" : ""
                            }`}
                          >
                            {t("About")}
                          </Link>
                        </li>
                        {
                          checkPermissions
                          (
                            "students_update"
                            ,
                            <li className="nav-item">
                              <Link
                                to="#profile-settings"
                                data-toggle="tab"
                                onClick={() => setActiveToggle("setting")}
                                className={`nav-link ${
                                  activeToggle === "setting" ? "active show" : ""
                                }`}
                              >
                                {t("Setting")}
                              </Link>
                            </li>
                          )
                        }
                      </ul>
                      <div className="tab-content">
                        <div
                          id="about-me"
                          className={`tab-pane fade ${
                            activeToggle === "aboutMe" ? "active show" : ""
                          }`}
                        >
                          <div className="profile-about-me">
                            <div className="pt-4 border-bottom-1 pb-3">
                              <h4 className="text-primary">
                                {t("About")} {studentData.name}
                              </h4>
                              <p className="mb-2">
                                A wonderful serenity has taken possession of my
                                entire soul, like these sweet mornings of spring
                                which I enjoy with my whole heart. I am alone,
                                and feel the charm of existence was created for
                                the bliss of souls like mine.I am so happy, my
                                dear friend, so absorbed in the exquisite sense
                                of mere tranquil existence, that I neglect my
                                talents.
                              </p>
                            </div>
                          </div>
                          <div className="profile-personal-info">
                            <h4 className="text-primary mb-4">
                              {t("Personal Information")}
                            </h4>
                            <div className="row mb-2">
                              <div className="col-4">
                                <h5 className="f-w-500">
                                  {t("Name")}
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8">
                                <span>{studentData.name}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <h5 className="f-w-500">
                                  {t("Email")}
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8">
                                <span>{studentData.email}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <h5 className="f-w-500">
                                  {" "}
                                  {t("Phone")}
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8">
                                <span>{studentData.phone}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <h5 className="f-w-500">
                                  {t("Created At")}
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8">
                                <span>{studentData.created_at}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          checkPermissions
                          (
                            "students_update"
                            ,
                            <div
                              id="profile-settings"
                              className={`tab-pane fade ${
                                activeToggle === "setting" ? "active show" : ""
                              }`}
                            >
                              <div className="pt-3">
                                <div className="settings-form">
                                  <h4 className="text-primary">{t("Account Setting")}</h4>
                                  <form
                                    id="update-student-form"
                                    onSubmit={handleSubmit((data) => {
                                        dispatch(
                                          editStudentFunc({
                                            api: student_URL_APi,
                                            newData: data,
                                          })
                                        ).then(result => {
                                          if(result.payload.success === true){
                                            window.location.reload();
                                            reset();

                                          }
                                        })
                                    })}
                                  >
                                    <div className="row">
                                      <div className="form-group mb-3 col-sm-12 col-md-6">
                                        <label>{t("New Name")}</label>
                                        {errors.name?.message && (
                                          <p className="text-red-600">
                                            {errors.name?.message}
                                          </p>
                                        )}
                                        <input
                                          {...register("name", {
                                            required: true,
                                            pattern: validName,
                                            minLength: {
                                              value: 3,
                                              message:
                                                t("Name must be more than 3 letters!"),
                                            },
                                          })}
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                      {/* <div className="form-group mb-3 col-sm-12 col-md-6">
                                        <label>New Email</label>
                                        {errors.email?.message && (
                                          <p className="text-red-600">
                                            {errors.email?.message}
                                          </p>
                                        )}
                                        <input
                                          {...register("email", {
                                            required: "Email is required",
                                            pattern: validEmail,
                                          })}
                                          type="text"
                                          className="form-control"
                                        />
                                      </div> */}
                                      <div className="form-group mb-3 col-sm-12 col-md-6">
                                        <label>{t("New Phone")}</label>
                                        {errors.phone?.message && (
                                          <p className="text-red-600">
                                            {errors.studentPhone?.message}
                                          </p>
                                        )}
                                        <input
                                          {...register("phone", {
                                            required: t("Phone is required"),
                                            pattern: validPhone,
                                          })}
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      className={`btn btn-primary ${editStudentloading ? "opacity-80 pointer-events-none" : ""}`}
                                    >
                                      {!editStudentloading 
                                        ? 
                                        t("Update") 
                                        : 
                                        <div className="relative flex justify-center items-center">
                                          {t("Updating")}...
                                          <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1 "></div>
                                        </div>
                                      }
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SubscriptionsTable subscriptionsData={studentData.subscriptions} student={studentData} refetchData={() => dispatch(fetchSingleStudentFunc(student_URL_APi))}/>
        </>
      )}
      {/* <UpdateImageForm
        show={showUpdateImageForm}
        onHide={() => setShowUpdateImageForm(false)} 
        userapi = {users_API}
        student_url = {student_URL_APi}
      /> */}
      <ChangeUserPasswordForm
        show={showChangePassForm}
        onHide={() => setShowChangePassForm(false)} 
        refetchFunction= {() => dispatch(fetchSingleStudentFunc(student_URL_APi))}
        userId = {params.studentId}
      />
      <AddInvoiceForm
        show = {showSubscriptionForm}
        onHide = {()=>setShowSubscriptionForm(false)}
        refetchData = {()=> dispatch(fetchSingleStudentFunc(student_URL_APi))}
        student = {studentData}
      />
    </>
  );
}

export default StudentProfilePage;
