import "./question-page.css";
import { useDispatch, useSelector } from "react-redux"
import { Spinner} from "react-bootstrap"
import { useContext, useEffect, useRef, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import swal from "sweetalert";
import { Link, useParams } from "react-router-dom";
import { deleteQuestionFunc } from "../../../store/custom-actions/Question-actions/DeleteQuestionSlicer";
import { getQuestionsFunc } from "../../../store/custom-actions/Question-actions/GetQuestionsSlicer";
import QuestionOptionsList from "./QuestionOptionsList";
import ShowLessonVideo from "../../global-compnents/lesson-box-components/ShowLessonVideoModal";
import Swal from "sweetalert2";
import ShowImageModal from "../../global-compnents/show-image-modal/ShowImageModal";
import checkUserRole from "../../global-functions/checkUserRole";
import UpdateQuestionVideo from "./UpdateQuestionVideo";
// import NewQuestionForm from "../../global-compnents/NewQuestionForm";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { useTranslation } from "react-i18next";


function QuestionsPage() {    
    const {t} = useTranslation();
    const {lessonId} = useParams();
    const {loading , categoriesData} = useSelector(
        state => state.getAllCategoriesSlicer
    )
    const {deleteCategoriesLoading} = useSelector(
        state => state.deleteCategorySlicer
    );
    const {deleteQuestionLoading} = useSelector(
        state => state.deleteQuestionSlicer
    );
    const {getQuestionsLoading , data:questionsData} = useSelector(
        state => state.getQuestionsSlicer
    )
    const contextApi = useContext(ContextApi);
    const delete_questions_API_URL = `${contextApi.dashboard_URL_API}/questions`;
    const questions_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lessonId}/questions`;
    const [questionsList , setQuestionsList] = useState([]);
    const [category , setCategory] = useState();
    const [QuestionOptions , setQuestionOptions] = useState([]);
    const [QuestionCorrectOption , setQuestionCorrectOption] = useState();
    const [showQuestionOPtions , setShowQuestionOPtions] = useState();
    const [questionWhichShowIt , setQuestionWhichShowIt] = useState();
    const [questionType , setQuestionType] = useState("");
    const [showQuestionVideo , setShowQuestionVideo] = useState();
    const [questionVideo , setQuestionVideo] = useState();
    const [showQuestionImage , setShowQuestionImage] = useState();
    const [questionImage , setQuestionImage] = useState();
    const [showUpdateQuestionVideo , setShowUpdateQuestionVideo] = useState();
    const [questionId , setQuestionId] = useState();
    const [showQuestionForm , setShowQuestionForm] = useState();
    const [filtredQuestions , setFiltredQuestions] = useState([])
    const [searchValue , setSearchValue] = useState("")
    const [lessonName , setLessonName] = useState("Lesson");
    
    
    const dispatch = useDispatch();
    useEffect(() => {
        const getData = () => {
            dispatch(getQuestionsFunc(questions_API_URL))
        }
        getData()
    },[])

      useEffect(() => {
        if(questionsData && questionsData.length >= 1) {
            setQuestionsList(questionsData);
            setFiltredQuestions(questionsData.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            ));
            questionsData[0]?.lesson?.name && setLessonName(questionsData[0].lesson.name) 
        }
      },[questionsData])

      const sort = 20;
      const activePag = useRef(0);

  
      const onClick = (i) => {
          activePag.current = i;
          setFiltredQuestions(questionsList.slice(
              activePag.current * sort,
              (activePag.current + 1) * sort
          ));
      };
          let paggination = Array(Math.ceil(questionsList.length / sort))
        .fill()
        .map((_, i) => i + 1);
  
      useEffect(() => {
          if (searchValue !== "" || questionType !== "") {
              const searchResult = questionsData &&
                  questionsData.length >= 1 && questionsData.filter((question) => (
                    // question.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                    // &&
                    question.type == questionType
                    )
                  );
                  console.log(searchResult)
              setFiltredQuestions(searchResult);
          } else {
              setFiltredQuestions(questionsData.slice(
                  activePag.current * sort,
                  (activePag.current + 1) * sort
              ));
          }
      }, [searchValue, questionsData , questionType]);

      const deleteQuestionFunction = async (questionId) => {
        swal({
            title: t("Confirm Question Deletion"),
            text: t(`Once deleted, will not be quize in platform!`),
            icon: "warning",
            buttons: true,
            dangerMode: true,
            }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteQuestionFunc(`${delete_questions_API_URL}/${questionId}`))
                .then(result => {
                    if(result.payload.success === true) {
                        dispatch(getQuestionsFunc(questions_API_URL));
                    }
                })
            }
            });
    }
    useEffect(() => {
        setCategory(0)
    },[])
    const switchQuestionType = (type) => {
        if(type) {
            switch(type) {
                case 1:
                    return t("True & False")
                case 2:
                    return t("Choose")
                case 3:
                    return t("Essay")
                default:
                    return ""
            }
        }
    }
    return (
        <>
        <PageTitle motherMenu={"Chapter"} activeMenu={lessonName} customClasses={"mb-0"}/>
        <div className="categories-section relative ">
            {
                getQuestionsLoading 
                ?
                (
                    <>
                    <div className="row d-flex justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                  </>
                )
                :
                (
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header flex justify-between items-center">
                                <h4 className="card-title">{t("Questions")}</h4>
                                <div className="relative add-and-search flex items-center">
                                    <div className="input-group search-area w-auto mr-2">
                                        <span className="input-group-text">
                                            <Link to={"#"}>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                        fill="var(--primary)"
                                                    ></path>
                                                </svg>
                                            </Link>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here..."
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                    </div>
                                    <div className="filter-icon relative  mx-2">
                                        <select className="form-control" id="filter-courses-btn"
                                            onChange={(e)=>{
                                            setQuestionType(e.target.value)
                                            }}
                                            defaultValue={""}
                                        >
                                            <option value={""}>{t("All Types")}</option>
                                            <option value={3}>{t("Essay Question")}</option>
                                            <option value={2}>{t("Choose Question")}</option>
                                            <option value={1}>{t("True & False")}</option>
                                        </select>
                                    </div>
                                    {checkPermissions(
                                        "create questions",
                                        <Link
                                            to={`/new-question/${lessonId}`}
                                            className="btn btn-primary btn-sm"
                                            // onClick={() => setShowNew}
                                        >
                                            {t("Create")}
                                            <i className="fa-solid fa-plus ml-1"></i>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        <div className="card-body">
                                    <div className="w-100 table-responsive">
                                        <div id="example_wrapper" className="dataTables_wrapper">
                                            <table id="example" className="display w-100 dataTable">
                                                <thead>
                                                    <tr>
                                                        <th className="">#{t("ID")}</th>
                                                        <th className="">{t("Question")}</th>
                                                        <th className="">{t("Type")}</th>
                                                        <th className="">{t("Grade")}</th>
                                                        <th className="">{t("Creation")}</th>
                                                        <th className="">{t("Last Update")}</th>
                                                        <th className=" text-end">
                                                            {t("Decision")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filtredQuestions && filtredQuestions.length >= 1
                                                        ?
                                                        filtredQuestions.map((question, index) => {
                                                            const creationDate = new Date(question.created_at)
                                                            const creationYear = creationDate.getFullYear();
                                                            const creationMonth = creationDate.getMonth()+1;
                                                            const creationDay = creationDate.getDate();
                                                            const latestUpdateDate = new Date(question.updated_at)
                                                            const latestUpdateYear = latestUpdateDate.getFullYear();
                                                            const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                                            const latestUpdateDay = creationDate.getDate();
                                                            return (
                                                            // <RowOfLesson lesson={lesson} key={index}/>
                                                            <tr key={index}>
                                                                <td className="font-bold">
                                                                #{question.id}
                                                                </td>
                                                                <td>
                                                                <div className="d-flex align-items-center">
                                                                    <i className="fa-solid fa-circle-question text-[20px] text-slate-700"></i>
                                                                    <h4 className="mb-0 fs-16 font-w500 ml-2">
                                                                        <div className="essay-q-content" dangerouslySetInnerHTML={{__html : question?.title?.slice(0,20)}} />
                                                                    </h4>
                                                                </div>
                                                                </td>
                                                                <td>{switchQuestionType(question?.type)}</td>
                                                                <td>{question.grade}</td>
                                                                <td>
                                                                    {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                                                </td>
                                                                <td className="">
                                                                    {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                                                </td>
                                                                <td className="">
                                                                    {
                                                                        checkUserRole
                                                                        (
                                                                            "manager"
                                                                            ,
                                                                            <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                                onClick={() => {
                                                                                    deleteQuestionFunction(question.id)
                                                                                }}
                                                                            >
                                                                                <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                                >
                                                                                    <i className="fa-solid fa-trash text-white"></i>
                                                                                    <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                                                    >{t("Delete")}</span>
                                                                                </div>
                                                                            </button>
                                                                        )
                                                                    }
                                                                    <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                        onClick={() => {
                                                                            setQuestionOptions(question.options);
                                                                            setQuestionCorrectOption(question.correct_option);
                                                                            setShowQuestionOPtions(true);
                                                                            setQuestionWhichShowIt(question)
                                                                            // if(question.type !== 3) {
                                                                            // }else {
                                                                            //     Swal.fire({
                                                                            //         title: t("Sorry!"),
                                                                            //         text: t("No Options For This Question!"),
                                                                            //         icon: "warning"
                                                                            //     });
                                                                            // }
                                                                        }}
                                                                    >
                                                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                        >
                                                                            <i className="fa-solid fa-eye text-white"></i>
                                                                        </div>
                                                                    </button>
                                                                    {/* <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                        onClick={() => {
                                                                            if(question.video_url) {
                                                                                setQuestionVideo(question.video_url);
                                                                                setShowQuestionVideo(true);
                                                                            }else {
                                                                                Swal.fire({
                                                                                    title: t("Sorry!"),
                                                                                    text: t("No Video For This Question!"),
                                                                                    icon: "warning"
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                        >
                                                                            <i className="fa-solid fa-circle-play text-white"></i>
                                                                        </div>
                                                                    </button> */}
                                                                </td>
                                                            </tr>
                                                            )
                                                        }
                                                            
                                                    )
                                                        :
                                                        (
                                                            searchValue !== "" 
                                                            &&
                                                            <tr>
                                                                <td colSpan={3}>{t("No Data Match Search Value!")}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        searchValue === "" && filtredQuestions && filtredQuestions.length < 1 
                                                        &&
                                                        <tr>
                                                            <td colSpan={3}>{t("No Questions For This Chapter!")}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                                <div className="dataTables_info">
                                                    {t("Showing")} {activePag.current * sort + 1} to{" "}
                                                    {questionsList.length > (activePag.current + 1) * sort
                                                        ? (activePag.current + 1) * sort
                                                        : questionsList.length}{" "}
                                                    {t("of")} {questionsList.length} {t("entries")}
                                                </div>
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                                    id="example5_paginate"
                                                >
                                                    <Link
                                                        className="paginate_button previous disabled"
                                                        to="#"
                                                        onClick={() =>
                                                            activePag.current > 0 && onClick(activePag.current - 1)
                                                        }
                                                    >
                                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                    </Link>
                                                    <span>
                                                        {paggination.map((number, i) => (
                                                            <Link
                                                                key={i}
                                                                to="#"
                                                                className={`paginate_button  ${activePag.current === i ? "current" : ""
                                                                    } `}
                                                                onClick={() => onClick(i)}
                                                            >
                                                                {number}
                                                            </Link>
                                                        ))}
                                                    </span>
                                                    <Link
                                                        className="paginate_button next"
                                                        to="#"
                                                        onClick={() =>
                                                            activePag.current + 1 < paggination.length &&
                                                            onClick(activePag.current + 1)
                                                        }
                                                    >
                                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                )
            }
        </div>
        <OverLayLoading loading={deleteCategoriesLoading} loadingText={"Deleting Category..."}/>
        <OverLayLoading 
            loading={deleteQuestionLoading} 
            loadingText={"Deletnig Question..."}
        />
        <QuestionOptionsList
            show_options = {showQuestionOPtions}
            hide_options = {() => setShowQuestionOPtions(false)}
            options = {QuestionOptions}
            correct_option = {QuestionCorrectOption}
            question = {questionWhichShowIt }
        />
        <ShowLessonVideo
            show={showQuestionVideo}
            onHide={() => setShowQuestionVideo(false)}
            lessonVideoUrl={questionVideo}
        />
        <ShowImageModal
            show={showQuestionImage}
            onHide={() => setShowQuestionImage(false)}
            image={questionImage}
        />
        <UpdateQuestionVideo
            show={showUpdateQuestionVideo}
            onHide={() => setShowUpdateQuestionVideo(false)}
            question_id={questionId}
        />
        {/* {
            showQuestionForm
            &&
            <NewQuestionForm
            show={showQuestionForm}
            onHide={()=>setShowQuestionForm(false)}
            />
        } */}
        </>
    )
}


export default QuestionsPage