import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import noSubscripeIcon from "../../../../media/images/page.png";
import subscriptionImg from "../../../../media/images/calendar.png";
import OverLayLoading from "../../../custom-pages/custome-overlay-loading/OverLayLoading";
import ContextApi from "../../../../store/ContextApi";
import { getLessonsSchuadlesFunc } from "../../../../store/custom-actions/lessons-actions/lessons-schuadles-actions/GetLessonsSchuadlesSlicer";
import { deleteLessonSchuadleFunc } from "../../../../store/custom-actions/lessons-actions/lessons-schuadles-actions/DeleteLessonSchuadle";
import swal from "sweetalert";
import ShowLessonsInTheSameSchuadle from "./ShowLessonsInSameSchuadle";
import PageTitle from "../../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

function ShowLessonsSchuadles() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {chapterId} = useParams();
    const contextApi = useContext(ContextApi);
    const {loading:getSchuadlesLoading ,data} = useSelector(
        state => state.getLessonsSchuadlesSlicer
    );
    const {loading:deleteSchuadleLoading} = useSelector(
        state => state.deleteLessonSchuadleSlicer
    );
    // const schuadles_URL_APi = `${contextApi.dashboard_URL_API}/courses/${courseId}/lesson-schadules`;
    const schuadles_URL_APi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}/lesson-schadules`;
    const delete_schuadles_URL_APi = `${contextApi.dashboard_URL_API}/lesson-schadules`;
    const [searchValue , setSearchValue] = useState("");
    const [schualdesList , setSchuadlesList] = useState([]);
    const [schuadleTitle , setSchuadleTitle] = useState();
    const [showEditSchuadleForm , setShowEditSchuadleForm] = useState();
    const [showRelatedLessonsTable , setShowRelatedLessonsTable] = useState();
    const [lessonSchuadleId , setLessonSchuadleId] = useState();
    

  useEffect(() => {
    let api = `${schuadles_URL_APi}?${searchValue !== "" ? `search=${searchValue}` : ""}`;

    let searchTimeout;
    let fetchCoursesData = async ()=>{
        if(searchValue !== "") {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=>{
          dispatch(getLessonsSchuadlesFunc(api));
        },2000)
      }else {
        dispatch(getLessonsSchuadlesFunc(api));
      }
    }
    fetchCoursesData()
    return () => clearTimeout(searchTimeout)
  },[searchValue])

  useEffect(() => {
    data && setSchuadlesList(data);
  },[data])

  const deleteSchuadle = async (schuadleId) => {
    swal({
      title: t("Confirm Schuadle Deletion"),
      text: t(`Once deleted, This Schuadle will not be avilable in platform!`),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteLessonSchuadleFunc(`${delete_schuadles_URL_APi}/${schuadleId}`))
        .then((result) => {
          if(result.payload.success === true){
            dispatch(getLessonsSchuadlesFunc(schuadles_URL_APi));
          }
        })
      }
    });
  };

  const sort = 20;
  let paggination = Array(Math.ceil(schualdesList.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    schualdesList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    )
  );
  const onClick = (i) => {
    activePag.current = i;
    jobData.current = schualdesList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
  };

    return (
        <>
        <PageTitle motherMenu={"Chapter"} activeMenu={"Lessons Schuadles"} />
        <div className="row">
          <div className="col-xl-12">
            <div className="card students-list">
              <div className="card-header border-0 flex-wrap pb-0">
                <h4 className="relative flex items-center">
                  <img src={subscriptionImg} alt="img" className="w-[20px] mr-1" />
                  {t("Schuadles List")}
                </h4>
                <div className="flex items-center">
                    <div className="input-group search-area w-auto mr-1">
                    <span className="input-group-text">
                        <Link to={"#"}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                            fill="var(--primary)"
                            ></path>
                        </svg>
                        </Link>
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t("Search here...")}
                        onChange={(e) => {
                        setSearchValue(e.target.value);
                        }}
                    />
                    </div>
                    <Link 
                    to={`/new-lesson-schuadles/${chapterId}`}
                    className="btn btn-primary btn-sm"
                    >
                      {t("Create")}
                      <i className="fa-solid fa-plus ml-1"></i>
                    </Link>
                </div>
              </div>
              <div className="card-body py-0">
                <div className="table-responsive">
                  <div
                    id="student_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      className="table display mb-4 dataTablesCard order-table card-table text-black application "
                      id="application-tbl1_next"
                    >
                      <thead>
                        <tr>
                          <th className="">#{t("ID")}</th>
                          <th className="">{t("Title")}</th>
                          <th className="">{t("Creation")}</th>
                          <th className="">{t("Last Update")}</th>
                          <th className="">{t("Course EX")}</th>
                          <th className="">
                            {t("Decision")}
                          </th>
                        </tr>
                      </thead>
                      {
                        getSchuadlesLoading 
                        ?
                        <>
                        <div className="row d-flex justify-content-center gap-2 my-4 pl-4">
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                        </div>
                        </>
                        :
                        <tbody>
                          {data &&
                            schualdesList.length > 0 ?
                            schualdesList.map((schuadle, index) => {
                                const {course_execution} = schuadle;
                                const {title:courseExcutionTitle , date_student_status} = course_execution;
                                const creationDate = new Date(schuadle.created_at)
                                const creationYear = creationDate.getFullYear();
                                const creationMonth = creationDate.getMonth()+1;
                                const creationDay = creationDate.getDate();
                                const latestUpdateDate = new Date(schuadle.updated_at)
                                const latestUpdateYear = latestUpdateDate.getFullYear();
                                const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                const latestUpdateDay = creationDate.getDate();
                              return (
                                <tr key={index}>
                                  <td className="font-bold">
                                    #{schuadle.id}
                                  </td>
                                  <td className="relative flex items-center">
                                        <img src={subscriptionImg} alt="img" className="w-[15px] mr-1" />
                                      {schuadle.title}
                                  </td>
                                  <td>
                                    {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                  </td>
                                  <td className="">
                                  {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                  </td>
                                  <td className="">
                                    <h5 className="m-0 flex items-center">
                                        {courseExcutionTitle}
                                        <i className={`fa-solid fa-temperature-full ml-2 ${date_student_status === "expired" ? "text-red-500" : "text-green-500"}`}></i>
                                    </h5>
                                  </td>
                                  <td className="">
                                    <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                        onClick={() => {
                                            deleteSchuadle(schuadle.id)
                                        }}
                                    >
                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                        >
                                            <i className="fa-solid fa-trash text-white"></i>
                                            <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                            >{t("Delete")}</span>
                                        </div>
                                    </button>
                                    <Link to={`/update-schuadle/${schuadle.id}?chapter_id=${chapterId}`} class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                        onClick={() => {
                                            setShowEditSchuadleForm(true);
                                            setSchuadleTitle(schuadle.title)
                                        }}
                                        >
                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                        >
                                            <i className="fa-solid fa-pen-clip text-white"></i>
                                            <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                            >{t("Update")}</span>
                                        </div>
                                    </Link>
                                    <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                        onClick={() => {
                                            setSchuadleTitle(schuadle.title)
                                            setShowRelatedLessonsTable(true)
                                            setLessonSchuadleId(schuadle.id)
                                        }}
                                    >
                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                        >
                                            <i className="fa-solid fa-paperclip text-white"></i>
                                        </div>
                                    </button>
                                </td>
                                </tr>
                              );
                            })
                            :
                            <td colSpan={8} className="flex flex-col justify-center items-center">
                                    <img src={noSubscripeIcon} alt="no subscription" className="w-[50px] border-none"/>
                                    <p className="m-0 border-none">{t("No Schuadles!")}</p> 
                            </td>
                            }
                        </tbody>
                      }
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                          <div className="dataTables_info">
                            {t("Showing")} {activePag.current * sort + 1} to{" "}
                            {schualdesList.length > (activePag.current + 1) * sort
                              ? (activePag.current + 1) * sort
                              : schualdesList.length}{" "}
                            {t("of")} {schualdesList.length} {t("entries")}
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers mb-0"
                            id="example5_paginate"
                          >
                            <Link
                              className="paginate_button previous disabled"
                            //   to="/table-datatable-basic"
                              to="#"
                              onClick={() =>
                                activePag.current > 0 && onClick(activePag.current - 1)
                              }
                            >
                              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                            </Link>
                            <span>
                              {paggination.map((number, i) => (
                                <Link
                                  key={i}
                                //   to="/table-datatable-basic"
                                  to="#"
                                  className={`paginate_button  ${
                                    activePag.current === i ? "current" : ""
                                  } `}
                                  onClick={() => onClick(i)}
                                >
                                  {number}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                            //   to="/table-datatable-basic"
                              to="#"
                              onClick={() =>
                                activePag.current + 1 < paggination.length &&
                                onClick(activePag.current + 1)
                              }
                            >
                              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                            </Link>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
            showRelatedLessonsTable
            &&
            <ShowLessonsInTheSameSchuadle
                show = {showRelatedLessonsTable}
                onHide = {() => setShowRelatedLessonsTable(false)}
                lesson_schadule_id = {lessonSchuadleId}
                lesson_title = {schuadleTitle}
            />
        }
        <OverLayLoading 
            loading={deleteSchuadleLoading} 
            loadingText={"Deleting Lesson Schuadle..."}
        />
      </>
    )
}

export default ShowLessonsSchuadles;