import React, { useContext, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Link } from "react-router-dom";
import ContextApi from "../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ActiveBox from "./home-components/active-box/ActiveBox";

import courseIcon from "../../../media/images/course-img.png"
import studentIcon from "../../../media/images/graduated (1).png"
import { fetchAllStudentsFromApi } from "../../../store/custom-actions/students-actions/GetAllStudentsSlicer";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import PieChart from "./home-components/chart";
import { toast } from "react-toastify";
import checkUserRole from "../../global-functions/checkUserRole";
import { useTranslation } from "react-i18next";
// import StudentsCharts from "./home-components/StudentsCharts";

const ScoreActivityChart = loadable(() =>
  pMinDelay(import("../../components/Dashboard/Dashboard/ScoreActivityChart"), 1000)
);

const StudentsCharts = loadable(() =>
  pMinDelay(
    import("./home-components/charts/StudentsCharts"),
    1000
  )
);
const CoursesChart = loadable(() =>
  pMinDelay(
    import("./home-components/charts/CoursesChart"),
    1000
  )
);


function Home() {
  const {t} = useTranslation();
  const dispatch = useDispatch()
  const {students} = useSelector(state => state.getAllStudents)
  const {data:instructors} = useSelector(state => state.fetchUsersSlicer)
  const {courses} = useSelector(state => state.getAllCoursesSlicer)
  const [allInstructors , setAllInstructors] = useState([]);
  const [activeInstructors , setActiveInstructors] = useState([]);
  const [activeStudents , setActiveStudents] = useState([]);
  const [allStudents , setAllStudents] = useState([]);
  const [allCourses , setAllCourses] = useState([]);
  const [activeCourses , setActiveCourses] = useState([]);
  const [activeInstructorLoading , setGetActiveInstructorLoading] = useState(true);
  const [activeCoursesLoading , setGetActiveCoursesLoading] = useState(true);
  const [activeStudentsLoading , setGetActiveStudentsLoading] = useState(true);
  const contextApi = useContext(ContextApi);
  const users_API = `${contextApi.dashboard_URL_API}/users`
  const instructors_URL_APi = `${contextApi.dashboard_URL_API}/teachers`;
  const students_URL_APi = `${contextApi.dashboard_URL_API}/students`;
  const courses_URL_APi = `${contextApi.dashboard_URL_API}/courses`;
  const user = JSON.parse(window.localStorage.getItem("user"));
  const userRoleName = user && user.roleName
  const getActiveInstructors = async () => {
    try {
      await axios.get(`${instructors_URL_APi}?publish=publish` )
      .then(response => {
        setActiveInstructors(response.data.data.teachers)
        response ?  setGetActiveInstructorLoading(false) : setGetActiveInstructorLoading(true)
      })
    }
    catch(error) {
      toast.error(error)
    }
  }
  const getActiveStudents = async () => {
    try {
      await axios.get(`${students_URL_APi}?publish=publish` )
      .then(response => {
        setActiveStudents(response.data.data.students)
        response ? setGetActiveStudentsLoading(false) : setGetActiveStudentsLoading(true)
      })
    }
    catch(error) {
      toast.error(error)
    }
  }
  const getActiveCourses = async () => {
    try {
      await axios.get(`${courses_URL_APi}?publish=publish` )
      .then(response => {
        setActiveCourses(response.data.data.courses)
        response ? setGetActiveCoursesLoading(false) : setGetActiveCoursesLoading(true)
      })
    }
    catch(error) {
      toast.error(error);
    }
  }
  const getActiveInstructorAndStudents = async () => {
    // getActiveInstructors();
    if(userRoleName && userRoleName.toLowerCase() === "manager") {
      getActiveStudents();
    }
    getActiveCourses();

    dispatch(fetchAllStudentsFromApi(students_URL_APi))
    // dispatch(fetchUsersFunc(instructors_URL_APi))
    if(userRoleName && (userRoleName.toLowerCase() === "manager" || userRoleName.toLowerCase() === "teacher")){
      dispatch(fetchAllCoursesFunc(courses_URL_APi))
    }
  }
  useEffect(()=>{
    const getActiveUsers = async () => {
        try {
          getActiveInstructorAndStudents()
        }
        catch(error) {
          toast.error(error);
        }
    }
    getActiveUsers();
},[])

useEffect(()=>{
  students && setAllStudents(students);
  instructors && setAllInstructors(instructors);
  courses && setAllCourses(courses);
},[students , instructors , courses])

    return (
      <>
        <div className="home-content flex flex-col relative justify-center">
          <div className="home-header relative flex justify-between border-b border-slate-500 pb-2">
            <h2 className="font-mono">{t("Hello")}, <span className="text-primary">{user ? user.name : "Admin"}</span>!</h2>
            <div className="relative team-box flex items-center">
              <div className="relative flex items-center mr-3">
                <i className="fa-solid fa-users text-white mx-1 p-2 bg-primary rounded-md"></i>
                {t("Team")}
              </div>
              <div className="teams-icons relative flex items-center">
                <div className="relative flex items-center instructors-box mr-3">
                  <Link to="/instructors-all" className="relative flex items-center justify-center">
                    <i className="fa-solid fa-chalkboard-user text-[20px] text-primary mx-1"></i>
                    {t("Users")}
                  </Link>
                </div>
                <div className="relative flex items-center instructors-box">
                  <Link to="/students" className="relative flex items-center justify-center">
                    <i className="fa-solid fa-graduation-cap text-[20px] text-primary mx-1"></i>
                    {t("Students")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="active-line relative flex-col md:flex-row flex mt-[20px]">
            {
              checkUserRole("manager",
              <ActiveBox
                customStyles={"bg-[#8acbe880]"}
                secondStyle={"bg-[#0d8ccd73]"}
                title={t("Active Students")}
                image={studentIcon}
                count={!activeStudentsLoading ? activeStudents.length : t("loading...")}
              />
              
              )
            }
            {
              (userRoleName.toLowerCase() === "manager" || userRoleName.toLowerCase() === "teacher")
              &&
              <ActiveBox
                customStyles={"bg-orange-300"}
                secondStyle={"bg-primary"}
                title={t("Active Courses")}
                image={courseIcon}
                count={!activeCoursesLoading ? activeCourses.length : t("loading...")}
              />
            }
          </div>
          <div className="row mt-4 flex justify-between gap-1">
            <div className="card bg-secondary col-sm-12 col-md-4">
              <div className="card-body">
                <div className="student-box flex flex-col">
                  <div className="relative flex items-end mb-2">
                    <span className="bg-transparent">
                      <img src={studentIcon} alt="student-icon" className="w-[50px]" />
                    </span>
                      <div className="relative flex items-start">
                        <span className="font-bold mr-1">{allStudents && allStudents.length}</span>
                        <span className=" d-block">{t("Total Students")}</span>
                      </div>
                  </div>
                  <StudentsCharts />
                </div>
              </div>
            </div>
            {
              (userRoleName.toLowerCase() === "manager" || userRoleName.toLowerCase() === "teacher")
              &&
              <div className="card bg-primary col-sm-12 col-md-4">
                <div className="card-body">
                  <div className="student-box flex flex-col">
                    <div className="relative flex items-end mb-2">
                      <span className="bg-transparent">
                        <img src={courseIcon} alt="student-icon" className="w-[50px]" />
                      </span>
                        <div className="relative flex items-start">
                          <span className="font-bold mr-1">{allCourses && allCourses.length}</span>
                          <span className=" d-block">{t("Total Courses")}</span>
                        </div>
                    </div>
                    <StudentsCharts />
                  </div>
                </div>
              </div>
            }
            {
              (userRoleName.toLowerCase() === "manager")
              &&
              <div className="charts-div flex items-center col-sm-12 col-md-3">
                <PieChart
                  activeCount={activeInstructors && activeInstructors.length}
                  allCount={allInstructors && allInstructors.length}
                />
              </div>
            }
          </div>
        </div>
      </>
    )

}
export default Home;