import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { getSingleLanguageFunc } from "../../../store/custom-actions/language/GetSingleLanguageSlicer";
import { Button, Spinner } from "react-bootstrap";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import NewTextForm from "./forms/NewTextForm";
import { useTranslation } from "react-i18next";
import { translateTextFunc } from "../../../store/custom-actions/language/TranslateTextSlicer";
import { toast } from "react-toastify";

export default function UpdateLaguageWordsPage() {
    const {t} = useTranslation();
    const {langId} = useParams();
    const contextApi = useContext(ContextApi);
    const langApi = `${contextApi.dashboard_URL_API}/languages/${langId}`;
    const {loading:getSingleLangLoading , data:singleLangData} = useSelector(
        state => state.getSingleLanguageSlicer
    );
    const [searchValue , setSearchValue] = useState("");
    const [langWords , setLangWords] = useState({})
    const dispatch = useDispatch();
    useEffect(() => {
        const getLang = () => {
            dispatch(getSingleLanguageFunc(langApi))
        }
        getLang();
    },[])

    useEffect(() => {
        if(singleLangData) {
            setLangWords(singleLangData.all_word);
        }
    },[singleLangData]);
    const search = () => {
        if(searchValue !== "") {
            if (langWords && singleLangData.all_word) {
                const filtred = Object.entries(langWords).filter(([key , value]) => {
                    return (key.toLowerCase().includes(searchValue.toLowerCase()) 
                    ||
                    value.toLowerCase().includes(searchValue.toLowerCase())) && [key , value]
                })
                filtred.length >= 1 ? setLangWords({...filtred}) : setLangWords(singleLangData.all_word)
                const filteredObject = Object.fromEntries(filtred);
                Object.keys(filteredObject).length >= 1 && setLangWords(filteredObject);
                }
        }else {
            setLangWords(singleLangData.all_word);
        }
    }
    useEffect(() => {
        Object.keys(langWords).length >= 1 && search()
    },[searchValue])

    // const [text , setText] = useState("");
    // const [oldTranslate , setOldTranslate] = useState("");
    const [showNewTextForm , setShowNewTextForm] = useState();
    // const showTranslationFormFunc = (textSource , oldTranslate) => {
    //     setShowTranslationForm(true);
    //     setText(textSource)
    //     setOldTranslate(oldTranslate)
    // }
    const [wordWhichUpdate , setWordWhichUpdate] = useState({});
    const [inputValues, setInputValues] = useState();
    useEffect(() => langWords && setInputValues(langWords) , [langWords])
    const handleInputChange = (wordKey, newValue) => {
        console.log(wordKey, newValue)
      setInputValues((prev) => ({
        ...prev,
        [wordKey]: newValue,
      }));
      setWordWhichUpdate({ string_key: wordKey, translate_word: newValue });
    };
    const translate_API_URL = `${contextApi.dashboard_URL_API}/languages/${langId}`;
    const {loading:translateTextLoading} = useSelector(state => state.translateTextSlicer);
   const handleSubmitData = () => {
      dispatch(translateTextFunc({api : translate_API_URL , data : {...wordWhichUpdate , "_method" : "PUT"}}))
      .then(result => {
          if(result.payload.success === true) {
            toast.success(`${result.payload.message} !`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
          }
      })
    }
    return (
        <>
            <PageTitle motherMenu={"Language"} activeMenu={"Tranlsate Words"} />
            {
                getSingleLangLoading
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                </div>
                :
                (
                    singleLangData
                    ?
                    (
                        langWords && Object.keys(langWords).length >= 1
                        &&
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header flex justify-between items-center">
                                    <h4 className="card-title">{t("Language Words")}</h4>
                                    <div className="relative add-and-search flex items-center">
                                    <div className="input-group search-area w-auto mr-2">
                                        <span className="input-group-text"
                                            // onClick={() => search()}
                                        >
                                            <Link to={"#"}>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                fill="var(--primary)"
                                                ></path>
                                            </svg>
                                            </Link>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("Search here...")}
                                            onChange={(e) => {
                                            setSearchValue(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {
                                        checkPermissions(
                                        "create language",
                                        <Link 
                                        to={"#"} 
                                        className="btn btn-primary btn-sm"
                                        onClick={() => setShowNewTextForm(true)}
                                        >{t("Create")}</Link>
                                        )
                                    }
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="w-100 table-responsive h-[300px] overflow-y-auto">
                                    <div id="example_wrapper" className="dataTables_wrapper">
                                        <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr role="row">
                                                <th>{t("Text")}</th>
                                                <th>{t("Tranlsation")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            Object.entries(inputValues).map(([wordKey , value]) => {
                                                // console.log([wordKey , value])
                                                return (
                                                    <tr 
                                                    className="hover:bg-slate-200 cursor-pointer transition-all duration-300"
                                                    >
                                                        <td>{wordKey}</td>
                                                        <td className="w-[50%]">
                                                            <form 
                                                                id={`translate-text-form${wordKey}`}
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    handleSubmitData()
                                                                    console.log(
                                                                        {
                                                                            wordKey,
                                                                            wordWhichUpdate,
                                                                        }
                                                                    )
                                                                }}
                                                                className="flex items-center"
                                                                >
                                                                    {/* <input  type="text" value={value} className="form-control placeholder:text-black" onChange={(e) => setWordWhichUpdate({string_key : wordKey , translate_word : e.target.value})}/> */}
                                                                    <input  type="text" value={value} className="form-control placeholder:text-black" 
                                                                        onChange={(e) => handleInputChange(wordKey, e.target.value)}
                                                                        onFocus={(e) => handleInputChange(wordKey, e.target.value)}
                                                                    />
                                                                    <Button form={`translate-text-form${wordKey}`} type="submit" onMouseOver={()=> {
                                                                        handleInputChange(wordKey, value)
                                                                    }}>
                                                                        {
                                                                            (
                                                                                wordKey === wordWhichUpdate?.string_key && translateTextLoading

                                                                            )
                                                                            ?
                                                                            t("loading")
                                                                            :
                                                                            t("Update")
                                                                        }
                                                                    </Button>
                                                            </form>
                                                        </td>
                                                    </tr>
                                                    // <RowOfWord textKay = {wordKey} value={value} />
                                                )
                                            }) 
                                            }
                                        </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    <h2 className="text-center">{t("No Data")}</h2>
                )
            }
            {/* <TranslateTextForm
                show={showTranslationForm}
                hide={() => setShowTranslationForm(false)}
                refetetchFunc={() => dispatch(getSingleLanguageFunc(langApi))}
                langId={langId}
                text={text}
                oldTranslate={oldTranslate}
            /> */}
            <NewTextForm
                show={showNewTextForm}
                hide={() => setShowNewTextForm(false)}
                refetetchFunc={() => dispatch(getSingleLanguageFunc(langApi))}
                langSlug={singleLangData && singleLangData.language && singleLangData.language.slug}
            />
        </>
    )
}