import { useEffect, useState } from "react";
import lessonIcon from "../../../../media/images/online-learning.png";
import { useTranslation } from "react-i18next";
function CheckboxForUpdateSchuadle({index , lesson , name , pushingLessonsFunction , lesson_id , courseLessons , lessonsWillSend , schuadleLessonsList}) {
    const {t} = useTranslation();
    const [startDate , setSatartDate] = useState("");
    const [endDate , setEndDate] = useState("");
    const [lessonObject , setLessonObject] = useState();
    const [checked , setChecked] = useState();
    useEffect(() => {
        console.log(schuadleLessonsList)
        const alreadyInScuadle = schuadleLessonsList.find((ls => ls.lesson_id === lesson_id));
        if(alreadyInScuadle) {
            setSatartDate(alreadyInScuadle.pivot.start_date);
            setEndDate(alreadyInScuadle.pivot.end_date);
            setLessonObject({
                start_date : alreadyInScuadle.pivot.start_date ,
                end_date :  alreadyInScuadle.pivot.end_date
            })
            setChecked(true);
        }else {
            setChecked(false);
        }
    },[])
    useEffect(() => {
        if (startDate && endDate) {
            console.log({[lesson_id] : lessonObject})
            pushingLessonsFunction(lesson_id, lessonObject);
          } else if (!startDate && !endDate && lessonObject) {
            pushingLessonsFunction(lesson_id, null); // Remove lesson if both start and end dates are empty
          }
      }, [startDate, endDate, lessonObject, lesson_id]);
    return (
        <div className="row flex items-center col-sm-12 mb-2 justify-between">
            <div className="lesson-title flex items-center col-sm-12 col-md-5">
                <input 
                    checked={checked}
                    onChange={(e)=>{
                        setChecked(!checked)
                        if(e.target.checked) {
                            console.log("checked");
                        }else {
                            setSatartDate("");
                            setEndDate("");
                        }
                    }}
                    type='checkbox' 
                    className={`cursor-pointer m-0 translate-y-[-2px]`} 
                    id={index}/>
                    <label htmlFor={index} className="relative flex items-center ml-2">
                        <img src={lessonIcon} className="mr-1 w-[20px] relative" alt="lesson icon" />
                        <h4 className="m-0 text-slate-700">{name}</h4>
                    </label>
            </div>
            <div className="relative flex items-center justify-between col-sm-12 col-md-7">
                <div className="form-group mb-3 col-sm-12 col-md-5">
                    <label className="relative flex items-center">
                        {t("From")} :
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        onChange={(e) => {
                            setSatartDate(e.target.value)
                            setLessonObject({start_date : e.target.value , endDate});
                        }}
                        value={startDate}
                />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-5">
                    <label className="relative flex items-center">
                        {t("To")} :
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        onChange={(e) => {
                            setEndDate(e.target.value)
                            setLessonObject({start_date:startDate , end_date : e.target.value});
                        }}
                        value={endDate}
                />
                </div>
            </div>
        </div>
    )
}

export default CheckboxForUpdateSchuadle;