import swal from "sweetalert";
import { useContext, useEffect, useState } from "react";
import { deleteCategoryFunc } from "../../../../store/custom-actions/categories-actions/DeleteCategoriesSlicer";
import { approveLessonFunc } from "../../../../store/custom-actions/lessons-actions/ApproveLessonSlicer";
import { unApproveLessonFunc } from "../../../../store/custom-actions/lessons-actions/UnApproveLessonSlicer";
import ShowLessonVideo from "../../../global-compnents/lesson-box-components/ShowLessonVideoModal";
import EditLessonDataForm from "../../../global-compnents/lesson-box-forms/EditLessonDataForm";
import EditLessonMatrialForm from "../../../global-compnents/lesson-box-forms/EditLessonMatrialForm";
import EditLessonOrder from "../../../global-compnents/lesson-box-forms/EditLessonOrder";
import PassingLessonRequireForm from "../../../global-compnents/lesson-box-forms/PassingLessonRequireForm";
import ShareMatrialForm from "../../../global-compnents/lesson-box-forms/ShareMatrialForm";
// import UnLinkQuizzesForm from "../../../global-compnents/lesson-box-forms/UnLinkQuizzesForm";
import UploadLessonVideoForm from "../../../global-compnents/lesson-box-forms/UploadLessonVideoForm";
// import LinkQuizeToLesson from "../../../global-compnents/link-quize-to-lesson/LinkQuizeToLesson";
import checkPermissions from "../../../global-functions/checkPermissionsFunc";
import ApproveItemNofication from "../../courses-page/ApproveItemNofication";
import LessonDescion from "./LessonDescion";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../../store/ContextApi";
import { showLessonVideoFunc } from "../../../../store/custom-actions/lessons-actions/ShowLessonVideoSlicer";
import OverLayLoading from "../../custome-overlay-loading/OverLayLoading";
import { getSingleChapterFunc } from "../../../../store/custom-actions/chapters/GetSingleChapterSlicer";
import axios from "axios";
import { useTranslation } from "react-i18next";


export default function RowOfLesson({lesson , refetchSingleChapter}) {
    const {t} = useTranslation();
    const token = window.localStorage.getItem("token")
    const contextApi = useContext(ContextApi);
    const allLessonsApi = `${contextApi.dashboard_URL_API}/lessons`;
    const chapterApi = `${contextApi.dashboard_URL_API}/chapters/${lesson.chapter_id}`;
    const dispatch = useDispatch();
    const { approve_lesson_loading } = useSelector(
        (state) => state.approveLessonSlicer
    );
    const { deleteLessonLoading } = useSelector(
        (state) => state.deleteLessonSlicer
    );
    const {un_approve_lesson_loading } = useSelector(
        (state) => state.unApproveLessonSlicer
    );
    const { loading: showVideoLoading} = useSelector(
        state => state.showLessonVideoSlicer
    );
    const [videoUrl, setVideoUrl] = useState(null);
    const lesson_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/approve`
    const update_lessons_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}`;
    const approve_lessons_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/approve`;
    const [showApproveNotfication , setShowApproveNotfication ] = useState();
    const [showLinkQuizeForm , setShowLinkQuizeForm ] = useState();
    const upload_lessons_files_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/upload-file`;
    const videoApi = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/stream`;
    const [showVideo, setShowVideo] = useState();
    const [showEditNameAndDescForm, setShowEditNameAndDescForm] = useState();
    const [showEditVideoForm, setShowEditVideoForm] = useState();
    const [showEditMatrialForm, setShowEditMatrialForm] = useState();
    const [showEditOrderform, setShowEditOrderForm] = useState();
    const [showPassingRequireFrom, setShowPassingRequireFrom] = useState();
    const [showShareMatrialForm, setShowShareMatrialForm] = useState();
    const [showUnlinkQuizForm, setShowUnlinkQuizForm] = useState();
    const [showRequirmentsList, setShowRequirmentsList] = useState();
    const approveLesson = async () => {
        if(lesson){
            await dispatch(approveLessonFunc({lessonApi: approve_lessons_api}))
            .then(result => {
                if(result.payload.success === true){
                    dispatch(getSingleChapterFunc(chapterApi))
                }
            })
        }
    }
    const unApproveLesson = async () => {
        if(lesson){
            await dispatch(unApproveLessonFunc({lessonApi: lesson_API_URL}))
            .then(result => {
                if(result.payload.success === true){
                    dispatch(getSingleChapterFunc(chapterApi))
                }
            })
        }
    }
    const showMatrial = async () => {
        // window.open(lesson.file_url , "_blank")
        dispatch(showLessonVideoFunc(videoApi))
        .then((result) => {
            // console.log(result.meta.arg)
            if(result.payload.status && result.payload.status === 404 && result.payload.data) {
                swal({
                    title: t("Error"),
                    text: result.payload.data.error,
                    icon: "error",
                    // buttons: true,
                    dangerMode: true,
                })
            }else {
                if(result.meta.arg) {
                    const token = window.localStorage.getItem("token")
                    // setVideoUrl(`${result.meta.arg}?token=${token}`);
                    // setShowVideo(true)
                    window.open(`${result.meta.arg}?token=${token}`, "_blank")
                }
            }
        })
    }
    const handleDeleteLesson = async () => {
        const deleteFunc = () => {
          try {
            dispatch(deleteCategoryFunc(`${allLessonsApi}/${lesson.id}`))
            .then((result)=>{
              if(result.payload.success === true){
                // refetchSingleChapter();
                
                dispatch(getSingleChapterFunc(chapterApi))
              }
            });
          }catch (error) {
            console.log(error);
          }
        }
        swal({
          title: t("Confirm Item Deletion"),
          text: t(`Once deleted, this will not avilable in platform!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteFunc()
          }
        })
    };
    useEffect(() => {
        const token = window.localStorage.getItem("token")
        axios.defaults.headers.common = {'Authorization' : `Bearer ${token}`};
    },[])
    return (
        <>
            <tr>
                <td>{lesson.id}</td>
                <td>{lesson.name}</td>
                <td>{lesson.type && lesson.type.toUpperCase().replace(/_/g , " ")}</td>
                <td>{lesson.order}</td>
                <td>{lesson.view_count}</td>
                <td>{lesson.publish}</td>
                {
                checkPermissions(
                    ["update lessons" , "delete lessons"],
                    <td>
                        <LessonDescion
                            lessonStatus = {lesson.status}
                            lesson_order = {lesson.order}
                            lessonId = {lesson.id}
                            lesson_type = {lesson.type}
                            quizzes_array = {lesson.quizzes}
                            deleteFunction = {() => handleDeleteLesson(lesson.id)}
                            showEditNameAndDescForm = {()=>setShowEditNameAndDescForm(true)}
                            showEditVideoForm = {()=>setShowEditVideoForm(true)}
                            showEditMatrialForm = {()=>setShowEditMatrialForm(true)}
                            showapprovelessonnotficationFunc = {()=>setShowApproveNotfication(true)}
                            unapprovelessonfunction = {unApproveLesson}
                            showLessonMatrial = {() => showMatrial()}
                            showEditOrderForm = {() => setShowEditOrderForm(true)}
                            showLinkQuizeForm = {() => setShowLinkQuizeForm(true)}
                            showShareFilesForm = {() => setShowShareMatrialForm(true)}
                            showPassLessonRequirmentsForm = {()=>setShowPassingRequireFrom(true)}
                            showUnlinkQuizForm = {()=>setShowUnlinkQuizForm(true)}
                            showRequirmentsList = {()=>setShowRequirmentsList(true)}
                            lesson_id = {lesson.id}
                            handleDeleteChapter = {() => handleDeleteLesson()}
                            visabilaty = {lesson.publish}
                            showLessonVideo = {() => {
                                if(lesson.type === "youtube") {
                                    setVideoUrl(lesson.contents);
                                    // setVideoUrl("https://www.youtube.com/embed/5SprLhOUpp4");
                                    setShowVideo(true);
                                }else {
                                    setVideoUrl(`${contextApi.dashboard_URL_API}/lessons/${lesson?.id}/stream?token=${token}`);
                                    setShowVideo(true);
                                }
                            }}
                            />
                    </td>
                )
                }
            </tr>
            {
                videoUrl
                &&
                <ShowLessonVideo
                    show={showVideo}
                    onHide={() => setShowVideo(false)}
                    lessonTitle={lesson.name}
                    url={videoUrl}
                    lessonType = {lesson?.type}
                />
            }
        <EditLessonDataForm
            show = {showEditNameAndDescForm}
            onHide = {()=>setShowEditNameAndDescForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            update_lessons_api = {update_lessons_api}
        />
        <ShareMatrialForm
            show = {showShareMatrialForm}
            onHide = {()=>setShowShareMatrialForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            update_lessons_api = {update_lessons_api}
            lesson_type = {lesson.type}
        />
        <UploadLessonVideoForm
            show = {showEditVideoForm}
            onHide = {()=>setShowEditVideoForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            upload_video_api = {upload_lessons_files_api}
        />
        <EditLessonMatrialForm
            show = {showEditMatrialForm}
            onHide = {()=>setShowEditMatrialForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            upload_files_url = {upload_lessons_files_api}
            lesson_type = {lesson.type}
        />
        <EditLessonOrder
            show = {showEditOrderform}
            onHide = {()=>setShowEditOrderForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            upload_files_url = {upload_lessons_files_api}
        />
        {/* <UnLinkQuizzesForm
            show = {showUnlinkQuizForm}
            onHide = {()=>setShowUnlinkQuizForm(false)}
            quizzes_list = {lesson.quizzes}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
        /> */}
        <PassingLessonRequireForm
            show = {showPassingRequireFrom}
            onHide = {()=>setShowPassingRequireFrom(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            // course_lessons = {courseLessons}
            lesson_name = {lesson.name}
        />
        {/* {
        showLinkQuizeForm
        &&
        <LinkQuizeToLesson
            show = {showLinkQuizeForm}
            onHide = {()=>setShowLinkQuizeForm(false)}
            // category_id = {courseCategoryId}
            // course_id = {courseId}
            lesson_id = {lesson.id}
        />
        } */}
        <ApproveItemNofication
            show_approve_notfication = {showApproveNotfication}
            hide_approve_notfication = {()=>setShowApproveNotfication(false)}
            itemTitle = {lesson.name}
            refetchFunction = {() => refetchSingleChapter()}
            // courseId = {courseId}
            item = {lesson}
            approve_function={approveLesson}
            approve_loading = {approve_lesson_loading}
        />
        <OverLayLoading loading={deleteLessonLoading} loadingText={"Deleting Lesson..."}/>
        <OverLayLoading loading={showVideoLoading} loadingText={"Loading Video..."}/>
        <OverLayLoading 
            loading={un_approve_lesson_loading} 
            loadingText={"Un Publish Lesson.."}
        />
        </>
    )
}