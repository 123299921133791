import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import ContextApi from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { getBasicSettingsFunc } from "../../../store/custom-actions/basic-settings/GetBasicSettingsSlicer";
import { postBasicSettingsFunc } from "../../../store/custom-actions/basic-settings/PostBasicSettingsSlicer";
import ReactQuill from "react-quill";
import ShowImageModal from "../../global-compnents/show-image-modal/ShowImageModal";
import { useTranslation } from "react-i18next";

function BasicSettingsPage() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const basic_settings_URL_APi = `${contextApi.dashboard_URL_API}/settings/general-settings`;
    const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
        state => state.getBasicSettingsSlicer
    )
    const {loading:postSettingsLoading} = useSelector(
        state => state.postBasicSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const [footerCopyRight , setFooterCopyRight] = useState("");
    const [siteDescription , setSiteDescription] = useState("");
    const [siteLogo , setSiteLogo] = useState(null);
    const [bannerHeader , setBannerHeader] = useState(null);
    const [bannerAbout , setBannerAbout] = useState(null);
    const [bannerFeatures , setBannerFeatures] = useState(null);
    const [showLogo , setShowLogo] = useState(null);
    useEffect(() => {
        if(settingsData) {
            setValue("facebook_link" , settingsData.facebook_link);
            setValue("youtube_link" , settingsData.youtube_link);
            setValue("twitter_link" , settingsData.twitter_link);
            setValue("instagram_link" , settingsData.instagram_link);
            setValue("linkedin_link" , settingsData.linkedin_link);
            setValue("contact_phone" , settingsData.contact_phone)
            setValue("contact_email" , settingsData.contact_email)
            setValue("contact_address" , settingsData.contact_address)
            setValue("site_title" , settingsData.site_title)
            // setValue("site_about" , settingsData.site_about)
            setValue("site_global_currency" , settingsData.site_global_currency)
            setValue("session_duration" , settingsData.session_duration)
            setValue("site_global_email" , settingsData.site_global_email)
            setValue("site_footer_about" , settingsData.site_footer_about)
            setValue("site_description" , settingsData.site_description)
            setFooterCopyRight(settingsData.site_footer_copyright);
            setSiteDescription(settingsData.site_description);
            setSiteLogo(settingsData.siteLogo);
            // setBannerHeader(settingsData.site_banner_header);
            // setBannerAbout(settingsData.site_banner_about);
            // setBannerFeatures(settingsData.site_banner_features);
            console.log(settingsData)
        }
    },[settingsData])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const basicSettingsFormData = new FormData();
            basicSettingsFormData.append("facebook_link" , data.facebook_link);
            basicSettingsFormData.append("youtube_link" , data.youtube_link);
            basicSettingsFormData.append("twitter_link" , data.twitter_link);
            basicSettingsFormData.append("instagram_link" , data.instagram_link);
            basicSettingsFormData.append("linkedin_link" , data.linkedin_link);
            basicSettingsFormData.append("contact_phone" , data.contact_phone);
            basicSettingsFormData.append("contact_email" , data.contact_email);
            basicSettingsFormData.append("contact_address" , data.contact_address);
            basicSettingsFormData.append("site_title" , data.site_title);
            basicSettingsFormData.append("site_footer_about" , data.site_footer_about);
            basicSettingsFormData.append("page_top_students_show" , data.page_top_students_show);
            // basicSettingsFormData.append("site_about" , data.site_about);
            basicSettingsFormData.append("site_description" , data.site_description);
            basicSettingsFormData.append("site_global_email" , data.site_global_email);
            basicSettingsFormData.append("session_duration" , data.session_duration);
            basicSettingsFormData.append("site_global_currency" , data.site_global_currency);
            siteLogo && basicSettingsFormData.append("site_logo" , siteLogo);
            bannerHeader && basicSettingsFormData.append("site_banner_header" , bannerHeader);
            bannerAbout && basicSettingsFormData.append("site_banner_about" , bannerAbout);
            bannerFeatures && basicSettingsFormData.append("site_banner_features" , bannerFeatures);
            basicSettingsFormData.append("site_footer_copyright" , footerCopyRight);
            dispatch(postBasicSettingsFunc({
                api : basic_settings_URL_APi , 
                data : basicSettingsFormData
            }))
            // .then(result => {
            //     if(result.payload.success === true) {
            //         dispatch(getBasicSettingsFunc(basic_settings_URL_APi));
            //     }
            // })
        }
    }
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
          container: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': colors }, { 'background': colors }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video'],
            ['clean']
          ],
        }
      };
    const allCurrencies = [
        "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN",
        "BAM", "BBD", "BDT", "BGN", "BHD", "BIF", "BMD", "BND", "BOB", "BRL",
        "BSD", "BTN", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY",
        "COP", "CRC", "CUP", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP",
        "ERN", "ETB", "EUR", "FJD", "FKP", "FOK", "GBP", "GEL", "GGP", "GHS",
        "GIP", "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HRK", "HTG", "HUF",
        "IDR", "ILS", "IMP", "INR", "IQD", "IRR", "ISK", "JEP", "JMD", "JOD",
        "JPY", "KES", "KGS", "KHR", "KID", "KMF", "KRW", "KWD", "KYD", "KZT",
        "LAK", "LBP", "LKR", "LRD", "LSL", "LYD", "MAD", "MDL", "MGA", "MKD",
        "MMK", "MNT", "MOP", "MRU", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN",
        "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN", "PGK",
        "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR",
        "SBD", "SCR", "SDG", "SEK", "SGD", "SHP", "SLL", "SOS", "SRD", "SSP",
        "STN", "SYP", "SZL", "THB", "TJS", "TMT", "TND", "TOP", "TRY", "TTD",
        "TVD", "TWD", "TZS", "UA",
    ]
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Basic Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button class="rounded-lg relative w-36 h-10 cursor-pointer flex items-center border border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500 overflow-hidden"
                        onClick={() => setShowLogo(true)}
                    >
                        <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">Logo</span>
                        <span class="absolute right-0 h-full w-10 rounded-lg bg-blue-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                            <i className="fa-solid fa-image text-white"></i>
                        </span>
                    </button>
                    <button 
                        type="submit"
                        form="new-settings-form"
                        class={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                <>{t("loading")}...</>
                                :
                                <>
                                    {t("Submit")}
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            {
                getBasicSettingsLoading
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                </div>
                :
                <div className="card p-3">
                    <div className="relative settings-form-content">
                        <div className="new-settings-form-div relative w-full ">
                            <form 
                                className="new-settings-form"
                                id="new-settings-form"
                                onSubmit={handleSubmit((data) => {
                                    handleSubmitSettings(data)
                                })}
                            >
                                {
                                    <>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12 col-md-4">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    {t("Site Title")}
                                                </label>
                                                {errors.site_title?.message && (
                                                <p className="text-red-600">
                                                    {errors.site_title?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("site_title")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-4">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    {t("Site Global Email")}
                                                </label>
                                                {errors.site_global_email?.message && (
                                                <p className="text-red-600">
                                                    {errors.site_global_email?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("site_global_email")}
                                                type="email"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group flex items-center pt-3 mb-3 col-sm-12 col-md-4">
                                                <input
                                                {...register("page_top_students_show")}
                                                type="checkbox"
                                                id="customCheckbox4"
                                                className="cursor-pointer scale-105 mx-2 "
                                                />
                                                <label htmlFor="customCheckbox4" className="flex items-center translate-y-[3px] cursor-pointer">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    {t("Showing Best Students Section")}
                                                </label>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-phone-volume mr-1"></i>
                                                    {t("Phone Number")}
                                                </label>
                                                {errors.contact_phone?.message && (
                                                <p className="text-red-600">
                                                    {errors.contact_phone?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("contact_phone")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-envelope mr-1"></i>
                                                    {t("Email Address")}
                                                </label>
                                                {errors.contact_email?.message && (
                                                <p className="text-red-600">
                                                    {errors.contact_email?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("contact_email")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-map-location-dot mr-1"></i>
                                                    {t("Address")}
                                                </label>
                                                {errors.contact_address?.message && (
                                                <p className="text-red-600">
                                                    {errors.contact_address?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("contact_address")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-image mr-1"></i>
                                                    {t("Site Logo")} ( {t("Best Size")} 100 * 100 )
                                                </label>
                                                {errors.site_logo?.message && (
                                                <p className="text-red-600">
                                                    {errors.site_logo?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("site_logo")}
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => setSiteLogo(e.target.files[0])}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12 col-md-4">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-image mr-1"></i>
                                                    {t("Header Banner")} ( {t("Best Size")} 1828 * 400 )
                                                </label>
                                                {errors.site_banner_header?.message && (
                                                <p className="text-red-600">
                                                    {errors.site_banner_header?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("site_banner_header")}
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => setBannerHeader(e.target.files[0])}
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-4">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-image mr-1"></i>
                                                    {t("About Banner Image")} ( {t("Best Size")} 528 * 400 )
                                                </label>
                                                {errors.site_banner_about?.message && (
                                                <p className="text-red-600">
                                                    {errors.site_banner_about?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("site_banner_about")}
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => setBannerAbout(e.target.files[0])}
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-4">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-image mr-1"></i>
                                                    {t("Features Banner Image")} ( {t("Best Size")} 528 * 400 )
                                                </label>
                                                {errors.site_banner_features?.message && (
                                                <p className="text-red-600">
                                                    {errors.site_banner_features?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("site_banner_features")}
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => setBannerFeatures(e.target.files[0])}
                                                />
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-brands fa-twitter mr-1"></i>
                                                    {t("Twitter URL")}
                                                </label>
                                                {errors.twitter_link?.message && (
                                                <p className="text-red-600">
                                                    {errors.twitter_link?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("twitter_link")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-brands fa-facebook mr-1"></i>
                                                    {t("Facebook URL")}
                                                </label>
                                                {errors.facebook_link?.message && (
                                                <p className="text-red-600">
                                                    {errors.facebook_link?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("facebook_link")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-brands fa-youtube mr-1"></i>
                                                    {t("Youtube URL")}
                                                </label>
                                                {errors.youtube_link?.message && (
                                                <p className="text-red-600">
                                                    {errors.youtube_link?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("youtube_link")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                <i class="fa-brands fa-instagram mr-1"></i>
                                                    {t("Instagram URL")}
                                                </label>
                                                {errors.instagram_link?.message && (
                                                <p className="text-red-600">
                                                    {errors.instagram_link?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("instagram_link")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i class="fa-brands fa-linkedin mr-1"></i>
                                                    {t("Linkedin URL")}
                                                </label>
                                                {errors.linkedin_link?.message && (
                                                <p className="text-red-600">
                                                    {errors.linkedin_link?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("linkedin_link")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label>{t("Currency")}</label>
                                                {errors.name?.message && (
                                                <p className="text-red-600">{errors.name?.message}</p>
                                                )}
                                                <select
                                                    {...register("site_global_currency")}
                                                    className='form-control'
                                                >
                                                {
                                                    allCurrencies.map((currency => <option key={currency}>{currency}</option>))
                                                }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12">
                                                <label>
                                                    <i className="fa-solid fa-stopwatch mr-1"></i>
                                                    {t("Session Duration")} {t("With Munites*")}
                                                </label>
                                                {errors.session_duration?.message && (
                                                <p className="text-red-600">{errors.session_duration?.message}</p>
                                                )}
                                                <input
                                                {...register("session_duration")}
                                                type="nubmer"
                                                className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-barcode mr-1"></i>
                                                    {t("Site Description")}
                                                </label>
                                                <textarea
                                                {...register("site_description")}
                                                className="form-control"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-12">
                                                <label className="flex items-center">
                                                    <i class="fa-solid fa-barcode mr-1"></i>
                                                    {t("Footer Content")}
                                                </label>
                                                <textarea
                                                {...register("site_footer_about")}
                                                className="form-control"
                                                ></textarea>
                                            </div>
                                        </div>
                                        {/* <div className="row p-0 mb-2 mx-0">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                {t("Site About Content")}
                                            </label>
                                            <ReactQuill
                                            modules = {modules}
                                            className="p-0 m-0 border-none outline-none form-control bg-white " 
                                            value={siteDescription} 
                                            theme="snow" 
                                            onChange={(content) => setSiteDescription(content)} />
                                        </div> */}
                                        <div className="row p-0 mb-2 mx-0">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                {t("Site Copyright Content")}
                                            </label>
                                            <ReactQuill
                                            modules = {modules}
                                            className="p-0 m-0 border-none outline-none form-control bg-white " 
                                            value={footerCopyRight} 
                                            theme="snow" 
                                            onChange={(content) => setFooterCopyRight(content)} />
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            }
            <ShowImageModal
                modalTitle = "Site Logo"
                show = {showLogo}
                onHide = {() => setShowLogo(false)}
                image = {settingsData?.site_logo}
            />
        </>
    )
}


export default BasicSettingsPage;