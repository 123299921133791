import { useState , useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import ContextApi from "../../../store/ContextApi";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { deleteUserFunc } from "../../../store/custom-actions/users-actions/DeleteUserSlicer";
import faildImg from "../../../media/images/fail-img.svg";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { getRolesFunc } from "../../../store/custom-actions/roles-actions/GetRolesSlicer";
import NewUserForm from "../../global-compnents/users-forms/NewUserForm";
import swal from "sweetalert";
import UserCard from "./user-card/UserCard";
import { useTranslation } from "react-i18next";


function InstructorsPage () {
    const {t} = useTranslation();
    const {loading , data:users} = useSelector(state => state.fetchUsersSlicer)
    const {loading:deleteUserLoading} = useSelector(state => state.deleteUserSlicer)
    const dispatch = useDispatch();
    const [modalShow , setModalShow] = useState(false)
    const contextApi = useContext(ContextApi);
    const users_API = `${contextApi.dashboard_URL_API}/users`
    const roles_API = `${contextApi.dashboard_URL_API}/roles`
    const instructors_URL_APi = `${users_API}`;
    // const instructors_URL_APi = `${users_API}?role=teacher`;
    const [usersList , setUsersList] = useState([]);
    const [instructorSearchValue , setInstructorSearchValue] = useState();
    const [filterWord , setFilterWord] = useState();
    const [rolesList , setRolesList] = useState([]);
    const [filterRole , setFilterRole] = useState();
    const {loading:getRolesLoading , data:rolesData} = useSelector(
      state => state.getRolesSlicer
  )

    const deleteUserFunction = async (userId) => {
      const deleteFunc = () => {
        dispatch(deleteUserFunc(`${users_API}/${userId}`))
        .then((result) => {
          if(result.payload.success === true) {
            dispatch(fetchUsersFunc(instructors_URL_APi));
          }
        })
      }
      swal({
        title: t("Confirm User Deletion"),
        text: t(`Once deleted, will not be user in platform!`),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          deleteFunc()
        }
      });
    }

    useEffect(() => {
      const getData = () => {
        dispatch(fetchUsersFunc(users_API))
      }
      getData()
    },[])
    // useEffect(() => {
    //   const usersApi = `${users_API}?${filterWord ? `publish=${filterWord}&` : ""}${instructorSearchValue ? `search=${instructorSearchValue}` : ""}`;
    //   let searchTimeout;
    //   let fetchNewStudentsData = async ()=>{
    //     if(instructorSearchValue !== "") {
    //       clearTimeout(searchTimeout);
    //       searchTimeout = setTimeout(()=>{
    //         dispatch(fetchUsersFunc(usersApi))
    //       },2000)
    //     }else {
    //       dispatch(fetchUsersFunc(usersApi))
    //     }
    //   }
    //   fetchNewStudentsData()
    //   return () => clearTimeout(searchTimeout)
    // },[filterWord , instructorSearchValue])

    const search = () => {
      const usersApi = `${users_API}?${filterWord ? `publish=${filterWord}&` : ""}${instructorSearchValue ? `search=${instructorSearchValue}` : ""}`;
      if(instructorSearchValue !== "") {
        dispatch(fetchUsersFunc(usersApi))
      }
    }
    useEffect(() => {
      const usersApi = `${users_API}?${filterWord ? `publish=${filterWord}&` : ""}${instructorSearchValue ? `search=${instructorSearchValue}` : ""}`;
      instructorSearchValue === "" && dispatch(fetchUsersFunc(usersApi))
    },[instructorSearchValue , filterWord])

    useEffect(()=>{
      const notMangerUsers = users && users.filter(user => user.roles[0].name.toLowerCase() === "teacher");
      users && notMangerUsers && setUsersList(notMangerUsers)
    },[users]);

    const getRoles = async () => {
      try {
          dispatch(getRolesFunc(roles_API))
      }
      catch (error){
          alert(error)
      }
  }
  useEffect(() => {
      getRoles()
  },[])
  useEffect(() => {
      // rolesData && setRolesList(rolesData)
      const uniqRoles = rolesData && rolesData.filter(role => role.name.toLowerCase() !== "manager");
      setRolesList(uniqRoles)
  },[rolesData])

    return (
        <>
          <div className="widget-heading d-flex justify-content-between align-items-center">
              <h3 className="m-0 header-text">{t("Instructors")}</h3>
              <div className="input-group search-area w-auto">
                  <span className="input-group-text">
                  <Link to={"#"}
                  onClick={() => search()}
                  >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                      <path
                          d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                          fill="var(--primary)"
                      ></path>
                      </svg>
                  </Link>
                  </span>
                  <input
                  type="text"
                  className="form-control"
                  placeholder={t("Search here...")}
                  // onChange={(e)=>{
                  //     setInstructorSearchValue(e.target.value)
                  // }}
                  />
                  <Link to={`/new-user/instructor`} className="add-student-btn px-3 mr-2 relative text-white py-2 rounded-md bg-primary flex justify-center items-center transition-all duration-500 border overflow-hidden" onClick={()=>{
                      setModalShow(true)
                  }}>{t("Create")}</Link>
                {/* <div className="filter-icon relative  ml-2">
                  {
                    getRolesLoading
                    ?
                    <p>Loading Roles..</p>
                    :
                    <select className="form-control ml-1" id="filter-users-btn"
                      onChange={(e)=>{
                        setFilterRole(e.target.value)
                      }}
                    >
                      <option value={""}>All</option>
                      {
                        rolesList
                        &&
                        rolesList.length >= 1
                        &&
                        rolesList.map((role , index) => {
                          return <option key={index} value={role.name}>{role.name}</option>
                        })
                      }
                    </select>
                  }
                  </div> */}
              </div>
          </div>
          {
              loading
              ?
              (
                  <>
                      <div className="row d-flex justify-content-center gap-2">
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                      </div>
                  </>
              )
              :
              (
                  <>
                      <div className="courses-section row mt-5">
                          {
                              users
                              ?
                              (
                                usersList && usersList.length > 0 ? usersList.map(user => {
                                  return (
                                        <UserCard
                                            key={user.id}
                                            user_role = {user.roles[0].name}
                                            user_id={user.id}
                                            user_img={user.image_url}
                                            user_name={user.name}
                                            user_email={user.email}
                                            user_phone={user.phone}
                                            user_status={user.publish}
                                            loading={loading}
                                            api={`${instructors_URL_APi}/${user.id}`}
                                            allusers={instructors_URL_APi}
                                            userBaio={user.baio}
                                        />
                                    )
                                })
                                :
                                <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                                  <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                                  <p>{t("No users!")}</p>
                                </div>
                                
                              )
                              :
                              <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                                <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                                <p>{t("Faild load Users! ")}
                                  <span className="text-[#4d5fe3] cursor-pointer"
                                  onClick={()=>dispatch(fetchUsersFunc(instructors_URL_APi))}
                                  >
                                  {t("Try again")}
                                  </span>
                                </p>
                              </div>
                          }
                      </div>
                  </>
              )
          }
          <NewUserForm
              show={modalShow}
              onHide={() => setModalShow(false)} 
          />
          <OverLayLoading loading={deleteUserLoading} loadingText={"Deleting User..."}/>
        </>
    )
}
export default InstructorsPage