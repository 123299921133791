import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "react-bootstrap"
import { useContext, useEffect, useRef, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { Link, useParams } from "react-router-dom";
import { fetchCourseLessonsFunc } from "../../../store/custom-actions/lessons-actions/GetCourseLessons";
import PageTitle from "../../layouts/PageTitle";
import RowOfLesson from "./RowOfLesson";


export default function LessonsPage() {
    const {chapterId} = useParams();
    // const {loading , lessonsData} = useSelector(state => state.getAllCategoriesSlicer)
    const {deleteCategoriesLoading} = useSelector(state => state.deleteCategorySlicer)
    const {loadingCourseLessons , lessons:lessonsData} = useSelector(state => state.getCourseLessonsSlicer)
    const contextApi = useContext(ContextApi);
    const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
    const [lessonsList , setLessonsList] = useState([]);
    const lessonsApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}/lessons`;
    const allLessonsApi = `${contextApi.dashboard_URL_API}/lessons`;
    const dispatch = useDispatch();

    useEffect(() => {
        const getLessons = async () => {
          try {
            dispatch(fetchCourseLessonsFunc(lessonsApi))
          } catch (error) {
            console.log(error);
          }
        };
        getLessons();
      }, []);
      useEffect(() => {
        lessonsData && setLessonsList(lessonsData);
      }, [lessonsData]);
      useEffect(() => {
        lessonsData && setLessonsList(lessonsData);
        lessonsData && (jobData.current = lessonsData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [lessonsData]);
      const sort = 20;
      let paggination = Array(Math.ceil(lessonsList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        lessonsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = lessonsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      // const handleDeleteLesson = async (lessonId) => {
      //   const deleteFunc = () => {
      //     try {
      //       dispatch(deleteCategoryFunc(`${allLessonsApi}/${lessonId}`))
      //       .then((result)=>{
      //         if(result.payload.success === true){
      //           dispatch(fetchCourseLessonsFunc(lessonsApi));
      //         }
      //       });
      //     }catch (error) {
      //       console.log(error);
      //     }
      //   }
      //   swal({
      //     title: "Confirm Item Deletion",
      //     text: `Once deleted, this will not avilable in platform!`,
      //     icon: "warning",
      //     buttons: true,
      //     dangerMode: true,
      //   }).then((willDelete) => {
      //     if (willDelete) {
      //       deleteFunc()
      //     }
      //   })
      // };
      const [searchValue , setSearchValue] = useState("")
      const search = () => {
        if(searchValue !== "") {
          const searchResult = lessonsData &&
          lessonsData.length >= 1 && lessonsData.filter((lesson) =>  
            lesson.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
            // ||
            // cat.sku.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );
          jobData.current = searchResult
        }else {
          dispatch(fetchAllCategoriesFunc(categories_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(fetchCourseLessonsFunc(lessonsApi));
      },[searchValue])
      return (
        <>
        <PageTitle motherMenu={"Chapter"} activeMenu={"Lessons"} />
        {
            !loadingCourseLessons && lessonsList.length < 1
            &&
            checkPermissions(
              "create lessons",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={`/new-lesson/${chapterId}`} 
                className="btn btn-primary btn-sm"
                >
                    Create
                    <i className="fa-solid fa-plus ml-1"></i>
                </Link>
              </div>
            )
        }
        {
            loadingCourseLessons
            ?
            (
                <>
                    <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    </div>
                </>
            )
            :
            (
                lessonsData
                ?
                (
                    lessonsList && lessonsList.length >= 1
                    &&
                    <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Lessons</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                          {
                            checkPermissions(
                              "create lessons",
                              <Link 
                              to={`/new-lesson/${chapterId}`} 
                              className="btn btn-primary btn-sm"
                              >
                                Create
                                <i className="fa-solid fa-plus ml-1"></i>
                              </Link>
                            )
                          }
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Order</th>
                                  <th>View Count</th>
                                  <th>Status</th>
                                  {
                                    checkPermissions(
                                      ["update lessons" , "delete lessons"],
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  jobData.current.map((lesson , index) => {
                //                       return (
                //                           <tr key={index}>
                //                               <td>{lesson.id}</td>
                //                               <td>{lesson.name}</td>
                //                               <td>{lesson.type}</td>
                //                               <td>{lesson.order}</td>
                //                               <td>{lesson.view_count}</td>
                //                               <td>{lesson.publish}</td>
                //                               {
                //                                 checkPermissions(
                //                                   ["update lessons" , "delete lessons"],
                //                                   <td>
                //                                       {/* <Link
                //                                           to="#"
                //                                           className="btn btn-danger shadow btn-xs sharp"
                //                                           onClick={() => {
                //                                               handleDeleteLesson(lessons.id)
                //                                           }}
                //                                       >
                //                                           <i className="fa fa-trash"></i>
                //                                       </Link>
                //                                       <Link
                //                                           to="#"
                //                                           className="btn btn-danger shadow btn-xs sharp"
                //                                           onClick={() => {
                //                                               handleDeleteLesson(lessons.id)
                //                                           }}
                //                                       >
                //                                           <i className="fa-solid fa-gear"></i>
                //                                       </Link> */}
                //                                                       <DescionDrop
                //     lessonStatus = {lesson.status}
                //     lesson_order = {lesson.order}
                //     lessonId = {lesson.id}
                //     lesson_type = {lesson.type}
                //     quizzes_array = {lesson.quizzes}
                //     // deleteFunction = {() => handleDeleteLesson(lessons.id)}
                //     // showEditNameAndDescForm = {()=>setShowEditNameAndDescForm(true)}
                //     // showEditVideoForm = {()=>setShowEditVideoForm(true)}
                //     // showEditMatrialForm = {()=>setShowEditMatrialForm(true)}
                //     // showapprovelessonnotficationFunc = {()=>setShowApproveNotfication(true)}
                //     // unapprovelessonfunction = {unApproveLesson}
                //     // showLessonMatrial = {() => showMatrial()}
                //     // showEditOrderForm = {() => setShowEditOrderForm(true)}
                //     // showLinkQuizeForm = {() => setShowLinkQuizeForm(true)}
                //     // showShareFilesForm = {() => setShowShareMatrialForm(true)}
                //     // showPassLessonRequirmentsForm = {()=>setShowPassingRequireFrom(true)}
                //     // showUnlinkQuizForm = {()=>setShowUnlinkQuizForm(true)}
                //     // showRequirmentsList = {()=>setShowRequirmentsList(true)}
                //     // lesson_id = {lessonId}
                //     // handleDeleteChapter = {() => handleDeleteChapter()}
                // />
                //                                   </td>
                //                                 )
                //                               }
                //                           </tr>
                //                       )
                return (
                  <RowOfLesson lesson={lesson} key={index} />
                )
                                  }) 
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {lessonsList.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : lessonsList.length}{" "}
                                of {lessonsList.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                )
                :
                <h3 className="text-center">No Lessons!</h3>
            )
        }
        <OverLayLoading loading={deleteCategoriesLoading} loadingText={"Deleting Lesson..."}/>
        </>
    )
}
