import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import sessionImg from "../../../media/images/online-learning.png";
import {Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
function ShowActivationCodes(props) {
    const {t} = useTranslation();
    const {bulck_name , activation_codes} = props;
    const printRef = useRef();
    const handlePrint = useReactToPrint({
      content : () => printRef.current
    })
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          {bulck_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    {
        <>
            <div className='relative w-full flex justify-between items-center'>
                <div className='relative '>{t("Activation Codes")} : {activation_codes && activation_codes.length}</div>
                <Link
                    to={`#`} 
                    className="btn btn-primary btn-sm ml-2"
                    // onClick={() => setShowNewBuclkForm(true)}
                    onClick={() => {
                        handlePrint()
                    }}
                    >
                    {t("Print")}
                    <i className="fa-solid fa-print ml-1"></i>
                </Link>
            </div>
            <Table responsive ref={printRef}>
                <thead>
                    <tr>
                    <th className="width80">
                        <strong>#{t("ID")}</strong>
                    </th>
                    <th>
                        <strong>{t("Code")}</strong>
                    </th>
                    <th>
                        <strong>{t("Student")}</strong>
                    </th>
                    <th>
                        <strong>{t("Creation")}</strong>
                    </th>
                    <th>
                        <strong>{t("Last Update")}</strong>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {
                    activation_codes && activation_codes.length >= 1 ? activation_codes.map((code,index)=>{
                        const creationDate = new Date(code.created_at)
                        const creationYear = creationDate.getFullYear();
                        const creationMonth = creationDate.getMonth()+1;
                        const creationDay = creationDate.getDate();
                        const latestUpdateDate = new Date(code.updated_at)
                        const latestUpdateYear = latestUpdateDate.getFullYear();
                        const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                        const latestUpdateDay = creationDate.getDate();
                        return (
                        <tr key={index}>
                            <td>
                                <strong>#{code.id}</strong>
                            </td>
                            <td>{code.code}</td>
                            <td>
                                {
                                    code.student_id
                                    ?
                                    code.student_id
                                    :
                                    <span className='bg-green-500 text-white  p-2 rounded-md print:bg-slate-700'>Not owned</span>
                                }
                            </td>
                            <td>
                            {`${creationYear} / ${creationMonth} / ${creationDay}`}
                            </td>
                            <td className="">
                            {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                            </td>
                        </tr>
                        )
                    })
                    :
                    <td colSpan={3}>{t("No Codes")}</td>
                
                }
                </tbody>
            </Table>
        </>
    }

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
            props.onHide();
        }}>{t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowActivationCodes