import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ContextApi from "../../../../store/ContextApi";
import {ShowLessonFunc} from "../../../../store/custom-actions/lessons-actions/ShowLessonSlicer"
import { Spinner, Table } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { Link } from "react-router-dom";
import correctIcon from "../../../../media/images/checklist.png";
import noAttemptsImg from "../../../../media/images/planet.png";
import questionIcon from "../../../../media/images/question.png";
import studentImg from "../../../../media/images/graduated.png";
import videoIcon from "../../../../media/images/video-camera.png";
import quizIcon from "../../../../media/images/quiz.png";
import fileIcon from "../../../../media/images/google-docs.png";
import pdfIcon from "../../../../media/images/file.png";
import rich_textIcon from "../../../../media/images/files.png";
import { getAllCourseAttemptsFunc } from "../../../../store/custom-actions/attempts-actions/GetAllCourseAttemptsSlicer";
import { useTranslation } from "react-i18next";
function AdminAllAttemptsPage() {
    const {t} = useTranslation();
    const params = useParams();
    const courseId = params.courseId;
    const dispatch = useDispatch();
    const {loading , data} = useSelector(state => state.getAllCourseAttemptsSLicer);
    const contextApi = useContext(ContextApi)
    const quizzesApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/quizzes`;
    const [courseAttempts , setCourseAttempts] = useState([]);
    const getCourseAttempts = () => {
        dispatch(getAllCourseAttemptsFunc(quizzesApi))
        .then(result => console.log(result))
    }
    useEffect(() => {
        getCourseAttempts();
    },[])
    useEffect(() => {
        data && setCourseAttempts(data);
    },[data])
    const checkLessonType = (lessonType) => {
        if(lessonType) {
            switch(lessonType) {
                case "video" :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "quiz" :
                return <img src={quizIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "file" :
                return <img src={fileIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "pdf" :
                return <img src={pdfIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "rich_text" :
                return <img src={rich_textIcon} alt="icon" className="relative mr-1 w-[20px]" />
                default :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />
            }
        }
    }

    return (
        <>
        
        {
            loading
            ?
            (
                <>
                    <div className="row d-flex justify-content-center gap-2">
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                    </div>
                </>
            )
            :
            <div className="attempt-setion flex flex-col mt-3">
            <PageTitle activeMenu="All Attempts" motherMenu={"Course"} />
        {
            data && courseAttempts && courseAttempts.length > 0 ?
            <div className="all-attempts-content">
                <Table responsive className="">
                    <thead>
                    <tr>
                        <th className="width80">
                            <strong className="">#{t("ID")}</strong>
                        </th>
                        <th>
                            <strong className="">{t("Student")}</strong>
                        </th>
                        <th>
                            <strong className="">{t("Attempt Lesson")}</strong>
                        </th>
                        <th>
                            <strong className="">{t("Creation Date")}</strong>
                        </th>
                        <th>
                            <strong className="">{t("Status")}</strong>
                        </th>
                        <th>
                            <strong className="">{t("Score")}</strong>
                        </th>
                        <th>
                            <strong className="">{t("Show")}</strong>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {data && courseAttempts.map((attemp,index)=>{
                            const date = new Date(attemp.created_at)
                            const year = date.getFullYear();
                            const month = date.getMonth()+1;
                            const day = date.getDate();
                            return (
                            <tr key={index}>
                                <td>
                                <   strong className="">#{attemp.id}</strong>
                                </td>
                                <td className="flex items-center">
                                    <Link className="relative flex items-center" to={`student-profile/${attemp.student_id}`}>
                                        <img 
                                        src={attemp.student && attemp.student.image_url !== null ? attemp.student.image_url : studentImg} 
                                        alt="student img"
                                        className="relative w-[40px] mr-1"
                                        />
                                        {attemp.student.name}
                                    </Link>
                                </td>
                                <td>
                                    <h5 className="relative flex items-center m-0">
                                        {
                                        attemp.lesson 
                                        && attemp.lesson.type 
                                        && attemp.lesson.type
                                        && checkLessonType(attemp.lesson.type)
                                        }
                                        {attemp.lesson && attemp.lesson.name && attemp.lesson.name}
                                    </h5>
                                </td>
                                <td>{year} / {month} / {day}</td>
                                <td>
                                    {
                                        attemp.is_visited === 1
                                        ?
                                        <img src={correctIcon} alt="attempt status img" className="w-[17px]"/>
                                        :
                                        <img src={questionIcon} alt="attempt status img" className="w-[17px]" />
                                    }
                                </td>
                                {/* <td>{`${(attemp.score === 0 || attemp.score >= 1) ? attemp.score : "No Score"}`}</td> */}
                                <td className="">
                                    {
                                        attemp.is_visited === 1
                                        ?
                                        <p className="m-0">{t("Corrected")}</p>
                                        :
                                        <Link
                                            to={`/attemp-page/${attemp.id}`}
                                            className="hover:text-black transition-all duration-300 text-[16px]"
                                        >
                                            <i className="fa-regular fa-eye"></i>
                                        </Link>
                                    }
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            :
            <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                <img
                src={noAttemptsImg}
                alt="faild img"
                className="w-[150px] mb-3 opacity-80"
                />
                <p>
                    {t("No Attempts Yet!")}
                </p>
            </div>
        }
        </div>
        }
        </>
    )
}

export default AdminAllAttemptsPage