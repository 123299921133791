import PageTitle from "../../layouts/PageTitle";
import { useContext, useEffect, useState } from "react";
import "./ui-settings-page.css"
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { Spinner } from "react-bootstrap";
import { getSlidersFunc } from "../../../store/custom-actions/ui-settings-actions/GetUiSlidersDataSlicer";
import swal from "sweetalert";
import  { deleteSliderFunc } from "../../../store/custom-actions/ui-settings-actions/DeleteSliderSlicer";
import noSlidersImg from "../../../media/images/planet.png"
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import UpdateSliderForm from "./UpdateSliderForm";
import ShowImageModal from "../../global-compnents/show-image-modal/ShowImageModal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AllSlidersPage() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const ui_settings_URL_APi = `${contextApi.dashboard_URL_API}/settings/slider-images`;
    const dispatch = useDispatch();
    const [slidersList , setSlidersList] = useState([]);
    const [showImage , setShowImage] = useState();
    const [image , setImage] = useState();
    const [showUpdateSliderForm , setShowUpdateSliderForm] = useState();
    const [oldSliderData , setOldSliderData] = useState();
    const {loading:getSlidersLoading , data:slidersData} = useSelector(
        state => state.getSlidersSliders
    );
    const {loading:deleteSliderLoading} = useSelector(
        state => state.deleteSliderSlicer
    );
    const getSliders = async () => {
        dispatch(getSlidersFunc(ui_settings_URL_APi))
    }
    useEffect(() => {
        getSliders();
    },[])
    useEffect(() => {
        slidersData && setSlidersList(slidersData)
    },[slidersData])

    const deleteSliderFunction = async (sliderId) => {
        swal({
          title: t("Confirm Slider Deletion"),
          text: t(`Once deleted, Slider will not be avilable in user intrface!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteSliderFunc(`${ui_settings_URL_APi}/${sliderId}`))
            .then((result) => {
              if(result.payload.success === true){
                getSliders();
              }
            })
          }
        });
      };
    return (
        <>
            <PageTitle motherMenu={"Home"} activeMenu={"Ui Sliders"} customClasses={"my-0"}/>
            <div className="new-slider-container relative flex items-center justify-end p-2 my-3">
                <Link to="/ui-settings" className="btn btn-primary">New Slider</Link>
            </div>
            <div className="table-responsive">
                <div
                id="student_wrapper"
                className="dataTables_wrapper no-footer"
                >
                <table
                    className="table display mb-4 dataTablesCard order-table card-table text-black application "
                    id="application-tbl1_next"
                >
                    <thead>
                    <tr>
                        <th className="">{t("ID")}</th>
                        <th className="">{t("Title")}</th>
                        <th className="">{t("Description")}</th>
                        <th className="">{t("Image")}</th>
                        <th className="">{t("Mobile Image")}</th>
                        <th className="">{t("Creation")}</th>
                        <th className="">{t("Last Update")}</th>
                        <th className=" text-end">
                        {t("Decision")}
                        </th>
                    </tr>
                    </thead>
                    {
                    getSlidersLoading 
                    ?
                    <>
                    <div className="row d-flex justify-content-center gap-2 my-4 pl-4">
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                    </div>
                    </>
                    :
                    <tbody>
                        {slidersData &&
                        slidersList.length > 0 ?
                        slidersList.map((slider, index) => {
                            const creationDate = new Date(slider.created_at)
                            const creationYear = creationDate.getFullYear();
                            const creationMonth = creationDate.getMonth()+1;
                            const creationDay = creationDate.getDate();
                            const latestUpdateDate = new Date(slider.updated_at)
                            const latestUpdateYear = latestUpdateDate.getFullYear();
                            const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                            const latestUpdateDay = creationDate.getDate();
                            return (
                            <tr key={index}>
                                <td className="font-bold">
                                #{slider.id}
                                </td>
                                <td className="">
                                    {slider.title}
                                </td>
                                <td>
                                    <div className="m-0" dangerouslySetInnerHTML={{__html : slider.description}} />
                                </td>
                                <td className="">
                                    <img src={slider.image_url} className="relative w-[50px] h-[30px] cursor-pointer rounded-md hover:scale-105 transition-all duration-500" alt="img" onClick={() => {
                                        setShowImage(true)
                                        setImage(slider.image_url)
                                    }}/>
                                </td>
                                <td className="">
                                    <img src={slider.mobile_image_url} className="relative w-[50px] h-[30px] cursor-pointer rounded-md hover:scale-105 transition-all duration-500" alt="img" onClick={() => {
                                        setShowImage(true)
                                        setImage(slider.mobile_image_url)
                                    }}/>
                                </td>
                                <td>
                                {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                </td>
                                <td className="">
                                {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                </td>
                                <td className="">
                                <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                    onClick={() => {
                                        deleteSliderFunction(slider.id)
                                    }}
                                >
                                    <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                    >
                                        <i className="fa-solid fa-trash text-white"></i>
                                        <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                        >{t("Delete")}</span>
                                    </div>
                                </button>
                                <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                    onClick={() => {
                                        setOldSliderData(slider);
                                        setShowUpdateSliderForm(true)
                                    }}
                                >
                                    <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                    >
                                        <i className="fa-solid fa-pen-to-square text-white"></i>
                                    </div>
                                </button>
                            </td>
                            </tr>
                        );
                        })
                        :
                        <td colSpan={8} className="flex flex-col justify-center items-center">
                                <img src={noSlidersImg} alt="no subscription" className="w-[50px] border-none"/>
                                <p className="m-0 border-none">{t("No Sliders!")}</p> 
                        </td>
                        }
                    </tbody>
                    }
                </table>
                </div>
            </div>
            <UpdateSliderForm
                show = {showUpdateSliderForm}
                onHide = {() => setShowUpdateSliderForm(false)}
                old_slider_data = {oldSliderData}
                refetch_data = {() => getSliders()}
            />
            <ShowImageModal
                show = {showImage}
                onHide = {() => setShowImage(false)}
                image = {image}
            />
            <OverLayLoading loading={deleteSliderLoading} loadingText={"Delete Slider.."} />
        </>
    )
}


export default AllSlidersPage;