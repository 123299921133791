import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getSingleCategoryFunc } from "../../../../store/custom-actions/categories-actions/GetSingleCategorySlicer";
import CategoryCard from "./CategoryCard";
import ContextApi from "../../../../store/ContextApi";
import PageTitle from "../../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

export default function SingleCategoryPage() {
    const {t} = useTranslation();
    const {categoryId} = useParams();
    const contextApi = useContext(ContextApi);
    const dispatch = useDispatch();
    const {loading:getCategoryLoading , data:categoryData} = useSelector(
        state => state.getSingleCategorySlicer
    )
    const [categoryChildren , setCategoryChildren] = useState([]);
    useEffect(() => {
        const categoryApi = `${contextApi.dashboard_URL_API}/categories/${categoryId}`;
        const getData = () => {
            dispatch(getSingleCategoryFunc(categoryApi))
        }
        getData();
    },[categoryId])
    useEffect(() => {
        if(categoryData) {
            // setChapter(categoryData)
            console.log(categoryData)
            setCategoryChildren(categoryData.all_children)
            // setChapterLessns(categoryData.lessons)
        }
    },[categoryData])
    return (
        <>
            <PageTitle motherMenu={"Categories"} activeMenu={"Category Children"} />
            {/* {
                !getCategoryLoading
                &&
                <div className="relative flex justify-end mb-1">
                    <Link
                        to={`/new-chapter/${categoryId}`}
                        className="btn btn-primary btn-sm"
                    >
                        Create
                        <i className="fa-solid fa-plus ml-1"></i>
                    </Link>
                </div>
            } */}
            {
                getCategoryLoading
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                </div>
                :
                (
                    categoryData
                    ?
                    <>
                        <div className="courses-section h-fit relative row">
                            {
                                categoryChildren && categoryChildren.length >= 1
                                ?
                                (
                                    categoryChildren.map((child , index) => {
                                        const {id , all_children , name} = child;
                                        // const {name:courseName} = course;
                                        return (
                                            <CategoryCard
                                            id={id}
                                            name={name}
                                            key={index}
                                            // chapterOrder={order}
                                            // courseName={"courseName"}
                                            // lessonsCount={lessons.length}
                                            childrenCount={all_children.length}
                                            refetchData = {() => dispatch(getSingleCategoryFunc(`${contextApi.dashboard_URL_API}/categories/${categoryId}`))}
                                            />
                                        )
                                    })
                                )
                                :
                                <h3 className="text-center my-3 w-full">{t("No Children For This Item!")}</h3>
                            }
                        </div>
                        {/* <ChapterLessonsTable
                            lessonsData={categoryData.lessons}
                            categoryId={categoryId}
                            refetchSingleChapter = {() => dispatch(getSingleChapterFunc(`${contextApi.dashboard_URL_API}/chapters/${categoryId}`))}
                        /> */}
                    </>
                    :
                    <h3 className="text-center my-3 w-full">{t("No Data!")}</h3>
                )
            }
        </>
    )
}