function ActiveBox({image , count , title , secondStyle , customStyles}) {
    return (
        <>
            <div className={`active-box col-sm-12 col-md-3 md:w-fit mt-4 md:mt-0 md:mx-[20px] flex`}>
              <div className={`icon-parent relative ${customStyles} p-1 rounded-md flex justify-center items-center roundedmd hover:translate-y-[-5px] transition-all duration-500 shadow-md shadow-slate-400`}>
                <div className={`p-2 rounded-md ${secondStyle}`}>
                    <img src={image} alt="active img" className="w-[30px]"/>
                </div>
              </div>
              <div className="relative flex flex-col mx-2 ">
                <span>{title} <i className="fa-solid fa-stopwatch"></i></span>
                <span className="mt-2 font-bold">{count}</span>
              </div>
            </div>
        
        </>
    )
}

export default ActiveBox;