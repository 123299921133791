import { useEffect, useState } from "react";
import lessonIcon from "../../../../media/images/online-learning.png";
import { useTranslation } from "react-i18next";
function CheckBox({ name , pushingLessonsFunction , lesson_id }) {
    const {t} = useTranslation();
    const [startDate , setSatartDate] = useState("");
    const [endDate , setEndDate] = useState("");
    const [lessonObject , setLessonObject] = useState();
    const [checkedLesson , setCheckedLesson] = useState();
    useEffect(() => {
        if (startDate && endDate) {
            pushingLessonsFunction(lesson_id, lessonObject)
        } else if (!startDate && !endDate && lessonObject) {
            pushingLessonsFunction(lesson_id, null);
        }
    }, [startDate, endDate, lessonObject, lesson_id , checkedLesson]);

    return (
        <div className="row flex items-center col-sm-12 mb-2 justify-between">
            <div className="lesson-title flex items-center col-sm-12 col-md-5">
                <input 
                    onChange={(e)=>{
                        if(e.target.checked) {
                            setCheckedLesson(true)
                        }else {
                            setCheckedLesson(false)
                            setSatartDate("");
                            setEndDate("");
                        }
                    }}
                    type='checkbox' 
                    className={`cursor-pointer m-0 translate-y-[-2px] scale-110`} 
                    id={lesson_id}/>
                    <label htmlFor={lesson_id} className={`relative flex items-center ml-2 cursor-pointer`}>
                        <img src={lessonIcon} className="mr-1 w-[20px] relative" alt="lesson icon" />
                        <h4 className="m-0 text-slate-700">{name}</h4>
                    </label>
            </div>
            <div className="relative flex items-center justify-between col-sm-12 col-md-7">
                <div className="form-group mb-3 col-sm-12 col-md-5">
                    <label className="relative flex items-center">
                        {t("From")} :
                    </label>
                    <input
                        type="date"
                        className={`form-control ${!checkedLesson ? "pointer-events-none opacity-50" : ""}`}
                        onChange={(e) => {
                            setSatartDate(e.target.value)
                            setLessonObject({start_date : e.target.value , endDate});
                        }}
                        value={checkedLesson ? startDate : ""}
                />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-5">
                    <label className="relative flex items-center">
                        {t("To")} :
                    </label>
                    <input
                        type="date"
                        className={`form-control ${!checkedLesson ? "pointer-events-none opacity-50" : ""}`}
                        onChange={(e) => {
                            setEndDate(e.target.value)
                            setLessonObject({start_date:startDate , end_date : e.target.value});
                        }}
                        value={checkedLesson ? endDate : ""}
                />
                </div>
            </div>
        </div>
    )
}

export default CheckBox;