import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import ContextApi from "../../../../store/ContextApi";
import { getSingleExcutionFunc } from "../../../../store/custom-actions/courses-actions/excutions-actions/GetSingleExcution";
import PageTitle from "../../../layouts/PageTitle";
import { Card, Col, Spinner, Tab, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import courseIcon from "../.././../../media/images/course-img.png";
import excutionIcon from "../.././../../media/images/gear.png";
import creationIcon from "../.././../../media/images/write.png";
import updateIcon from "../.././../../media/images/update.png";
import priceIcon from "../.././../../media/images/tag.png";
import calenderIcon from "../.././../../media/images/calendar.png";
import discountIcon from "../.././../../media/images/discounts.png";
import studentIcon from "../.././../../media/images/graduated.png";
import sortIcon from "../.././../../media/images/sort.png";
import visiblityIcon from "../.././../../media/images/high-visibility.png";
import anyoneIcon from "../.././../../media/images/multiple-users-silhouette.png";
import faildImg from "../.././../../media/images/planet.png";
import { useTranslation } from "react-i18next";

function SingleExcutionPage() {
    const {t} = useTranslation();
    const params = useParams();
    const excutionId = params.excutionId;
    const contextApi = useContext(ContextApi);
    const dispatch = useDispatch()
    const [singleExcution , setSingleExcution] = useState({});
    const [excutionStudents , setExcutionStudents] = useState([]);
    const [creationDate , setCreationDate] = useState();
    const [lastUpdateDate , setLastUpdateDate] = useState([]);
    const singleExcutionApi = `${contextApi.dashboard_URL_API}/course-executions/${excutionId}`;
    const {loading , data} = useSelector(
      (state) => state.getSingleExcutionSlicer
    );
    useEffect(() => {
        const getSingleExcution = async () => {
            dispatch(getSingleExcutionFunc(singleExcutionApi))
        }
        getSingleExcution();
    },[])
    useEffect(() => {
        data && setSingleExcution(data.courseExecution);
        data && data.students && setExcutionStudents(data.students);
        const creation = data && singleExcution.created_at && new Date(singleExcution.created_at);
        const creationDay = creation && creation.getUTCDate();
        const creationMonth = creation && creation.getUTCMonth();
        const creationYear = creation && creation.getUTCFullYear();
        setCreationDate(`${creationYear} / ${creationMonth} / ${creationDay}`);
        const lastUpdate = data && singleExcution.updated_at && new Date(singleExcution.updated_at);
        const updateDay = lastUpdate &&  lastUpdate.getUTCDate();
        const updateMonth = lastUpdate &&  lastUpdate.getUTCMonth();
        const updateYear = lastUpdate &&  lastUpdate.getUTCFullYear();
        setLastUpdateDate(`${updateYear} / ${updateMonth} / ${updateDay}`);
    },[data , singleExcution])
    return (
        <>
            <PageTitle motherMenu={"Excutions"} activeMenu={"Show Excution"}/>
            {
                loading
                ?
                <>
                    <div className="row d-flex justify-content-center gap-2">
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                    </div>
                </>
                :
                (
                    data && singleExcution
                    ?
                    <>
                        <div className="excution-content relative">
                            <div className="content row">
                                <div className="col-sm-12 col-md-8 flex flex-col h-fit flex-wrap gap-3 my-3 py-3">
                                    <div className="relative flex">
                                        <div className="box mr-2 relative flex items-center rounded-md bg-white shadow-md shadow-slate-300 h-fit p-3 w-fit">
                                            <img src={courseIcon} alt="course icon" className="mr-1 w-[20px]" />
                                            <h5 className="m-0 text-slate-600 text-sm">{t("Course")} : {singleExcution.course && singleExcution.course.name && singleExcution.course.name}</h5>
                                        </div>
                                        <div className="box mr-2 relative flex items-center rounded-md bg-white shadow-md shadow-slate-300 h-fit p-3 w-fit">
                                            <img src={excutionIcon} alt="course icon" className="mr-1 w-[20px]" />
                                            <h5 className="m-0 text-slate-600 text-sm">{t("Title")} : {singleExcution.title}</h5>
                                        </div>
                                        <div className="relative flex items-start rounded-md bg-white shadow-md shadow-slate-300 h-fit p-3 w-fit">
                                            <img src={studentIcon} alt="icon" className="relative mr-1 w-[20px]" />
                                            <h5 className="m-0 text-slate-600 text-sm">{t("Students Count")} : {excutionStudents.length}</h5>
                                        </div>
                                    </div>
                                    <div className="relative flex">
                                        <div className="relative flex flex-col items-start rounded-md bg-white shadow-md shadow-slate-300 h-fit p-3 w-fit justify-start mr-2">
                                            <div className="relative flex items-center mb-1">
                                                <img src={priceIcon} alt="icon" className="relative mr-1 w-[20px]" />
                                                <h5 className="m-0 text-slate-600 text-sm">{t("Price")} : {singleExcution.price}&pound;</h5>
                                            </div>
                                            {
                                                singleExcution.discount
                                                &&
                                                <div className="relative flex items-center mb-1">
                                                    <img src={discountIcon} alt="icon" className="relative mr-1 w-[20px]" />
                                                    <h5 className="m-0 text-slate-600 text-sm">{t("Discount")} : {singleExcution.discount}</h5>
                                                </div>
                                            }
                                        </div>
                                        <div className="relative flex flex-col items-start rounded-md bg-white shadow-md shadow-slate-300 h-fit p-3 w-fit justify-start mr-2">
                                            <div className="relative flex items-center mb-1">
                                                <img src={creationIcon} alt="icon" className="relative mr-1 w-[20px]" />
                                                <h5 className="m-0 text-slate-600 text-sm">{t("Creation Date")} : {creationDate}</h5>
                                            </div>
                                            <div className="relative flex items-center mb-1">
                                                <img src={updateIcon} alt="icon" className="relative mr-1 w-[20px]" />
                                                <h5 className="m-0 text-slate-600 text-sm">{t("Last Update")} : {lastUpdateDate}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col">
                                        <div className="relative flex items-center">
                                            <img src={sortIcon} alt="icon" className="relative mr-1 w-[20px]" />
                                            <h5 className="m-0 text-slate-600 text-sm">{t("Order")} : {singleExcution.order}</h5>
                                        </div>
                                        <div className="relative flex items-center mt-2">
                                            <img src={visiblityIcon} alt="icon" className="relative mr-1 w-[20px]" />
                                            <h5 className="m-0 text-slate-600 text-sm">{t("Visibilty")} : 
                                            {
                                                singleExcution.is_visible === 1
                                                ?
                                                <span className="p-2 text-sm ml-2 rounded-md text-white bg-green-500">{t("Visible")}</span>
                                                :
                                                <span className="p-2 text-sm ml-2 rounded-md text-white bg-red-500">{t("Not Visible")}</span>
                                            }
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative flex flex-col col-sm-12 col-md-4 py-3">
                                    <div className="relative flex flex-col">
                                        <div className="relative flex items-start justify-start">
                                            <img src={studentIcon} className="relative mr-1 w-[20px]" alt="calender icon" />
                                            <p>{t("Date Of Publish Course For Only Platform Students")}</p>
                                        </div>
                                        <div className="students-pulish-date rounded-md bg-white shadow-md shadow-slate-300 h-fit px-2 py-3 w-fit mb-2 col-sm-12">
                                            <div className="start-date relative flex items-center">
                                                <img src={calenderIcon} className="relative mr-1 w-[20px]" alt="calender icon" />
                                                <h5 className="m-0 text-slate-600 text-sm">{t("From")} : {singleExcution.start_date_student}</h5>
                                            </div>
                                            <div className="start-date relative flex items-center mt-2">
                                                <img src={calenderIcon} className="relative mr-1 w-[20px]" alt="calender icon" />
                                                <h5 className="m-0 text-slate-600 text-sm">{t("To")} : {singleExcution.end_date_student}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col mt-3">
                                        <div className="relative flex items-start justify-start">
                                            <img src={anyoneIcon} className="relative mr-1 w-[20px]" alt="calender icon" />
                                            <p>{t("Date Of Publish Course For anyone")}</p>
                                        </div>
                                        <div className="anyone-pulish-date rounded-md bg-white shadow-md shadow-slate-300 h-fit px-2 py-3 w-fit col-sm-12">
                                            <div className="start-date relative flex items-center">
                                                <img src={calenderIcon} className="relative mr-1 w-[20px]" alt="calender icon" />
                                                <h5 className="m-0 text-slate-600 text-sm">{t("From")} : {singleExcution.start_date_publish}</h5>
                                            </div>
                                            <div className="start-date relative flex items-center mt-2">
                                                <img src={calenderIcon} className="relative mr-1 w-[20px]" alt="calender icon" />
                                                <h5 className="m-0 text-slate-600 text-sm">{t("To")} : {singleExcution.end_date_publish}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                            <Col xl={12}>
                            <Card>
                            <Card.Header>
                                <Card.Title className="text-slate-600 flex items-center">
                                    <img src={studentIcon} alt="course icon" className="mr-1 w-[30px]" />
                                    {t("Excution Studnts Table")}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {/* <!-- Nav tabs --> */}
                                <div className="default-tab">
                                <Tab.Container defaultActiveKey={"online"}>
                                    <Tab.Content className="">
                                    <Tab.Pane eventKey="online">
                                        {
                                        loading 
                                        ?
                                        <>
                                            <div className="row d-flex justify-content-center gap-2">
                                                <Spinner animation="grow" variant="warning" />
                                                <Spinner animation="grow" variant="warning" />
                                                <Spinner animation="grow" variant="warning" />
                                                <Spinner animation="grow" variant="warning" />
                                            </div>
                                        </>
                                        :
                                        
                                            data && excutionStudents && excutionStudents.length > 0 
                                            ?
                                            <>
                                            <Table responsive>
                                                <thead>
                                                <tr>
                                                    <th className="width80">
                                                    <strong>#{t("ID")}</strong>
                                                    </th>
                                                    <th>
                                                    <strong>{t("Name")}</strong>
                                                    </th>
                                                    <th>
                                                    <strong>{t("Email")}</strong>
                                                    </th>
                                                    <th>
                                                    <strong>{t("Info")}</strong>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data
                                                &&
                                                excutionStudents
                                                &&
                                                excutionStudents.length > 0 &&
                                                excutionStudents.map((student,index)=>{
                                                return (
                                                <tr key={index}>
                                                    <td>
                                                    <strong>{student.id }</strong>
                                                    </td>
                                                    <td>{student.name }</td>
                                                    <td>{student.email }</td>
                                                    <td>
                                                    <Link
                                                        to={`student-profile/${student.id}`}
                                                        className="hover:text-black transition-all duration-300 text-[16px]"
                                                    >
                                                        <i className="fa-solid fa-circle-info"></i>
                                                    </Link>
                                                    </td>
                                                </tr>
                                                )
                                                })}
                                                </tbody>
                                            </Table>
                                            </>
                                            :
                                            <div className="relative flex items-center">
                                                <i className="fa-solid fa-triangle-exclamation text-red-500 mr-1"></i>
                                                {t("No Student For This Excution!")}
                                            </div>
                                        
                                        }
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="ofline">
                                    </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                                </div>
                            </Card.Body>
                            </Card>
                            </Col>
                            </div>
                        </div>
                    </>
                    :
                    <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                        <img
                        src={faildImg}
                        alt="faild img"
                        className="w-[150px] mb-3"
                        />
                        <p>
                            {t("No Data For This Excution!")}
                        </p>
                    </div>
                )
            }
        </>
    )
}

export default SingleExcutionPage