import { Spinner } from "react-bootstrap";
import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ContextApi from "../../../../store/ContextApi";
import faildImg from "../../../../media/images/planet.png";
import "./lesson-schuadles-form.css";
import { useLocation, useParams } from "react-router-dom";
import { getSingleLessonSchuadleFunc } from "../../../../store/custom-actions/lessons-actions/lessons-schuadles-actions/GetSingleLessonSchuadleSlicer";
import CheckboxForUpdateSchuadle from "./CheckboxForUpdateSchuadle";
import { editLessonSchuadleFunc } from "../../../../store/custom-actions/lessons-actions/lessons-schuadles-actions/EditLessonSchuadleSlicer";
import PageTitle from "../../../layouts/PageTitle";
import { getSingleChapterFunc } from "../../../../store/custom-actions/chapters/GetSingleChapterSlicer";
import { useTranslation } from "react-i18next";
function UpdateLessonsSchualdesPage() {
    const {t} = useTranslation();
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();
    const params = useParams();
    const schuadleId = params.schuadleId;
    const {loading:getSingleSchuadleLoading , data} = useSelector(state => state.getSingleLessonSchuadleSlicer);
    const dispatch = useDispatch();
    const location = useLocation();
    const contextApi = useContext(ContextApi);
    const [excutionId , setExcutionId] = useState();
    const [courseId , setCourseId] = useState(null);
    const [chapterId , setChapterId] = useState();
    // const 
    const singleSchuadleApi = `${contextApi.dashboard_URL_API}/lesson-schadules/${schuadleId}`;
    // const singleSchuadleApi = `${contextApi.dashboard_URL_API}/chapters/{chapter_id}/lessons`;
    const [lessonsWillSend , setLessonsWillSend] = useState({});
    const [courseLessonsList , setCourseLessonsList] = useState([]);
    const [chapterAllLessons , setChapterAllLessons] = useState([]);
    const [title , setTitle] = useState("");
    const {loadingCourseLessons , lessons} = useSelector(
        (state) => state.getCourseLessonsSlicer
    );
    const {loading:updateSchuadleLoading} = useSelector(
        (state) => state.editLessonSchuadleSlicer
    );
    const {loading:getChapterLoading , data:chapterData} = useSelector(
        state => state.getSingleChapterSlicer
    )
    const [schuadleLessonsList , setSchuadleLessonsList] = useState([]);
    useEffect(() => {
        dispatch(getSingleLessonSchuadleFunc(singleSchuadleApi))
    },[])
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const chapterId = searchParams.get("chapter_id")
        chapterId && setChapterId(chapterId);
        const chapterApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}`;
        dispatch(getSingleChapterFunc(chapterApi));
    },[location])
    useEffect(() => {
        data && data.title && setTitle(data.title)
        data && data.lessons && setSchuadleLessonsList(data.lessons);
        data && data.course_execution &&  setCourseId(data.course_execution.course_id)
        data?.lessons && setCourseLessonsList(data.lessons)
    },[data])
    useEffect(() => {
        if(chapterData) {
            setChapterAllLessons(chapterData.lessons)
        }
    },[chapterData])
    const handleUpdateSchuadle = (data) => {
        dispatch(editLessonSchuadleFunc({api : singleSchuadleApi , schuadleData : {...data , title : title , lessons : lessonsWillSend}}))
        .then(result => {
            if(result.payload.success === true) {
                setTimeout(() => window.location.reload() , 500);
                reset();
            }
        })
    }
    const pushLessonsFunction = (lessonId , object) => {
        if(lessonId && object) {
            console.log({lessonId : object})
            setLessonsWillSend(prevObjects => Object.assign({} , prevObjects , {[lessonId] : object}))
        }else {
            setLessonsWillSend((prevObjects) => {
                const newObj = { ...prevObjects };
                delete newObj[lessonId];
                return newObj;
            });
        }
    }
    return (
        <>
        <PageTitle motherMenu={"Schuadles"} activeMenu={"Update Schuadle"} customClasses={"mb-0"}/>
            <div className="form-content relative">
                <div className="basic-form">
                    <form 
                        className="course-extintions-form" 
                        id="schaudle-form"
                        onSubmit={handleSubmit((data) => {
                            handleUpdateSchuadle(data)
                        })
                        }
                    >
                    <div className="rowrelative flex items-center justify-between my-3">
                        <div className="relative flex items-center">
                            <button class="rounded-lg relative w-36 h-10 cursor-pointer flex items-center border border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500 overflow-hidden"
                                onClick={() => window.history.back()}
                            >
                                <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">Back</span>
                                <span class="absolute right-0 h-full w-10 rounded-lg bg-blue-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                                    <i className="fa-solid fa-arrow-left text-white"></i>
                                </span>
                            </button>
                            <button 
                                type="submit"
                                form="schaudle-form"
                                class={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                                ${updateSchuadleLoading ? "pointer-events-none opacity-80" : ""}
                                `}
                                >
                                    {
                                        updateSchuadleLoading
                                        ?
                                        <>{t("loading")}...</>
                                        :
                                        <>
                                            {t("Submit")} 
                                            <svg
                                                class="w-5 h-5"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                                                stroke-linejoin="round"
                                                stroke-linecap="round"
                                                ></path>
                                            </svg>
                                        </>
                                    }
                            </button>
                        </div>
                    </div>
                    <div className="card p-3">
                        <div className="row relative">
                            <div className="form-group mb-3 col-sm-12">
                                <label className="relative flex items-center">
                                    {t("Title")}
                                </label>
                                {errors.title?.message && (
                                    <p className="text-red-600">{errors.title?.message}</p>
                                )}
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                            />
                            </div>
                        </div>
                        <div className="schuadles-lessons-content flex items-start row h-[300px] overflow-y-auto">
                            {
                                getSingleSchuadleLoading
                                ?
                                <div className="row flex justify-center w-full gap-2 my-4 pl-4">
                                    <Spinner animation="grow" variant="warning" />
                                    <Spinner animation="grow" variant="warning" />
                                    <Spinner animation="grow" variant="warning" />
                                    <Spinner animation="grow" variant="warning" />
                                </div>
                                :
                                (
                                // courseLessonsList &&  courseLessonsList.length >= 1
                                // ?
                                // courseLessonsList.map((lesson , index) => {
                                //     return (
                                //         <CheckboxForUpdateSchuadle 
                                //             key={index}
                                //             lesson_id = {lesson.lesson_id}
                                //             name={lesson.name} 
                                //             pushingLessonsFunction={pushLessonsFunction}
                                //             courseLessons = {courseLessonsList}
                                //             lesson = {lesson}
                                //             lessonsWillSend = {lessonsWillSend}
                                //             schuadleLessonsList = {schuadleLessonsList}
                                //         />
                                //     )
                                // })
                                chapterAllLessons &&  chapterAllLessons.length >= 1
                                ?
                                chapterAllLessons.map((lesson , index) => {
                                    return (
                                        <CheckboxForUpdateSchuadle 
                                            key={index}
                                            lesson_id = {lesson.id}
                                            name={lesson.name} 
                                            pushingLessonsFunction={pushLessonsFunction}
                                            courseLessons = {courseLessonsList}
                                            lesson = {lesson}
                                            lessonsWillSend = {lessonsWillSend}
                                            schuadleLessonsList = {schuadleLessonsList}
                                        />
                                    )
                                })
                                :
                                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                                <img
                                src={faildImg}
                                alt="faild img"
                                className="w-[150px] mb-3"
                                />
                                <p>{t("There Is No Lessons!")}</p>
                                </div>
                                )
                            }
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateLessonsSchualdesPage