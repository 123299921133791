import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import {jwtDecode} from "jwt-decode";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
export const loginFunc = createAsyncThunk(
    "login",
    async ({api , userData}) => {
        // axios.defaults.headers.common["Content-Type"] = "application/json";
        // axios.defaults.headers.common["Accept"] = "application/json";
        try {
            const response = await axios.post(api , userData);
            if(response.data.success === true){
                const token = response.data.data.authorisation.token;
                const decodeToken = jwtDecode(response.data.data.authorisation.token);
                console.log(decodeToken)
                localStorage.setItem("expire_date", decodeToken.exp);
                localStorage.setItem("token", token);
                if(token) {
                    axios.defaults.headers.common = {'Authorization' : `Bearer ${token}`}
                    axios.defaults.headers.common["Content-Type"] = "application/json";
                    axios.defaults.headers.common["Accept"] = "application/json";
                }
                // localStorage.setItem("expire_date", response.data.data.expiry_token);
                const user = {
                    id : response.data.data.user.id,
                    name : response.data.data.user.name,
                    email : response.data.data.user.email,
                    phone : response.data.data.user.phone,
                    image : response.data.data.user.image_url,
                    roleName : response.data.data.user.roles && response.data.data.user.roles[0].display_name,
                    // assistantTeacherId : response.data.data.user.user_id && response.data.data.user.user_id,
                    permissions : response.data.data.user.roles[0].permissions && response.data.data.user.roles[0].permissions,
                }
                localStorage.setItem("user", JSON.stringify(user));
                checkErrorsFunctions(response , response.data.errors);
                return response.data
            }else {
                if(response.data && response.data.success === false && response.data.message) {
                    swal("Oops!", response.data.message, "warning");
                }
            }
        }
        catch (error) {
            console.log(error);
            checkErrors(error.response , error.message);
        }
    }
);

const loginSlicer = createSlice({
    name : "user login",
    initialState : {
        loading : false,
        status : null,
        error : false
    },
    reducers : {
        loginAction : (state , action) => {
            state.loading = false
            state.status = "loggedIn"
            state.error = false
        }
    },
    extraReducers : (builder) => {
        builder.addCase(loginFunc.pending , (state) =>{
            state.loading = true
            state.status = null
            state.error = false
        })
        builder.addCase(loginFunc.rejected , (state) =>{
            state.loading = false
            state.status = null
            state.error = true
        })
        builder.addCase(loginFunc.fulfilled , (state , action) =>{
            state.loading = false
            state.status = action.payload
            state.error = false
        })
    }
})
export default loginSlicer.reducer;
export let {loginAction} = loginSlicer.actions;