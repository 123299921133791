import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { Accordion, Spinner, Table } from 'react-bootstrap';
import ContextApi from '../../../../store/ContextApi';
import { fetchAllStudentsFromApi } from '../../../../store/custom-actions/students-actions/GetAllStudentsSlicer';
import Swal from 'sweetalert2';
import {addNewSessionFunc} from '../../../../store/custom-actions/sessions-actions/AddSessionSlicer';
import defaultStImg from "../../../../images/1.jpg";
import "./sessions-page.css";
import { getSingleSessionFunc } from '../../../../store/custom-actions/sessions-actions/ShowSingleSessionSlicer';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PageTitle from '../../../layouts/PageTitle';
import SessionExamModal from '../../../global-compnents/show-offline-exam-modal/SessionExamModal';
import { deleteSessionExamFunc } from '../../../../store/custom-actions/offline-exams-actions/DeleteSessionExamSlicer';
import swal from 'sweetalert';
import OverLayLoading from '../../custome-overlay-loading/OverLayLoading';
function ShowSingleSessionPage(props) {
    const params = useParams()
    const sessionId = params.sessionId;
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const singleSessionApi = `${contextApi.dashboard_URL_API}/sessions/${sessionId}`;
    const deleteOfflineExamsApi = `${contextApi.dashboard_URL_API}/offline-exams`;
    const {data ,  loading} = useSelector(state => state.getSingleSessionSlicer);
    const {loading:deleteExamLoading} = useSelector(state => state.deleteSessionExamSlicer);
    const [showExamModal , setShowExamModal] = useState();
    const [sessionData , setSessionData] = useState();
    const [sessionExam , setSessionExam] = useState({});

    useEffect(() => {
        const showSession = () => {
            dispatch(getSingleSessionFunc(singleSessionApi))
        }
        showSession()
    },[])

    useEffect(() => {
        data && setSessionData(data)
        data && data.offline_exam && setSessionExam(data.offline_exam)
    },[data])

    const deleteOfflineExam = async (examId) => {
        const deleteFunc = () => {
            dispatch(deleteSessionExamFunc(`${deleteOfflineExamsApi}/${examId}`))
            .then(result => {
                if(result.payload.success === true) {
                    dispatch(getSingleSessionFunc(singleSessionApi))
                }
            })
        }
        swal({
            title: "Confirm Session Exam Deletion",
            text: `Once deleted, This Exam Will Not Avilable On Platform!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteFunc();
            } else {
                swal("Your Exam is safe!");
            }
        });
    }

    return (
        <>
        <PageTitle motherMenu={"Sessions"} activeMenu={"Show Session"} />
            {
                loading
                ?
                <>
                    <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    </div>
                </>
                :
                <div className='session-content'>
                    <div className='row flex justify-between mb-3'>
                        <div className='session-date flex items-center'>
                            <i className="fa-solid fa-clock mr-1"></i>
                            <p className='m-0'>Session Date : {sessionData && sessionData.session_date}</p>
                        </div>
                        <div className='relative'>
                            <Button className='btn btn-primary'
                                onClick={()=>{
                                    data && data.offline_exam
                                    ?
                                    setShowExamModal(true)
                                    :
                                    Swal.fire({
                                        title: "Oops!",
                                        text: "No Exam For This Session!",
                                        icon: "warning"
                                    });
                                }}
                            >Show Exam</Button>
                            {
                                data && data.offline_exam
                                &&
                                <Button className='btn bg-red-500 ml-2'
                                    onClick={()=>deleteOfflineExam(sessionExam.id)}
                                >Delete Exam</Button>
                            }
                        </div>
                    </div>
                    <Table className='bg-white rounded-lg' responsive>
                        <thead>
                            <tr className='p-3'>
                            <th className="width80">
                                <strong className='text-primary'>#ID</strong>
                            </th>
                            <th>
                                <strong>Student Name</strong>
                            </th>
                            <th>
                                <strong>Student Email</strong>
                            </th>
                            <th>
                                <strong>Attendance Status</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                        {sessionData
                            &&
                            sessionData.students
                            &&
                            sessionData.students.length > 0 &&
                            sessionData.students.map((student,index)=>{
                            return (
                            <tr key={index} className='p-3'>
                            <td>
                                <strong className='text-primary'>#{student.id}</strong>
                            </td>
                            <td className='p-3'>
                                <Link className='flex items-center' to={`/student-profile/${student.id}`}>
                                    <div className='relative mr-2 w-[40px] h-[40px] overflow-hidden rounded-[50%]'>
                                        <img className='absolute top-0 left-0 w-full h-full' src={student.image_url !== null ? student.image_url : defaultStImg}alt="st img" />
                                    </div>
                                    <p className='m-0 text-black'>{student.name}</p>
                                </Link>
                                
                            </td>
                            <td>{student.email}</td>
                            <td>
                                <span className={`p-2 w-fit flex justify-center items-center text-white rounded-md ${student.pivot && student.pivot.status === "absent" ? "bg-red-500" : "bg-green-500"}`}>{student.pivot && student.pivot.status}</span>
                            </td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>
            }
            <SessionExamModal
                show = {showExamModal}
                onHide = {() => setShowExamModal(false)}
                sessionexam = {sessionExam}
            />
            <OverLayLoading loading={deleteExamLoading} loadingText={"Deleting Session Exam"} />
        </>
    );
}
export default ShowSingleSessionPage