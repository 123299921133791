import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { deleteCategoryFunc } from "../../../store/custom-actions/categories-actions/DeleteCategoriesSlicer";
import swal from "sweetalert";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { getAttachedFunc } from "../../../store/custom-actions/attached-actions/GetAttachedSlicer";
import { useTranslation } from "react-i18next";
import { deleteAttachedFunc } from "../../../store/custom-actions/attached-actions/DeleteAttachedSlicer";
import { showAttachedFunc } from "../../../store/custom-actions/attached-actions/ShowAttachedSlicer";

function AttachedsPage() {
    const {t} = useTranslation();
    const {loading:getAttachedLoading , data:attachedData} = useSelector(state => state.getAttachedSlicer)
    const {loading:deleteAttachedLoading} = useSelector(state => state.deleteAttachedSlicer)
    const contextApi = useContext(ContextApi);
    const attachedApi = `${contextApi.dashboard_URL_API}/attacheds`;
    const [attachedList , setAttachedList] = useState([]);
    const [showCategoryForm , setShowCategoryForm] = useState();
    const [showUpdateCategoryForm , setShowUpdateCategoryForm] = useState();
    const [catNameWillUpdate , setCatNameWillUpdate] = useState();
    const [catIdWillUpdate , setCatIdWillUpdate] = useState();
    const [catParnetIdWillUpdate , setCatParnetIdWillUpdate] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const getData = async () => {
          try {
            dispatch(getAttachedFunc(attachedApi))
          } catch (error) {
            console.log(error);
          }
        };
        getData();
      }, []);
      useEffect(() => {
        attachedData && setAttachedList(attachedData);
      }, [attachedData]);
      useEffect(() => {
        attachedData && setAttachedList(attachedData);
        attachedData && (jobData.current = attachedData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [attachedData]);
      const sort = 20;
      let paggination = Array(Math.ceil(attachedList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        attachedList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = attachedList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const deleteItem = async (id) => {
        const deleteFunc = () => {
          try {
            dispatch(deleteAttachedFunc(`${attachedApi}/${id}`))
            .then((result)=>{
              if(result.payload.success === true){
                dispatch(getAttachedFunc(attachedApi));
              }
            });
          }catch (error) {
            console.log(error);
          }
        }
        swal({
          title: t("Confirm Item Deletion"),
          text: t(`Once deleted, this Item will not be Avilable in platform!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteFunc()
          }
        })
      };
      const showAttached = async (id) => {
        // const deleteFunc = () => {
          try {
            dispatch(showAttachedFunc(`${attachedApi}/${id}/stream`))
            .then((result)=>{
              if(result.payload.success === true){
                dispatch(getAttachedFunc(attachedApi));
              }
            });
          }catch (error) {
            console.log(error);
          }
        // }
        // swal({
        //   title: t("Confirm Item Deletion"),
        //   text: t(`Once deleted, this Item will not be Avilable in platform!`),
        //   icon: "warning",
        //   buttons: true,
        //   dangerMode: true,
        // }).then((willDelete) => {
        //   if (willDelete) {
        //     deleteFunc()
        //   }
        // })
      };
      const [searchValue , setSearchValue] = useState("")
      const search = () => {
        if(searchValue !== "") {
          const searchResult = attachedData &&
          attachedData.length >= 1 && attachedData.filter((item) =>  
            item.added_by.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );
          jobData.current = searchResult
        }else {
          dispatch(getAttachedFunc(attachedApi))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getAttachedFunc(attachedApi));
      },[searchValue])

    return (
        <>
        <PageTitle activeMenu={"Attacheds"} motherMenu={"Dashboard"}/>
        {/* {
          attachedData && attachedList?.length < 1
          &&
          <div className="relative flex justify-end items-center">
            <Link 
            to={"#"} 
            className="btn btn-primary btn-sm"
            onClick={() => {
              setShowCategoryForm(true)
            }}
            >
              Create
              <i className="fa-solid fa-plus ml-1"></i>
            </Link>
          </div>
        } */}
        <div className="categories-section relative ">
            {
                getAttachedLoading 
                ?
                (
                    <>
                    <div className="row d-flex justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                  </>
                )
                :
                <>
                    {
                        attachedList && attachedList.length > 0 
                        ?
                        <div className="col-12">
                          <div className="card">
                            <div className="card-header flex justify-between items-center">
                              <h4 className="card-title">{t("Attacheds")}</h4>
                              <div className="relative add-and-search flex items-center">
                                <div className="input-group search-area w-auto mr-2">
                                    <span className="input-group-text"
                                      onClick={() => search()}
                                    >
                                        <Link to={"#"}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                            fill="var(--primary)"
                                            ></path>
                                        </svg>
                                        </Link>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Search here...")}
                                        onChange={(e) => {
                                          setSearchValue(e.target.value);
                                        }}
                                    />
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="w-100 table-responsive">
                                <div id="example_wrapper" className="dataTables_wrapper">
                                  <table id="example" className="display w-100 dataTable">
                                    <thead>
                                      <tr role="row">
                                        <th>#{t("ID")}</th>
                                        <th>{t("Added By")}</th>
                                        <th>{t("Lessons Count")}</th>
                                        <th>{t("Shared Count")}</th>
                                        <th>{t("Action")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        jobData.current.map((item , index) => {
                                            const date = new Date(item.created_at)
                                            const year = date.getFullYear();
                                            const month = date.getMonth()+1;
                                            const day = date.getDate();
                                            return (
                                            <tr key={index}>
                                              <td>
                                              <strong>#{item.id}</strong>
                                              </td>
                                              <td>
                                                {item.added_by}
                                              </td>
                                              <td>
                                                {item.lessons_count}
                                              </td>
                                              <td>
                                                {item.shared_count}
                                              </td>
                                              <td className="flex items-center">
                                                <Link
                                                    to="#"
                                                    className="btn btn-danger shadow btn-xs sharp"
                                                    onClick={() => {
                                                        deleteItem(item?.id);
                                                    }}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </Link>
                                                {/* <Link
                                                    to={`#`}
                                                    className="btn btn-primary shadow btn-xs sharp me-1 ml-1"
                                                    onClick={() => {
                                                      setCatNameWillUpdate(item.name)
                                                      setCatParnetIdWillUpdate(item.parent_id)
                                                      setCatIdWillUpdate(item.id)
                                                      setShowUpdateCategoryForm(true);
                                                  }}
                                                >
                                                    <i className="fas fa-pencil-alt"></i>
                                                </Link> */}
                                                <Link
                                                    // to={`/single-category/${item.id}`}
                                                    to={`#`}
                                                    onClick={() => showAttached(item.id)}
                                                    className="btn btn-primary shadow btn-xs sharp me-1 ml-1"
                                                  //   onClick={() => {
                                                  //     setCatNameWillUpdate(item.name)
                                                  //     setCatIdWillUpdate(item.id)
                                                  //     setShowUpdateCategoryForm(true);
                                                  // }}
                                                >
                                                    <i className="fa-solid fa-eye"></i>
                                                </Link>
                                              </td>
                                            </tr>
                                            )
                                        }) 
                                      }
                                    </tbody>
                                  </table>
                                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                    <div className="dataTables_info">
                                      {t("Showing")} {activePag.current * sort + 1} to{" "}
                                      {attachedList.length > (activePag.current + 1) * sort
                                        ? (activePag.current + 1) * sort
                                        : attachedList.length}{" "}
                                      {t("of")} {attachedList.length} {t("entries")}
                                    </div>
                                    <div
                                      className="dataTables_paginate paging_simple_numbers mb-0"
                                      id="example5_paginate"
                                    >
                                      <Link
                                        className="paginate_button previous disabled"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current > 0 && onClick(activePag.current - 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                      </Link>
                                      <span>
                                        {paggination.map((number, i) => (
                                          <Link
                                            key={i}
                                          
                                            to="#"
                                            className={`paginate_button  ${
                                              activePag.current === i ? "current" : ""
                                            } `}
                                            onClick={() => onClick(i)}
                                          >
                                            {number}
                                          </Link>
                                        ))}
                                      </span>
                                      <Link
                                        className="paginate_button next"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current + 1 < paggination.length &&
                                          onClick(activePag.current + 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <h2>{t("No Data")}</h2>
                    }
                </>
            }
        </div>
        <OverLayLoading loading={deleteAttachedLoading} loadingText={t("Deleting Attached...")}/>
        </>
    )
}


export default AttachedsPage