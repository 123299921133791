import React from "react";
import ReactApexChart from "react-apexcharts";

// class PieChart extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       series: [100, 300, 100, 400, 200, 400],
//       options: {
//         chart: {
//           type: 'donut', // Change chart type to 'pie'
//           height: 300, // Adjust height if needed
//           toolbar: {
//             show: false,
//           },
//         },
//         labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Label 6'], // Add labels for each data point
//         colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#FF9800', '#9C27B0'], // Specify colors for each slice
//         legend: {
//           show: false,
//         },
//       },
//     };
//   }

//   render() {
//     return (
//       <div id="NewCustomers">
//         <ReactApexChart
//           options={this.state.options}
//           series={this.state.series}
//           type="pie"
//           height={200}
//         />
//       </div>
//     );
//   }
// }
function PieChart ({activeCount , allCount}) {
        const activeRange = allCount - activeCount
        const data = {
            series: [activeRange, allCount],
            options: {
              chart: {
                type: 'donut', // Change chart type to 'donut'
                height: 200, // Adjust height if needed
                toolbar: {
                  show: false,
                },
              },
              labels: ['Active', 'not active'], // Add labels for each data point
              colors: ['#FF6384', '#36A2EB'], // Specify colors for each slice
              legend: {
                show: false,
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: '50%', // Adjust the size of the hole
                  },
                },
              },
            },
        }
    
      return (
        <div id="NewCustomers">
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="donut"
            height={200}
          />
        </div>
      );
  }

export default PieChart;