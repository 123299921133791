import "./students-page.css";
import { Spinner , Dropdown } from "react-bootstrap";
import { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContextApi from "../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStudentsFromApi } from "../../../store/custom-actions/students-actions/GetAllStudentsSlicer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { deleteStudentFunc } from "../../../store/custom-actions/students-actions/DeleteStudentSlicer";
import { addNewStudentFunc } from "../../../store/custom-actions/students-actions/AddStudentSlicer";
import { editStudentFunc } from "../../../store/custom-actions/students-actions/EditStudentSlicer";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { unApproveStudentFunc } from "../../../store/custom-actions/students-actions/UnApproveStudentSlicer";
import { approveStudentFunc } from "../../../store/custom-actions/students-actions/ApproveStudentSlicer";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { Bounce, toast } from "react-toastify";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import ChangeStudentPassForm from "../custom-drop-down/ChangeStudentPassForm";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import PageTitle from "../../layouts/PageTitle";
import NewSubscripeForm from "../../custom-forms/subscriptions/NewSubscripeForm";
import { useTranslation } from "react-i18next";

// addition new student form
function AddNewStudentForm(props) {
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm
  ();
  const { loading } = useSelector((state) => state.addStudentSlicer);
  const dispatch = useDispatch();
  const { studentapi, allstudents } = props;
  const studentName = watch("name");
  const studentEmail = watch("email");
  const studentPassword = watch("password");
  const studentConfirmPassword = watch("password_confirmation");
  // const validName = /[a-zA-Z]{3,}/;
  // const validEmail = /^[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
  const validPass = /[a-zA-Z0-9]{8,}/;
  const validPhone = /[0-9]{11,}/;
  const [validationPassword, setValidationPassword] = useState();
  const [alreadyStudent, setAlreadyStudent] = useState();

  useEffect(() => {
    if (studentConfirmPassword !== studentPassword) {
      setValidationPassword(false);
    } else {
      setValidationPassword(true);
    }
  }, [studentPassword, studentConfirmPassword]);

  useEffect(() => {
    const find =
      allstudents &&
      allstudents.length > 0 &&
      allstudents.find(
        (student) =>
          student.email === studentEmail && student.password === studentPassword
      );
    find ? setAlreadyStudent(true) : setAlreadyStudent(false);
  }, [allstudents, studentEmail, studentPassword]);


  const handleSubmitData = (data) => {
    if (!validationPassword) {
      toast.warning(t("Password not confirmed!"));
      return;
    }
  
    if (!data.password.match(validPass)) {
      toast.warning(t("Password must be more than 8 characters!"));
      return;
    }
  
    if (!data.phone.match(validPhone)) {
      toast.warning(t("Phone must be 11 numbers!"));
      return;
    }
  
    // if (!data.name.match(validName)) {
    //   toast.warning(t("Student name must be more than 3 letters!"));
    //   return;
    // }
  
    // if (!data.email.match(validEmail)) {
    //   toast.warning(t("Please enter a valid email!"));
    //   return;
    // }
  
    dispatch(addNewStudentFunc({
      api: studentapi,
      studentData: data,
    })).then((result) => {
      if (result.payload.success === true) {
        dispatch(fetchAllStudentsFromApi(studentapi));
        props.onHide();
        reset();
      }
    })
  };
  useEffect(() => {
    if(errors) {
      Object.keys(errors).forEach(key => {
        toast.warning(
          errors[key].message , {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
      })
    }
  },[errors])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="fa-solid fa-graduation-cap mr-1"></i>
          {t("New Students")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form
                  id="new-student-form"
                  onSubmit={handleSubmit((data) => {
                    // if (alreadyStudent) {
                    //   swal({
                    //     title: "Oops!",
                    //     text: `${studentName} is already a student on this platform`,
                    //     icon: "warning",
                    //     button: "Try again!",
                    //   });
                    // } else {
                    //   if (validationPassword) {
                    //     dispatch(
                    //       addNewStudentFunc({
                    //         api: studentapi,
                    //         studentData: data,
                    //       })
                    //     ).then((result)=> {
                    //       if(result.payload.success === true){
                    //         dispatch(fetchAllStudentsFromApi(studentapi));
                    //         props.onHide();
                    //         reset();
                    //       }
                    //       }
                    //     )
                    //   }
                    //   else {
                    //     swal("Password not confirmed!");
                    //   }
                    // }
                    handleSubmitData(data)
                  })}
                >
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Name")}</label>
                      {errors.name?.message && (
                        <p className="text-red-600">{errors.name?.message}</p>
                      )}
                      <input
                        {...register("name", {
                          required: t("Student Name is required!")})}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Email")}</label>
                      {errors.email?.message && (
                        <p className="text-red-600">{errors.email?.message}</p>
                      )}
                      <input
                        {...register("email", {
                          required: t("Email is required"),
                        })}
                        type="text"
                        className="form-control"
                        placeholder="Example12@gmail.com"
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Password")}</label>
                      {errors.password?.message && (
                        <p className="text-red-600">
                          {errors.password?.message}
                        </p>
                      )}
                      <input
                        {...register("password", {
                          required: t("Password is required"),
                        })}
                        type="password"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Confirm Password")}</label>
                      {errors.password_confirmation?.message && (
                        <p className="text-red-600">
                          {errors.password_confirmation?.message}
                        </p>
                      )}
                      <input
                        type="password"
                        className="form-control"
                        {...register("password_confirmation", {
                          required: t("Please confirm password"),
                        })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12">
                      <label>{t("Phone Number")}</label>
                      {errors.phone?.message && (
                        <p className="text-red-600">
                          {errors.phone?.message}
                        </p>
                      )}
                      <input
                        {...register("phone", {
                          required: t("Invalid Phone number")})}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("Close")}</Button>
        <Button type="submit" form="new-student-form" className={`${loading ? "opacity-80 pointer-events-none" : ""}`}>
          {!loading ? (
            t("Submit")
          ) : (
            <div className="loading-spin-data w-[19px] h-[19px] rounded-[50%] border-t-[2px] border-r-[2px] border-white "></div>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

// edit student form
function EditStudentForm(props) {
  const{t} = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const contextApi = useContext(ContextApi);
  const { editStudentloading } = useSelector((state) => state.editStudentSlicer);
  const dispatch = useDispatch();
  const { student , studentapi , allstudents } = props;
  const students_URL_APi = `${contextApi.dashboard_URL_API}/students`;
  const validName = /[a-zA-Z]{3,}/;
  const validPhone = /[0-9]{11,}/;
  const studentEmail = watch("email");
  const [emailAndPassUsed, setEmailAndPassUsed] = useState();

  useEffect(() => {
    const alreadyData =
      allstudents &&
      allstudents.find((student) => student.email === studentEmail);
    alreadyData ? setEmailAndPassUsed(true) : setEmailAndPassUsed(false);
  }, [allstudents, studentEmail]);

  useEffect(() => {
    if (student) {
      setValue("name", student.name);
      setValue("phone", student.phone);
    }
  }, [setValue, student]);
  const handleSubmitData = (data) => {
    if(!data.phone.match(validPhone)) {
      toast.error(t("Phone Number Should be 11 Numbers!"))
    }else{
      dispatch(
        editStudentFunc({ api: studentapi, newData: data })
      ).then(result => {
        if(result.payload.success === true){
          dispatch(fetchAllStudentsFromApi(students_URL_APi));
          props.onHide();
          reset();
          navigate(`student-profile/${student.id}`)
        }else {
          swal({
            title: t("Oops!"),
            text: t(`Failed to Update Student Data. Please Retry !`),
            icon: "warning",
            button: "Done",
          });
        }
      })
    }
  }
  useEffect(() => {
    HandleErrorsListFunc(errors)
  },[errors])
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Update Data")}
        </Modal.Title>
        <i
          className="fa-solid fa-xmark text-xl cursor-pointer"
          onClick={() => {
            reset();
            props.onHide();
          }}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <div className="w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                {student && (
                  <form
                    id="update-student-form"
                    onSubmit={handleSubmit((data) => {
                      handleSubmitData(data)
                    })}
                  >
                    <div className="row">
                      <div className="form-group mb-3 col-sm-12 col-md-6">
                        <label>{t("Name")}</label>
                        {errors.name?.message && (
                          <p className="text-red-600">{errors.name?.message}</p>
                        )}
                        <input
                          {...register("name", {
                            required: true,
                            pattern: validName,
                            minLength: {
                              value: 3,
                              message: t("Name must be more than 3 letters!"),
                            },
                          })}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group mb-3 col-sm-12 col-md-6">
                        <label>{t("Phone Number")}</label>
                        {errors.phone?.message && (
                          <p className="text-red-600">
                            {errors.phone?.message}
                          </p>
                        )}
                        <input
                          {...register("phone", {
                            required: t("Phone Number Shuold be 11 Numbers!"),
                          })}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            reset();
            props.onHide();
          }}
        >
          {t("Close")}
        </Button>
        <Button type="submit" form="update-student-form" className={`${editStudentloading ? "opacity-80 pointer-events-none" : ""}`}>
          {editStudentloading ? <Spinner animation="border" size="sm" /> : t("Submit")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

// delete and edit drop down menu
function DecisionsDropMenu(props) {
  const {t} = useTranslation();
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
              {/* <Dropdown.Item
                onClick={() => {
                  props.showMoreAboutStudent();
                }}
              >
                <i className="fa-solid fa-circle-info mr-1"></i>
                Info
              </Dropdown.Item> */}
          {
            checkPermissions
            (
              "students_update"
              ,
              <>
                <Dropdown.Item
                  onClick={() => {
                    props.show_change_pass_form();
                  }}
                >
                  <i className="fa-solid fa-lock mr-1"></i>
                  {t("Change Password")}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    props.showEditForm();
                  }}
                >
                  <i className="fa-solid fa-pen-nib mr-1"></i>
                  {t("Edit")}
                </Dropdown.Item>
              </>
            )
          }
          <Dropdown.Item
            onClick={() => {
              props.showNewSubscripeForm();
            }}
          >
            <i className="fa-solid fa-wand-magic-sparkles mr-1"></i>
            {t("New Subscripe")}
          </Dropdown.Item>
          {
            props.student_status === "publish"
            ?
            checkPermissions
            (
              "students_update"
              ,
              <Dropdown.Item
                onClick={() => {
                  props.un_approve_student();
                }}
              >
                <i className="fa-solid fa-chart-line mr-1"></i>
                {t("Un Active")}
              </Dropdown.Item>
            )
            :
            checkPermissions
            (
              "students_update"
              ,
              <Dropdown.Item
              onClick={() => {
                props.approve_student();
              }}
            >
              <i className="fa-solid fa-chart-line mr-1"></i>
              {t("Active")}
              </Dropdown.Item>
            )
          }
          {
            checkPermissions
            (
              "students_delete"
              ,
              <Dropdown.Item
                onClick={() => {
                  swal({
                    title: t("Confirm Student Deletion"),
                    text: t(`Once deleted, will not be student in platform!`),
                    icon: "warning",
                    buttons: {
                      cancel: {
                        text: t("Cancel"),
                        value: false,
                        visible: true,
                        className: "",
                        closeModal: true,
                      },
                      confirm: {
                        text: t("OK"),
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                      },
                    },
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      props.deleteFunction(props.api);
                    }
                  });
                }}
              >
                <i className="fa-solid fa-ban mr-1"></i>
                {t("Delete")}
              </Dropdown.Item>
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function StudentsPage() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { loading, students } = useSelector((state) => state.getAllStudents);
  const { approve_student_loading } = useSelector((state) => state.approveStudentSlicer);
  const { unapprove_student_loading } = useSelector((state) => state.unApproveStudentSlicer);
  const { deleteStudentLoading } = useSelector((state) => state.deleteStudentSlicer);
  const [isSearch, setIsSearch] = useState();
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const contextApi = useContext(ContextApi);
  const students_URL_APi = `${contextApi.dashboard_URL_API}/students`;
  const [allStudents, setAllStudents] = useState([]);
  const [allStudentsCount, setAllStudentsCount] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [editFormShowStatus, setEditFormShowStatus] = useState(false);
  const [editStudentApi, setEditStudentApi] = useState();
  const [student, setStudent] = useState();
  const [filterWord, setFilterWord] = useState();
  const [acticveSt , setActiveSt] = useState([])
  const [showChangePass , setShowChangePass] = useState();
  const [studentId , setStudentId] = useState();
  const [studentsList , setStudentsList] = useState([])
  const [showSubscriptionForm , setShowSubscriptionForm] = useState();
  const navigate = useNavigate();
  const approveStudent = async (studentName , studentId) => {
        await dispatch(approveStudentFunc({studentApi: `${students_URL_APi}/${studentId}/approve`}))
        .then(result => {
            if(result.payload.success === true){
              dispatch(fetchAllStudentsFromApi(students_URL_APi));
            }
        })
}
  const unApproveStudent = (studentName , studentId) => {
      swal({
        title: t("Confirm Student Un Active"),
        text: t(`Once Un Active, will not be active in platform!`),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(unApproveStudentFunc({studentApi: `${students_URL_APi}/${studentId}/approve`}))
          .then(result => {
              if(result.payload.success === true){
                dispatch(fetchAllStudentsFromApi(students_URL_APi));
              }
          })
        }
      });

    }

  useEffect(() => {
    const studentsApi = `${students_URL_APi}?${filterWord ? `publish=${filterWord}&` : ""}${studentSearchValue ? `search=${studentSearchValue}` : ""}`;
    let searchTimeout;
    let fetchNewStudentsData = async ()=>{
      if(studentSearchValue !== "") {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=>{
          dispatch(fetchAllStudentsFromApi(studentsApi))
        },2000)
      }else {
        dispatch(fetchAllStudentsFromApi(studentsApi))
      }
    }
    fetchNewStudentsData()
    return () => clearTimeout(searchTimeout)
  },[dispatch , filterWord , studentSearchValue])
  
  useEffect(() => {
    students && setAllStudents(students);
    const filterActiveSt = () => {
      const activeStudents = students.length > 0 && students.filter(student => {
        return student.publish === "publish"
      })
      setActiveSt(activeStudents)
    }
    filterActiveSt();
  }, [students]);
  useEffect(() => {
    students && setStudentsList(students);
    students && (jobData.current = students.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      ));
  }, [students]);
  const sort = 20;
  let paggination = Array(Math.ceil(studentsList.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    studentsList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    )
  );
  const onClick = (i) => {
    activePag.current = i;
    jobData.current = studentsList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
  };
  const [searchValue , setSearchValue] = useState("")
  const search = () => {
    if(searchValue !== "") {
      const searchResult = students &&
      students.length >= 1 && students.filter((cat) =>  
        cat.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        // ||
        // cat.sku.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );
      jobData.current = searchResult
    }else {
      dispatch(fetchAllStudentsFromApi(students_URL_APi))
    }
  }
  useEffect(() => {
    searchValue === "" && dispatch(fetchAllStudentsFromApi(students_URL_APi));
  },[searchValue])
  const deleteStudentFunction = async (api) => {
    try {
      dispatch(deleteStudentFunc(api)).then((result)=>{
        if(result.payload.success === true){
          dispatch(fetchAllStudentsFromApi(students_URL_APi));
        }else {
          swal(t("faild delete student") , "");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    allStudents && setAllStudentsCount(allStudents.length);
  }, [allStudents]);

  useEffect(() => {
    studentSearchValue !== "" ? setIsSearch(true) : setIsSearch(false)
  },[studentSearchValue])

  const showEditForm = (edit_Student_Api, student) => {
    setEditStudentApi(edit_Student_Api);
    setStudent(student);
    setEditFormShowStatus(true);
  };

  const showNewSubscripeForm = (student) => {
    // setEditStudentApi(edit_Student_Api);
    setStudentId(student.id);
    setShowSubscriptionForm(true);
  };





  return (
    <>
      <PageTitle activeMenu={"Students"} motherMenu={"Dashboard"} />
      <div className="row">
          <>
            <div className="col-12">
              <div className="card">
                <div className="card-header flex justify-between items-center">
                  <h4 className="card-title">{t("Students")}</h4>
                  <div className="relative add-and-search flex items-center">
                    <div className="input-group search-area w-auto mr-2">
                        <span className="input-group-text"
                          // onClick={() => search()}
                        >
                            <Link to={"#"}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                fill="var(--primary)"
                                ></path>
                            </svg>
                            </Link>
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Search here...")}
                            onChange={(e) => {
                              // setSearchValue(e.target.value);
                              setStudentSearchValue(e.target.value);
                            }}
                        />
                    </div>
                    <div className="filter-icon relative mx-2">
                      <select className="form-control" id="filter-courses-btn"
                        onChange={(e)=>{
                          console.log(e.target.value)
                          setFilterWord(e.target.value)
                        }}
                      >
                        <option value={""}>{t("All Students")}</option>
                        <option value={"publish"}>{t("Active Students")}</option>
                        <option value={"unpublish"}>{t("Un Active Students")}</option>
                      </select>
                    </div>
                    {
                      checkPermissions(
                        "students_create",
                        <Link 
                        to={"#"} 
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setModalShow(true);
                        }}
                        >
                          {t("Create")}
                          <i className="fa-solid fa-plus ml-1"></i>
                        </Link>
                      )
                    }
                  </div>
                </div>
                <div className="card-body">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <table id="example" className="display w-100 dataTable">
                        <thead>
                          <tr role="row">
                            {/* <th>#ID</th> */}
                            <th>{t("Name")}</th>
                            <th>{t("Email")}</th>
                            <th>{t("Contact")}</th>
                            <th>{t("Status")}</th>
                            {/* <th>Info</th> */}
                            <th>{t("Action")}</th>
                          </tr>
                        </thead>
                        {
                          loading 
                          ?
                          <>
                          <div className="row d-flex justify-content-center gap-2 my-4 pl-4">
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                          </div>
                          </>
                          :
                          <tbody>
                            {
                              jobData.current.map((item , index) => {
                                  const date = new Date(item.created_at)
                                  const year = date.getFullYear();
                                  const month = date.getMonth()+1;
                                  const day = date.getDate();
                                  return (
                                    <tr key={index}>
                                      {/* <td className="font-bold text-[#4d5fe3]">
                                        ID:{item.id}
                                      </td> */}
                                      {/* <td>
                                        <div className="d-flex align-items-center">
                                          <i className="fa-solid fa-user text-[20px]"></i>
                                          <h4 className="mb-0 fs-16 font-w500 ml-2">
                                            {item.name}
                                          </h4>
                                        </div>
                                      </td> */}
                                      <td>
                                        <Link to={`/student-profile/${item.id}`} className="relative flex items-center">
                                          <img 
                                            src={item.image_url ? item.image_url : "https://i.pinimg.com/564x/b9/8f/43/b98f4351a2f0e183156d8583e8184f9a.jpg"} 
                                            className="mr-1 w-[30px] h-[30px] rounded-[50%] cursor-pointer" 
                                            alt="item img"
                                          />
                                          {item.name}
                                        </Link>
                                      </td>
                                      <td>{item.email}</td>
                                      <td className="text-[16px]">
                                        {item.phone}
                                      </td>
                                      <td className="">
                                        <i className={`fa-solid fa-temperature-full mr-1 ${item.publish === "publish" ? "text-green-500" : "text-red-400"}`}></i>
                                        
                                          {item.publish === "publish" ? "Active" : "Not Active"}
                                        
                                      </td>
                                      {
                                        (
                                          checkPermissions(
                                            "students_update" , 
                                            <td>
                                              <DecisionsDropMenu
                                                deleteFunction={() => {
                                                  deleteStudentFunction(
                                                    `${students_URL_APi}/${item.id}`
                                                  );
                                                }}
                                                api={`${students_URL_APi}/${item.id}`}
                                                show_change_pass_form = {() => {
                                                  setStudentId(item.id);
                                                  setShowChangePass(true);
                                                }}
                                                studentName={item.name}
                                                showEditForm={() =>
                                                  showEditForm(
                                                    `${students_URL_APi}/${item.id}`,
                                                    item
                                                  )
                                                }
                                                student_status = {item.publish}
                                                approve_student = {()=>approveStudent(item.name , item.id)}
                                                un_approve_student = {()=>unApproveStudent(item.name , item.id)}
                                                showMoreAboutStudent = {() => navigate(`student-profile/${item.id}`)}
                                                showNewSubscripeForm = {() => showNewSubscripeForm(item)}
                                              />
                                            </td>
  
                                          ) 
                                          || 
                                          checkPermissions(
                                            "students_delete" , 
                                            <td>
                                              <DecisionsDropMenu
                                                deleteFunction={() => {
                                                  deleteStudentFunction(
                                                    `${students_URL_APi}/${item.id}`
                                                  );
                                                }}
                                                show_change_pass_form = {() => {
                                                  setStudentId(item.id);
                                                  setShowChangePass(true);
                                                }}
                                                api={`${students_URL_APi}/${item.id}`}
                                                studentName={item.name}
                                                showEditForm={() =>
                                                  showEditForm(
                                                    `${students_URL_APi}/${item.id}`,
                                                    item
                                                  )
                                                }
                                                student_status = {item.publish}
                                                approve_student = {()=>approveStudent(item.name , item.id)}
                                                un_approve_student = {()=>unApproveStudent(item.name , item.id)}
                                                showNewSubscripeForm = {() => {
                                                  console.log("clicked")
                                                  // setStudentId(item.id)
                                                  setShowSubscriptionForm(true);
                                                }}
                                              />
                                            </td>
                                          )
                                        )
                                      }
                                    </tr>
                                  );
                              }) 
                            }
                          </tbody>
                        }
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                        <div className="dataTables_info">
                          {t("Showing")} {activePag.current * sort + 1} to{" "}
                          {studentsList.length > (activePag.current + 1) * sort
                            ? (activePag.current + 1) * sort
                            : studentsList.length}{" "}
                          {t("of")} {studentsList.length} {t("entries")}
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="example5_paginate"
                        >
                          <Link
                            className="paginate_button previous disabled"
                          //   to="/table-datatable-basic"
                            to="#"
                            onClick={() =>
                              activePag.current > 0 && onClick(activePag.current - 1)
                            }
                          >
                            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                          </Link>
                          <span>
                            {paggination.map((number, i) => (
                              <Link
                                key={i}
                              //   to="/table-datatable-basic"
                                to="#"
                                className={`paginate_button  ${
                                  activePag.current === i ? "current" : ""
                                } `}
                                onClick={() => onClick(i)}
                              >
                                {number}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                          //   to="/table-datatable-basic"
                            to="#"
                            onClick={() =>
                              activePag.current + 1 < paggination.length &&
                              onClick(activePag.current + 1)
                            }
                          >
                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      </div>
      <AddNewStudentForm
        show={modalShow}
        studentapi={students_URL_APi}
        allstudents={allStudents}
        onHide={() => setModalShow(false)}
      />
      <EditStudentForm
        show={editFormShowStatus}
        onHide={() => {
          setEditFormShowStatus(false);
        }}
        studentapi={editStudentApi}
        allstudents={allStudents}
        student={student}
      />
        <ChangeStudentPassForm
          show={showChangePass}
          onHide={() => setShowChangePass(false)} 
          refetchFunction= {() => window.location.reload()}
          student_id = {studentId}
        />
      <NewSubscripeForm
        show = {showSubscriptionForm}
        onHide = {()=>setShowSubscriptionForm(false)}
        refetchFunction = {()=> dispatch(fetchAllStudentsFromApi(students_URL_APi))}
        student_id = {studentId}
      />
      <OverLayLoading loading={deleteStudentLoading} loadingText={"Deleting Student..."}/>
      <OverLayLoading loading={approve_student_loading} loadingText={"Approving Student..."}/>
      <OverLayLoading loading={unapprove_student_loading} loadingText={"Un Approving Student..."}/>
      <OverLayLoading loading={unapprove_student_loading} loadingText={"Un Approving Student..."}/>
    </>
  );
}
export default StudentsPage;
