import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import { toast } from "react-toastify";
export const getInvoicesFunc = createAsyncThunk(
    "get Invoices function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.invoices;
        }
        catch (error) {
            toast.error(error)
        }
        
    }
);

const getInvoicesSlicer = createSlice({
    name : "get Invoices slicer",
    initialState : {
        loading : null,
        data : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getInvoicesFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(getInvoicesFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(getInvoicesFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default getInvoicesSlicer.reducer;