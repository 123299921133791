import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuizzesFunc } from "../../../store/custom-actions/quizzes-actions/GetAllQuizzesSlicer";
import ContextApi from "../../../store/ContextApi";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Button , Spinner} from "react-bootstrap";
import { postAttempGradeFunc } from "../../../store/custom-actions/attempts-actions/PostAttemptsGradeSlicer";
import { getQuizeAttemptsFunc } from "../../../store/custom-actions/attempts-actions/GetAttemptsSlicer";
import { getQuizeSingleAttempFunc } from "../../../store/custom-actions/attempts-actions/GetSingleAttemp";
import noQuestionsImg from "../../../media/images/fail-img.svg"
import swal from "sweetalert2";
import "./quizzes.css";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { Bounce, toast } from "react-toastify";

function AnswerShowModal(props) {
    const {t} = useTranslation();
    const  {answerImg} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Student Answer")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="relative w-full flex justify-center">
            <img src={answerImg} alt="answer img" clasName="relative col-sm-12 rounded-md" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}


function AttemptPage() {
    const {t} = useTranslation();
    const {attempId} = useParams();
    const contextApi = useContext(ContextApi);
    const quizeAttemptsApi = `${contextApi.dashboard_URL_API}/quizzes/${attempId}`
    const dispatch = useDispatch();
    const {register , handleSubmit , reset} = useForm();
    const {loadingpostAttempGrade} = useSelector(state => state.postAttempGradeSlicer);
    const {quizeSingleAttemptsloading , attempData} = useSelector(state => state.getQuizeSingleAttemptsSlicer);
    const [attemp , setAttemp] = useState();
    const [quizeNote , swtQuizeNote] = useState();
    const [noLoading , setNoLoading] = useState();
    const [showImageModel , setShowImageModel] = useState();
    const [answerImg , setAnswerImg] = useState();
    useEffect(() => {
        dispatch(getQuizeSingleAttempFunc(quizeAttemptsApi))
        .then(response => console.log(response))
    },[])

    useEffect (() => {
        attempData && setAttemp(attempData)
        console.log(attemp)
    },[attempData])

    const handleSubmitGrade = (data) => {
        if(data) {
            dispatch(postAttempGradeFunc({attempApi : quizeAttemptsApi , attempData : {note : quizeNote , grades : data}}))
            .then(result => {
                if(result.payload.success === true) {
                    window.history.back();
                }
            })
        }
    }

    useEffect(() => {
        !quizeSingleAttemptsloading ? setTimeout(()=>setNoLoading(true),300) : setNoLoading(false)
    },[quizeSingleAttemptsloading])
    return (
        <>
        {
            quizeSingleAttemptsloading
            ?
            (
                <>
                    <div className="row d-flex justify-content-center gap-2">
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                    </div>
                </>
            )
            :
            <div className="attempt-setion flex flex-col">
                <div className="relative flex items-start justify-between mb-3">
                    <h4 className="">{t("Questions Count")} : <span className="text-orange-700">({attemp && attemp.questions.length})</span></h4>
                    <div className="relative flex flex-col justify-end">
                        <h4>{t("Score")} : <span className="text-orange-700">
                            ({attempData && attempData.score}%)</span>
                        </h4>
                        <p className="text-red-500 m-0">{t("Note : this score of choose and true&false questions")}</p>
                    </div>
                </div>
                <form 
                className="attemp-form relative flex flex-col" 
                id="attemp-form"
                onSubmit={handleSubmit((data) => {
                    if(attemp?.status_passed === "compleated") {
                        toast.error(
                            t("Can Not Correct Quize Tow Times") , {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        });
                    }else {
                        handleSubmitGrade(data)
                    }
                })}
                >
                {
                    attempData && attemp && attemp.questions.length > 0 
                    ?
                    <>
                    {
                    attemp.questions.map((question , index) => {
                        return (
                            <div style={{"animationDelay" : `.${index}s`}} key={index} className={`question bg-white shadow-md shadow-slate-300 p-3 rounded-[20px] relative flex flex-col mb-4 ${noLoading ? "mounted" : ""} transition-all duration-500`}>
                                {
                                    question.type === 1 || question.type === 2
                                    ?
                                    <div className="relative flex justify-between items-center ">
                                        <h5><span className="text-orange-700">{t("Question")}: </span><div dangerouslySetInnerHTML={{__html: question.title}} /></h5>
                                        <div className="correct-status relative flex items-center">
                                            {t("Corrected")}
                                            <i className="fa-regular fa-circle-check text-green-500 ml-2"></i>
                                        </div>
                                    </div>
                                    :
                                    <div className="relative flex flex-col">
                                        <label className="mt-2 relative flex items-center justify-end">
                                            <p className="m-0">
                                            <i className="fa-solid fa-marker mr-1 text-orange-700"></i> {t("Question Grade")}: <span className="text-orange-700">{question.grade}</span>
                                            </p>
                                        </label>
                                        <div className="question-img relative w-full rounded-lg overflow-hidden  mb-4">
                                            {/* {
                                                question.image_url !== null
                                                ?
                                                <img src={question.image_url} alt="question-img" className="absolute w-full h-full"/>
                                                :
                                                <div className="relative flex items-center">
                                                    <i className="fa-solid fa-triangle-exclamation mr-1 text-red-700"></i>
                                                    <p className="m-0">{t("No Question Image")}</p>
                                                </div>
                                            } */}
                                            <h5><span className="text-orange-700">{t("Question")}: </span><div dangerouslySetInnerHTML={{__html: question.title}} /></h5>
                                        </div>
                                    </div>
                                    
                                }
                                {
                                    (question.type === 1 || question.type === 2)
                                    &&
                                    <div className="relative flex flex-col correct-answer-div">
                                        <label className="mt-2 relative flex items-center justify-between">
                                        {t("Correct Answer")}
                                            <p className="m-0">
                                            <i className="fa-solid fa-marker mr-1 text-orange-700"></i> {t("Question Grade")}: <span className="text-orange-700">{question.grade}</span>
                                            </p>
                                        </label>
                                        <div className="answer-div relative px-2 py-3 rounded-md bg-blue-200 my-b text-black">
                                            {question.options[question.correct_option]}
                                        </div>
                                    </div>
                                }
                                <div className="relative flex flex-col student-answer-div">
                                    <label className="mt-2 relative flex items-center justify-between">
                                        {t("Student Answer")}
                                    </label>
                                {
                                    (question.type === 1 || question.type === 2)
                                    ?
                                    <div className="answer-div relative px-2 py-3 rounded-md bg-green-200 my-b text-black">
                                        {question?.pivot?.answer ? question?.pivot?.answer : t("No Answer")}
                                    </div>
                                    :
                                    <h5><div dangerouslySetInnerHTML={{__html: question.pivot.answer}} /></h5>
                                }
                                </div>
                                <input 
                                {...register(question.id.toString(), 
                                    {
                                    required: (question.type !== 1 || question.type !== 1) && "field is required",
                                })}
                                className={`form-control relative my-2 ${(question.type === 1 || question.type === 2) ? "pointer-events-none cursor-no-drop" : ""}`}
                                type="number" 
                                disabled = {attemp?.status_passed === "compleated"}
                                min={0}
                                placeholder={`${(question.type === 1 || question.type === 2) ? 
                                    "Is Done" : "question Grade (number)"}`}
                                max={question.grade}
                                onChange={(e)=>{
                                    if(e.target.value > question.grade) {
                                        swal.fire({
                                            title: t("Grade Limit"), 
                                            html: `Question Grade is <span style="color: #006cc2; font-weight: bold;">( ${question.grade} )</span> when set grade more than question Grade will set full mark of this question`,  
                                            confirmButtonText: "Ok",
                                        });
                                        e.target.defaultValue = question.pivot.grade;
                                    }
                                }}
                                defaultValue={(question.type === 1 || question.type === 2) && question.pivot.grade}
                                />
                            </div>
                        )
                    })
                    }
                    {/* <div className="essay-questions-answers relative flex justify-center items-center flex-col">
                        {
                            attempData && attemp && attemp.images_url.length > 0 
                            ?
                            attemp.images_url.map((answer  , index)=>{
                                return (
                                    <div key={index} className="answer-div sm:W-[90%] md:w-[80%] relative w-full h-[300px] rounded-md overflow-hidden md:h-[350px] lg:h-[400px] mb-2 cursor-pointer"
                                        onClick={()=>{
                                            setAnswerImg(answer)
                                            setShowImageModel(true)
                                        }}
                                    >
                                        <img src={answer} alt="answer img" className="absolute top-0 left-0 w-full h-full"/>
                                    </div>
                            )
                            })
                            :
                            <p className="text-center">{t("no answers for articales questions")}</p>
                        }
                    </div> */}
                    <div className="relative flex flex-col ">
                        <textarea
                        className="attemp-note mt-2 mb-4 px-2 py-3  shadow-md shadow-slate-200 resize-none indent-2 rounded-[20px]" cols={70} rows={10} placeholder="Attemp Note (optinal)"
                        onChange={(e) => swtQuizeNote(e.target.value)}
                        readOnly = {attemp?.status_passed === "compleated"}
                        >
                        </textarea>
                        {
                            attemp?.status_passed === "compleated"
                            ?
                            ""
                            :
                            <Button type="submit" form="attemp-form" className={`${loadingpostAttempGrade ? "opacity-80 pointer-events-none" : ""}`}>
                                {!loadingpostAttempGrade 
                                ? 
                                "Submit" 
                                :
                                <div className="relative flex justify-center items-center">
                                {t("loading")}..
                                <div className="loading-spin-data w-[16px] h-[16px] ml-1 rounded-[50%] border-t-[2px] border-r-[2px] border-white "></div>
                                </div> 
                                }
                            </Button>
                        }
                    </div>
                    </>
                    :
                    <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                        <img
                        src={noQuestionsImg}
                        alt="faild img"
                        className="w-[150px] mb-3"
                        />
                        <p>
                        {t("No Questions Yet!")} 
                        <span
                            className="text-[#4d5fe3] cursor-pointer"
                            onClick={() =>
                            window.history.back()
                            }
                        >
                            {t("Back")}
                        </span>
                        </p>
                    </div>
                }
                </form>
            </div>
        }
        <AnswerShowModal
            show = {showImageModel}
            onHide = {() => setShowImageModel(false)}
            answerImg = {answerImg}
        />
        </>
    )
}

export default AttemptPage