import { lazy, Suspense, useEffect } from "react";
import Index from "./jsx";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { useState } from "react";
import ContextApi from "./store/ContextApi";
import { getBasicSettingsFunc } from "./store/custom-actions/basic-settings/GetBasicSettingsSlicer";
import { getLanguageSettingsFunc } from "./store/custom-actions/language/GetLanguageSettingsSlicer";
import i18n from "i18next";
function App(props) {
  const dispatch = useDispatch();

  const [isLogedin, setIslogedIn] = useState(false);
  const { loading ,  status } = useSelector((state) => state.loginStatus);
  const global_data = {
    // dashboard_URL_API : "https://apis-lms.topgrowth-eg.com/api/dashboard",
    dashboard_URL_API : "https://apis.dracademyeg.com/api/dashboard",
    main_headers : (
      axios.defaults.headers.common["Content-Type"] = "application/json",
      axios.defaults.headers.common["Accept"] = "application/json",
      axios.defaults.headers.common["ngrok-skip-browser-warning"] = "69420"
    )
  }
  const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
    state => state.getBasicSettingsSlicer
  )
  const changeFavicon = (iconURL) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = iconURL;
  };
  const changeTitle = (title) => {
    document.title = title;
    // link.href = iconURL;
  };
  const basicSettingsApi = `${global_data.dashboard_URL_API}/settings/general-settings`
  const getSettingsFunc = () => {
    dispatch(getBasicSettingsFunc(basicSettingsApi))
  }
  useEffect(() => {
    getSettingsFunc();
  },[])
  useEffect(() => {
    const userToken = window.localStorage.getItem("token");
    if(userToken) {
      axios.defaults.headers.common = {'Authorization' : `Bearer ${userToken}`};
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
        // getSettingsFunc();
    }
  },[])
  useEffect(()=>{
    if(status) {
      // console.log(status)
      if(status.success && status.user){
        setIslogedIn(true)
        getSettingsFunc();
      }else {
        setIslogedIn(false)
      }
    }
  },[status])

  useEffect(() => {
    console.log(settingsData)
    // Change the favicon to a new icon URL
    changeFavicon(settingsData?.site_logo);
    changeTitle(settingsData?.site_title);
    
    // You can also revert back to the original favicon on unmount if needed
    return () => {
        changeFavicon(settingsData?.site_logo);
        changeTitle(settingsData?.site_title)
    };
  }, [settingsData]);
  // const languageAPi = `https://apis-lms.topgrowth-eg.com/api/language-settings`
  const languageAPi = `https://apis.dracademyeg.com/api/language-settings`
  useEffect(() => {
    const getSettings = () => {
        // dispatch(getGlobalSettingsFunc(settingsApi))
        if(window.localStorage.getItem("langSlug")) {
          dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${window.localStorage.getItem("langSlug")}`))
        }else {
          dispatch(getLanguageSettingsFunc(languageAPi))
        }
    }
    getSettings()
},[])
const {loading:switchLangLoading , data:languageData} = useSelector(
  state => state.getLanguageSettingsSlicer
)
useEffect(() => {
  const fetchTranslations = async () => {
    if (languageData) {
      const { defaultLang, all_word } = languageData;
      if (defaultLang && all_word) {
        const { direction, slug } = defaultLang;
        document.documentElement.dir = direction; // Set the direction dynamically
        window.localStorage.setItem("langSlug" , defaultLang.slug);
        console.log(defaultLang.slug)
        i18n.addResources(slug, 'translation', all_word);
        i18n.changeLanguage(slug);
        // if (defaultLang.slug && defaultLang.slug === "en" && window.localStorage.getItem("langSlug") === 'en') {
        //   document.documentElement.setAttribute('dir', 'ltr');
        //   document.documentElement.setAttribute('lang', 'en');
        // }
        // if (defaultLang.slug && defaultLang.slug === "ar" && window.localStorage.getItem("langSlug") === 'ar') {
        //   const existingLink = document.querySelector('link[href="./assets/css/zilom_en.css"]');
        //   if (!existingLink) {
        //     const rtlStyleLink = document.createElement('link');
        //     rtlStyleLink.href = './assets/css/zilom_en.css';
        //     rtlStyleLink.rel = 'stylesheet';
        //     document.head.appendChild(rtlStyleLink);
        //     }
        // }else {
        //   const existingLink = document.querySelector('link[href="./assets/css/zilom_en.css"]');
        //   if(existingLink) {
        //     existingLink.href = ""
        //   }
        // }
      } else {
        console.error('Invalid language data structure', languageData);
      }
    }
  };
  fetchTranslations();
}, [languageData]);

  return (
    <>
      <ContextApi.Provider value={global_data}>
        <Index/>
      </ContextApi.Provider>
    </>
  )
  
}

// export default withRouter(connect(mapStateToProps)(App));
export default App
