import { useState } from "react";
import { Link } from "react-router-dom";
import defaultCourseImg from "../../../media/images/course-img.png"
import { useTranslation } from "react-i18next";
function UserCourseBox({courseId , courseImg , courseName , courseDesc}) {
    const {t} = useTranslation();
    const [descCourse , setDescCourse] = useState(false);
    return (
        <div className="course-box relative w-full flex flex-col pt-3 px-3 mb-2 cursor-pointer transition-all duration-500 bg-white shadow-md shadow-slate-400 rounded-md"
        onClick={()=>setDescCourse(!descCourse)}
        >
            <div className="relative w-full flex justify-between items-center">
                <div className="course-img-and-name flex items-center relative">
                    <img src={courseImg !== null ? courseImg : defaultCourseImg} alt="course img" className="w-[70px] mr-2 rounded-md"/>
                    <h5>{courseName}</h5>
                </div>
                <i className={`fa-solid fa-chevron-left transition-all duration-500 
                ${descCourse ? "rotate-[90deg]" : "rotate-[-90deg]"}`}></i>
            </div>
            <p className={`course-description transition-all duration-500 relative w-full ${descCourse ? "max-h-[300px]" : "max-h-0"} overflow-hidden flex flex-col`}>
            {courseDesc}
            <Link to={`course-details-page/${courseId}`} className="bg-primary my-1 transition-all duration-300 hover:shadow-slate-400 shadow-md hover:translate-y-[-3px] text-white rounded-md p-1 px-2 w-fit">{t("About course")}</Link>
            </p>
        </div>
    )
}
export default UserCourseBox;