export default function checkPermissions( targetPermission , element , insteadPage) {
    const user = JSON.parse(window.localStorage.getItem("user"))
    const permissionsList = user && user.permissions
    if(!permissionsList) {
        return element
    }else {
        const find = permissionsList.find(permission => permission.name.toLowerCase() === targetPermission);
        if(find) {
            return element;
        }else {
            if(insteadPage){
                return insteadPage;
            } 
        }
    }
}