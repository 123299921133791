import { useContext, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPermissionsFunc } from "../../../../store/custom-actions/permissions-actions/GetAllPermissions";
import { fetchSingleUserFunc } from "../../../../store/custom-actions/users-actions/GetSingleUserSlicer";
import PermissionCheckBox from "./PermissionCheckBbox";
import { useForm } from "react-hook-form";
import ContextApi from "../../../../store/ContextApi";
import { updateUserPermissionsFunc } from "../../../../store/custom-actions/users-actions/UpdateUserPermissionsSlicer";
import { fetchUsersFunc } from "../../../../store/custom-actions/users-actions/GetUsersSlicer";
import { useTranslation } from "react-i18next";

export default function UpdateUserPermissions({show , onHide , userId}) {
    const {t} = useTranslation();
    const {handleSubmit} = useForm();
    const [arrayWillSend , setarrayWillSend] = useState([]);
    const contextApi = useContext(ContextApi);
    const allPerissionsApi = `${contextApi.dashboard_URL_API}/permissions`;
    const single_user_URL_APi = `${contextApi.dashboard_URL_API}/users/${userId}`;
    const post_permissions_user_URL_APi = `${contextApi.dashboard_URL_API}/user-permissions/${userId}`;
    const users_API = `${contextApi.dashboard_URL_API}/users`
    const {loading:getPermissionsLoading , data:permissionsData} = useSelector(
        state => state.getAllPermissionsSlicer
    )
    const {loading:updatePermissionsLoading} = useSelector(
        state => state.updateUserPermissionsSlicer
    )
    const { loading:getSingleUserLoading, userData } = useSelector(
        (state) => state.getSingleUserSlicer
      );
      const [userPermissions , setUserPermissions] = useState([])
      useEffect(() => {
        const getUserData = async () => {
          dispatch(fetchSingleUserFunc(single_user_URL_APi))
        };
        getUserData();
      }, []);
    const [permissionsList ,setPermissionsList] = useState([])
    const dispatch = useDispatch();
    const getPermissions = async () => {
        try {
            dispatch(fetchAllPermissionsFunc(allPerissionsApi))
        }
        catch (error) {
            alert(error)
        }
    }
    useEffect(() => {
        getPermissions()
    },[])
    useEffect(() => {
        permissionsData && setPermissionsList(permissionsData)
    },[permissionsData])

    const removingPermisssion = (arrayAfterRemoving) => {
    setarrayWillSend(arrayAfterRemoving)
    }
    const additionPermission = (permission) => {
    setarrayWillSend([...arrayWillSend , permission])
    }
    useEffect(() => {
        userData && setUserPermissions(userData.permissions)
        userData && setarrayWillSend(userData.permissions)
    },[userData])
    const handleSubmitData = async (data) => {
        if(data && arrayWillSend ) {
            const uniqueData = arrayWillSend.flatMap(permission => permission.name)
            dispatch(updateUserPermissionsFunc(
            {
                user_api : post_permissions_user_URL_APi , 
                new_data : {
                    permissions : uniqueData
                }}
            ))
            .then(result => {
                if(result.payload.success === true) {
                    onHide();
                    // dispatch(getRolesFunc(roles_API_URL));
                    dispatch(fetchUsersFunc(users_API));
                }
            })
    }
    }
    return (
        <>
    <Modal
    //   {...props}
        show = {show}
        onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          {t("Update Permissions")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='session-form-div'>
            {
                (getPermissionsLoading || getSingleUserLoading)
                ?
                (
                    <>
                        <div className="row d-flex justify-content-center gap-2">
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                        </div>
                    </>
                )
                :
                (
                <form
                    id="session-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitData(data);
                    })}
                >
                    <h4 className="my-2">{t("Permissions")}</h4>
                    <div className='row mt-2'>
                    {
                        getPermissionsLoading
                        ?
                        <p className='my-1 text-center w-full relative'>{t("loading")}...</p>
                        :
                        (
                            permissionsData
                            ?
                            (
                                permissionsList.length >= 1
                                ?
                                permissionsList.map((permission , index) => {
                                return (
                                    <>
                                    <div key={index} className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-3 flex items-center'>
                                        
                                        <PermissionCheckBox 
                                            key={index}
                                            permission = {permission}
                                            arrayWillSend = {arrayWillSend}
                                            removing = {removingPermisssion}
                                            additionDone = {additionPermission}
                                        />
                                    </div>
                                    </>
                                )
                                })
                                :
                                <p>{t("There are no Permissions!")}</p>
                            )
                            :
                            <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                                {/* <img
                                src={faildImg}
                                alt="faild img"
                                className="w-[150px] mb-3"
                                /> */}
                                <p>
                                {t("Faild load Permissions!")}
                                <span
                                    className="text-[#4d5fe3] cursor-pointer"
                                    onClick={() =>
                                        getPermissions()
                                    }
                                >
                                    {t("Try again")}
                                </span>
                                </p>
                            </div>
                        )
                    }
                    </div>
                </form>
                )
            }
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
            onHide();
        }}>{t("Close")}</Button>
        <Button type="submit" form="session-form" className={`${updatePermissionsLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!updatePermissionsLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
        </>
    )
}